import { Divider, Grid, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { Link } from "react-router-dom";
import PayItemTable from "../tablecmp/PayItemTable";

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  projectheading: {
    fontWeight: 500,
    letterSpacing: 0.5,
    fontSize: 17,
  },
  divider: {
    height: 4,
    backgroundImage: "linear-gradient(90deg, #00b0ff, #00C853)",
    width: "100%",
    marginTop: 15,
  },
  headcontainer: {
    margin: "15px 0%",
  },
  projectbtn: {
    "& a": {
      float: "right",
      textTransform: "inherit",
      fontWeight: 500,
      fontSize: 14,
      color: "white",
      letterSpacing: 0.8,
      borderRadius: 20,
    },
  },
}));

function PayItemList() {
  const classes = useStyles();
  return (
    <Grid container direction="row">
      <Grid className={classes.headcontainer} container item xs={12} md={12}>
        <Grid item xs={6} className={classes.projectheading}>
          <Typography variant="h6" gutterBottom>
            PayItems
          </Typography>
        </Grid>
        <Grid item xs={6} className={classes.projectbtn}>
          <Button
            component={Link}
            variant="contained"
            color="primary"
            to="/AddPayItem/0"
          >
            Add PayItem
          </Button>
        </Grid>
        <Divider className={classes.divider} />
      </Grid>
      <Grid container item xs={12} md={12}>
        <PayItemTable />
      </Grid>
    </Grid>
  );
}
export default PayItemList;
