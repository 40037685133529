import { Checkbox, FormControl, Grid, TextField } from "@material-ui/core";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import { apiService } from "../../services/api.service";
import ReportFooter from "./ReportFooter";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    margin: "0.75rem 0.75rem",
    //'box-shadowhadow': 'none',
  },
  formControl: {
    minWidth: 120,
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
  },
  pos: {
    marginBottom: 12,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
});

export default function QuoteToActualCompAllDivision() {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  useEffect(() => {
    // apiService.getContent("pi/type").then((edata) => { 
    //   console.log(edata);
    //   setData(edata)
    
    // });
   
  }, []);

    const runReport = (event, rptType) => {
        
            
            //Submit form rptype...xlsx pdf
            var qrystr = "payitemcompsumaggr&format=" + rptType ;
            var fileName = "QuotesToActual - Detail_All_Division." + rptType;
            console.log(qrystr, fileName);
            apiService.getReport(qrystr, fileName);
       
    };
  //const allType = data.map((x) => x);
  return (
    <>
      <CardContent>
        <Typography className={classes.title} gutterBottom>
        Quote to Actual Comparision all Active Jobs for all Division
        </Typography>
        <Typography variant="h5" component="h2"></Typography>
        <Typography className={classes.pos} color="textSecondary">
         
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <ReportFooter sumbit={runReport} />
      </CardActions>
      {/* </Card> */}
    </>
  );
}
