import React from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { makeStyles, Typography } from '@material-ui/core';

const getColor = (props) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isDragActive) {
    return '#2196f3';
  }
  return '#eeeeee';
}

const useStyles = makeStyles({
  fileNameTitle: {
    fontSize: '18px',
    fontWeight: '500',
    margin: '10px 1px 5px 0px',
    paddingLeft: 25,
  },
})

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
`;

export default function FileUploader({ onDrop, fileNames = [], acceptedFileTypes, maxFiles }) {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({ accept: acceptedFileTypes, maxFiles, onDrop });

  const classes = useStyles();

  return (
    <div className="container">
      <Container {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop files here, or click to select files</p>
      </Container>
      <aside>
        {fileNames.length > 0 && (
          <>
            <Typography className={classes.fileNameTitle}>
              Current Uploaded Files
            </Typography>
            <ul>
              {fileNames.map(file => (
                <li key={file.path}>
                  {file.path} - {file.size} bytes
                </li>
              ))}
            </ul>
          </>
        )}
      </aside>
    </div>
  );
}
