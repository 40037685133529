import { Badge, Button, Grid, MenuItem, MenuList } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import { deepOrange } from "@material-ui/core/colors";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import Menu from "@material-ui/core/Menu";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/Menu";
import clsx from "clsx";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { logout } from "../actions/auth";
import { apiService } from "../services/api.service";

const drawerWidth = 220;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
      "z-index": "99",
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.up("md")]: {
      "margin-top": "53px",
    },
    "margin-top": "53px",
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },

  content: {
    flexGrow: 1,
    "margin-top": "30px",
    "z-index": "100",
    padding: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
      "margin-top": "30px",
    },
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  projectheading: {
    fontWeight: 500,
    letterSpacing: 0.5,
    fontSize: 17,
  },
  projectbtn: {
    "& a": {
      float: "right",
      textTransform: "inherit",
      fontWeight: 500,
      fontSize: 14,
      color: "white",
      letterSpacing: 0.8,
      borderRadius: 20,
    },
  },
  divider: {
    height: 4,
    backgroundImage: "linear-gradient(90deg, #00C853, #00b0ff)",
  },
  menuLogo: {
    padding: "10px 15px 4px 25px",
    position: "fixed",
    "border-bottom": "1.5px solid rgba(0, 0, 0, 0.12)",
    "z-index": "999",
    width: "100%",
    "background-image": "linear-gradient(90deg, #fefefe, #00b0ff)",
    //'background-color' : 'white',
    //color : 'white',
    [theme.breakpoints.down("sm")]: {
      border: "none",
      padding: "10px 25px 0px 25px",
    },
  },
  divider: {
    height: 4,
    backgroundImage: "linear-gradient(90deg, #00b0ff, #00C853)",
    width: "100%",
    "margin-bottom": "15px",
  },
  headcontainer: {
    margin: "15px 0%",
  },
  menuBarT: {
    height: "33px",
    "margin-right": "15px",
  },
  logoI: {
    height: "33px",
    width: "140px",
  },
  profileLink: {
    position: "fixed",
    bottom: "15px",
    "z-index": "9999",
    width: "202px",
    background: "white",
    "margin-left": "15px",
    // [theme.breakpoints.down("sm")]: {
    //   display:'none',
    // },
  },
  profileName: {
    margin: "8px 0px 0px 0px",
    "line-height": "1.1",
  },
  profileDtl: {
    "z-index": "99999",
  },
  collapseMenu: {
    "padding-left": "15px",
  },
}));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.secondary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
    fontFamily:
      '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif',
    fontSize: 14,
    color: "rgba(0,0,0,0.87)",
    fontWeight: "500",
    height: "48px",
    lineHeight: "48px",
    padding: "0 32px",
  },
}))(MenuItem);
const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -10,
    top: 10,
    //border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}))(Badge);
function ResponsiveDrawer(props) {
  const { window, children, stateprops } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [badgeCount, setBadgeCount] = React.useState(0);
  useEffect(() => {
    apiService
      .getContentNoLoad("dp/app/count")
      .then((count) => setBadgeCount(count));
  }, []);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const [open, setOpen] = React.useState(false);

  const handleClickmenu = () => {
    setOpen(!open);
  };
  const dispatch = useDispatch();

  const history = useHistory();
  const logOut = () => {
    dispatch(logout());
    history.push("/login");
  };
  const drawer = (
    <div>
      {
        //console.log(stateprops)
      }
      {stateprops.currentUser ? (
        <MenuList>
          <StyledMenuItem component={Link} to="/main">
            Dashboard
          </StyledMenuItem>

          {/* {!stateprops.showAdmin && (
            <StyledMenuItem component={Link} to="/">
              Dashboard
            </StyledMenuItem>
          )} */}
          {(stateprops.showAdmin ||
            stateprops.showManager ||
            stateprops.showCorporate) && (
            <StyledMenuItem component={Link} to="/atmbp">
              Business Partners
            </StyledMenuItem>
          )}
          {(stateprops.showAdmin || stateprops.showCorporate) && (
            <StyledMenuItem component={Link} to="/PayItemList">
              Pay Items
            </StyledMenuItem>
          )}
          {(stateprops.showAdmin ||
            stateprops.showManager ||
            stateprops.showCorporate) && (
            <StyledMenuItem
              component={Link}
              to="/joblist"
              // onClick={handleClickmenu}
            >
              Job List
              {/* {open ? <ExpandLess /> : <ExpandMore />} */}
            </StyledMenuItem>
          )}
          {/* <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            className={classes.collapseMenu}
          >
            <StyledMenuItem component={Link} to="/JobPayItem">
              Job Pay Items
            </StyledMenuItem>
            <StyledMenuItem component={Link} to="/SubLineItem">
              Station Detail
            </StyledMenuItem>
            <StyledMenuItem component={Link} to="/Estimate">
              Estimates
            </StyledMenuItem>
          </Collapse> */}
          {stateprops.showAdmin && (
            <StyledMenuItem component={Link} to="/ProdApproval">
              <StyledBadge
                color="error"
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                overlap="circle"
                badgeContent={badgeCount}
              >
                Daily Approvals
              </StyledBadge>
            </StyledMenuItem>
          )}

          {(stateprops.showAdmin ||
            stateprops.showManager ||
            stateprops.showCorporate) && (
            <StyledMenuItem component={Link} to="/CpReports">
              Corporate Reports
            </StyledMenuItem>
          )}
          <StyledMenuItem component={Link} to="/OpReports">
            Operational Reports
          </StyledMenuItem>
          {(stateprops.showAdmin || stateprops.showManager) && (
            <StyledMenuItem component={Link} to="/Boards">
              Job Boards
            </StyledMenuItem>
          )}
          <StyledMenuItem component={Link} to="/foreman">
            Foreman Home
          </StyledMenuItem>
          {stateprops.showForeman && (
            <StyledMenuItem component={Link} to="/MyWorkList">
              My Changes
            </StyledMenuItem>
          )}
          
          <StyledMenuItem component={Link} to="/Hotels">
            Hotels List
          </StyledMenuItem>
           
          <StyledMenuItem component={Link} to="/Resources">
            Resources
          </StyledMenuItem>
        </MenuList>
      ) : (
        <MenuList>
          <StyledMenuItem component={Link} to="/login">
            Login
          </StyledMenuItem>
        </MenuList>
      )}
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuOpen, setMenuOpen] = React.useState(true);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDrawerOpen = () => {
    setMenuOpen(!menuOpen);
    //console.log(menuOpen);
  };

  const handleDrawerClose = () => {
    setMenuOpen(false);
  };
  //console.log(stateprops)
  return (
    <div className={classes.root}>
      <CssBaseline />
      <div className={classes.menuLogo}>
        <Hidden only={["xs"]}>
          <MenuIcon
            className={classes.menuBarT}
            data-aa="anish"
            onClick={handleDrawerOpen}
          />
        </Hidden>
        <Hidden only={["lg", "xl", "md", "sm"]}>
          <MenuIcon className={classes.menuBarT} onClick={handleDrawerToggle} />
        </Hidden>
        <img
          src="/images/atm-png.png"
          className={classes.logoI}
          width="130"
          alt="Logo"
        />
        {/* <a onClick={handleDrawerOpen}>Close</a> */}
      </div>
      <nav className={classes.drawer} aria-label="Menu Options">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
            {stateprops.currentUser && (
              <div className={classes.profileLink}>
                <Grid container spacing={1} onClick={handleClick}>
                  <Grid item xs={4}>
                    <Button
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={handleClick}
                    >
                      <Avatar className={classes.orange}>
                        {stateprops.currentUser.abrName}
                      </Avatar>
                    </Button>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography
                      variant="subtitle2"
                      className={classes.profileName}
                      gutterBottom
                    >
                      {stateprops.currentUser.info.firstName}{" "}
                      {stateprops.currentUser.info.lastName}
                    </Typography>
                    <Typography variant="caption" gutterBottom>
                      {stateprops.currentUser.email}
                    </Typography>
                  </Grid>
                </Grid>
                <Menu
                  className={classes.profileDtl}
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem
                    component={Link}
                    to="/userdetails"
                    onClick={handleClose}
                  >
                    Profile
                  </MenuItem>
                  {stateprops.showAdmin && (
                    <MenuItem
                      component={Link}
                      to="/manageuser"
                      onClick={handleClose}
                    >
                      Manage Users
                    </MenuItem>
                  )}
                  <MenuItem
                    component={Link}
                    to="/profile"
                    onClick={handleClose}
                  >
                    My Account
                  </MenuItem>
                  <MenuItem onClick={logOut}>Logout</MenuItem>
                </Menu>
              </div>
            )}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="persistent"
            anchor="left"
            open={menuOpen}
          >
            {drawer}
            {stateprops.currentUser && (
              <div className={classes.profileLink}>
                <Grid container spacing={1} onClick={handleClick}>
                  <Grid item xs={4}>
                    <Button
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={handleClick}
                    >
                      <Avatar className={classes.orange}>
                        {stateprops.currentUser.abrName}
                      </Avatar>
                    </Button>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography
                      variant="subtitle2"
                      className={classes.profileName}
                      gutterBottom
                    >
                      {stateprops.currentUser.info.firstName}{" "}
                      {stateprops.currentUser.info.lastName}
                    </Typography>
                    <Typography variant="caption" gutterBottom>
                      {stateprops.currentUser.email}
                    </Typography>
                  </Grid>
                </Grid>
                <Menu
                  className={classes.profileDtl}
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem
                    component={Link}
                    to="/userdetails"
                    onClick={handleClose}
                  >
                    Profile
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    to="/profile"
                    onClick={handleClose}
                  >
                    My Account
                  </MenuItem>

                  <MenuItem onClick={logOut}>Logout</MenuItem>
                </Menu>
              </div>
            )}
          </Drawer>
        </Hidden>
      </nav>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: menuOpen,
        })}
      >
        {/* <div className={classes.toolbar} /> */}
        {/* <Container maxWidth="lg"> */}
        {children}
        {/* </Container> */}
        <footer>
          <Grid container direction="row">
            <Grid
              className={classes.headcontainer}
              container
              item
              xs={12}
              md={12}
            >
              <Divider className={classes.divider} />© 2020-
              {new Date().getFullYear()}, All rights reserved.
            </Grid>
          </Grid>
        </footer>
      </main>
    </div>
  );
}

export default ResponsiveDrawer;
