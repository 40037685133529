import DateFnsUtils from "@date-io/date-fns";
import { FormControl, Grid, TextField } from "@material-ui/core";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import React, { useEffect, useState } from "react";
import { apiService } from "../../services/api.service";
import ReportFooter from "./ReportFooter";
import dateFormat from "dateformat";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    margin: "0.75rem 0.75rem",
    //'box-shadowhadow': 'none',
  },

  title: {
    fontSize: 14,
    fontWeight: 600,
  },
  pos: {
    marginBottom: 12,
  },
  //   formControl: {
  //     padding: "0px 8px",
  //     'margin-top': '8px',
  //   },
});

export default function ExtJobCompleteRpt() {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedjob, setSelectedJob] = useState(null);
  useEffect(() => {
    apiService.getContent("job/joblist").then((edata) => setData(edata));
  }, []);
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const runReport = (event, rptType) => {
    if (selectedjob && selectedDate) {
      var formatd = dateFormat(selectedDate, "yyyy-mm-dd")
      console.log(selectedjob + " ---Job--" + formatd);
      //Submit form rptype...xlsx pdf
      var qrystr = "extjobcomplete&format=" + rptType + "&jobcode=" + selectedjob + "&installdate_date=" + formatd;
      var fileName = selectedjob + "_" + formatd + "_external." + rptType;
      apiService.getReport(qrystr, fileName);
    } else {
      console.log("Please select a ---Job");
    }
  };
  const alljobs = data.map((x) => x);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      {/* <Card className={classes.root} variant="outlined"> */}
      <CardContent>
        <Typography className={classes.title} gutterBottom>
          External Daily Production
        </Typography>
        <Typography variant="h5" component="h2"></Typography>
        <Typography className={classes.pos} color="textSecondary">
          Daily Production - quantities by Job
        </Typography>

        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormControl
              className={classes.formControl}
              fullWidth={true}
              margin={"normal"}
              size={"medium"}
            >
              <Autocomplete
                id="job"
                // blurOnSelect
                options={alljobs}
                onChange={(_, value) => {
                  setSelectedJob(value);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Select a Job" />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <KeyboardDatePicker
              margin={"normal"}
              fullWidth
              id="date-picker-dialog"
              label="Date"
              format="MM/dd/yyyy"
              value={selectedDate}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <ReportFooter sumbit={runReport} />
      </CardActions>
      {/* </Card> */}
    </MuiPickersUtilsProvider>
  );
}
