import {
  GoogleMap,
  InfoWindow,
  LoadScript,
  Marker
} from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import { apiService } from "../../services/api.service";
import { FormControl, Grid, InputLabel, MenuItem, Select, makeStyles } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
//import { Select } from "formik-material-ui";
import {
  Checkbox,
  TextField,
} from "@material-ui/core";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const containerStyle = {
  width: "100%",
  height: "550px",
};

const defaultCenter = {
  lat: 43.811309236605624,
  lng: -84.49395263376184,
};


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
function GoogleMapComp() {
  const classes = useStyles();
  const [selected, setSelected] = useState({});
  const [locdata, setLocData] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);

  const [payItemList, setPayItemList] = useState([]);


  const handleDropdownChange = (event) => {
    setSelectedOption(event.target.value);

  };


  useEffect(() => {
    console.log("Loaded...");
    apiService.getContent("jsi/geo").then((edata) => {
      console.log(edata);
      setLocData(edata)
    });
    apiService.getContent("job/page?jobid=").then((data) => {
      console.log(data);
      setPayItemList(data.jobTypes.map((ptype) => ptype.division))
    });

  }, [])
  const onSelect = (item) => {
    setSelected(item);
  };
  const location = locdata.map((ptype) => ({
    name: ptype.jobinfo,
    stateJobNumber: ptype.stateJobNumber,
    projEng: ptype.projEng,
    contact: ptype.contact,
    completeDt1: ptype.completeDt1,
    jobType: ptype.jobType,
    contractor: ptype.contractor,
    location: {
      lat: parseFloat(ptype.latitude),
      lng: parseFloat(ptype.longitude),
    },
  }));
  // const filteredMarkers = location.filter((marker) =>
  //   selectedOption ? marker.jobType.includes(selectedOption) : true
  // );
  const filteredMarkers = location.filter((marker) =>
  selectedOption.length > 0
    ? marker.jobType && selectedOption.some((option) => marker.jobType.includes(option))
    : true
);

  return (
    <Grid container justify="flex-end">
      <Grid item xs={12} md={4} px={2}>
        <FormControl
          className={classes.formControl}
          fullWidth={true}
          margin={"normal"}
          size={"medium"}
        >
          <Autocomplete
            multiple
            id="checkboxes-jobtype"
            options={payItemList}
            limitTags={3}
            value={selectedOption}
            disableCloseOnSelect
            onChange={(event, newValue) => {
              setSelectedOption(newValue);
            }}
            getOptionLabel={(option) => option}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Job Type"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password",
                }}
              />
            )}
          />
        </FormControl>


      </Grid>
      
    

      <LoadScript googleMapsApiKey="AIzaSyBBTD3uJQJfWiP3Qe7JKT1OKsJOCmg_XVg">
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={defaultCenter}
          zoom={7}
        //  onLoad={onLoad}
        //onUnmount={onUnmount}
        >
          {/* Child components, such as markers, info windows, etc. */}

          {filteredMarkers.map((item) => {
            return (
              <Marker
                key={item.name}
                position={item.location}
                onClick={() => onSelect(item)}
              />
            );
          })}
          {selected.location && (
            <InfoWindow
              position={selected.location}
              clickable={true}
              onCloseClick={() => setSelected({})}>
              <p>{selected.name}<br />
                Proj Engineer: {selected.projEng}<br />
                Contact: {selected.contact} <br />
                Complete Date1: {selected.completeDt1} <br />
                Type: {selected.jobType} <br />
                State Job: {selected.stateJobNumber}<br />
                <span>Contractor: {selected.contractor}</span>
              </p>
            </InfoWindow>
          )}
        </GoogleMap>
      </LoadScript>
      {/* <div>
        {filteredMarkers.length > 0 ? (
          <ul>
            {filteredMarkers.map((marker) => (
              <li key={marker.id}>{marker.name}</li>
            ))}
          </ul>
        ) : (
          <p>No markers meet the condition.</p>
        )}
      </div> */}
    </Grid>
  );
}

export default React.memo(GoogleMapComp);
