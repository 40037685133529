import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import FormLabel from "@material-ui/core/FormLabel";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CancelIcon from "@material-ui/icons/Cancel";
import SaveIcon from "@material-ui/icons/Save";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Field, Form, Formik } from "formik";
import { RadioGroup } from "formik-material-ui";
import { KeyboardDatePicker } from "material-ui-formik-components/KeyboardDatePicker";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTable } from "react-table";
import * as Yup from "yup";
import { CLEAR_STATION } from "../../actions/type";
import ConfirmDialog from "../../helper/ConfirmDialog";
import store from "../../reducers/store";
import { apiService } from "../../services/api.service";
import JobMenu from "../menu/JobLinks";
import StationDetailsList from "../tablelist/StationDetailsList";
import FkInputFld from "./formik-custom-fields/FkInputFld";
import FkSelectFld from "./formik-custom-fields/FkSelectFld";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#4cc8ff",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 13,
    padding: 8,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function Table2({ columns, data, onRowClick }) {
  // Use the state and functions returned from useTable to build your UI
  const handleRowClick = useCallback(
    (data) => {
      onRowClick(data);
    },
    [onRowClick]
  );
  const [selected, setselected] = useState(-1);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });
  // Render the UI for your table
  return (
    <Table {...getTableProps()}>
      <TableHead>
        {headerGroups.map((headerGroup) => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <StyledTableCell {...column.getHeaderProps()}>
                {column.render("Header")}
                {/* Render the columns filter UI */}
                {/* <div>{column.render('Header')}</div> */}
              </StyledTableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <StyledTableRow
              {...row.getRowProps({ onClick: () => handleRowClick(row) })}
            >
              {row.cells.map((cell) => {
                return (
                  <StyledTableCell {...cell.getCellProps()}>
                    {cell.render("Cell")}
                  </StyledTableCell>
                );
              })}
            </StyledTableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    padding: "0px 12px",
    "margin-top": "8px",
  },
  formControlD: {
    padding: "0px 12px",
    minWidth: 200,
    "& label": {
      padding: "0px 12px",
    },
  },
  rdgControl: {
    padding: "0px 12px",
    "margin-top": "8px",
  },
  headcontainer: {
    margin: "15px 0%",
  },
  fullwidthform: {
    width: "100%",
    padding: "0px 15px",
  },
  fullwidthpaper: {
    width: "100%",
  },
  fullwidthpaperFH: {
    width: "100%",
    "background-color": "rgb(0,176,255,0.7)",
    "border-right": "10px solid #ff5722",
  },
  button: {
    color: "white",
  },
  formFooter: {
    padding: "15px 18px",
    background: "#efefef",
    "margin-top": "25px",
  },
  signlogo: {
    width: "180px",
    height: "155px",
    padding: "0px 0px",
  },
  lvlResize: {
    left: "15px",
  },
  rdglvl: {
    "padding-left": "12px",
    "margin-top": "10px",
  },
  grayOut: {
    background: "rgb(0,0,0,0.1)",
    margin: "25px 0px",
  },
  formHeader: {
    padding: "10px 15px",
    "font-weight": "bold",
    "font-size": "18px",
    "letter-spacing": "0.5px",
    color: "white",
  },
  tableFH: {
    "font-weight": "500",
    "text-transform": "uppercase",
    "font-size": "14px",
  },
  LeftCND: {
    "padding ": "4px 7px 0px 0px",
  },
  RightCND: {
    "padding ": "0px 0px 0px 7px",
  },
}));
const useFocus = () => {
  const htmlElRef = useRef(null);
  //console.log(htmlElRef.current);
  const setFocus = () => {
    htmlElRef.current && htmlElRef.current.focus();
  };

  return [htmlElRef, setFocus];
};
const SubLineItem = ({ match }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "Pay Item",
        accessor: "payItmCode",
      },
      {
        Header: "Description",
        accessor: "payItmDesc",
      },
      {
        Header: "Quantity",
        accessor: "quantity",
      },
      {
        Header: "Unit",
        accessor: "unit",
      },
    ],
    []
  );
  const dispatch = useDispatch();
  const [imgOpen, setImgOpen] = useState(false);
  const [btnText, setbtnText] = useState("Add");
  const [lineItem, setLineItem] = useState([]);
  const [contr, setContr] = useState([]);
  const [signs, setSigns] = useState([]);
  const [job, setJob] = useState({});
  const [isEdit, setisEdit] = useState(false);
  const [disabled, setdisabled] = useState(true);
  const [stationItem, setStationItem] = useState([]);
  const [stations, setStations] = useState([]);
  const [usedQuant, setUsedQuant] = useState({});
  const [availQuant, setAvailQuant] = useState({});
  const [stNameInputRef, setStNameInputRef] = useFocus();

  const [initialValues, setinitialValues] = useState({
    stationName: "",
    contractor: "",
    latitude: "",
    longitude: "",
    payItemCode: "",
    payitemDesc: "",
    jobPayitm: "",
    quantity: "",
    quantityUnit: "",
    npi: "",
    npiQuantity: "",
    sign_code_auto: null,
    signCode: "",
    signHeight: "",
    signWidth: "",
    intNotes: "",
    extNotes: "",
    //New Fields Added need maping to DB-a-
    supports: "",
    measuredDt: null,
    engSentDt: null,
    cardSentDt: null,
    cardCmpltDt: null,
    delivrdLoc: "",
  });
  const [payItmHasSupport, setpayItmHasSupport] = useState(false);
  const [payItmHasMeasurements, setpayItmHasMeasurements] = useState(false);
  const [payItmIsSign, setpayItmIsSign] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [showAdmin, setShowAdmin] = useState(false);
  const { user: currentUser } = useSelector((state) => state.auth);

  useEffect(() => {
    //console.log(match);
    let reqestp =
      typeof match !== "undefined" && typeof match.params.id !== "undefined"
        ? match.params.id
        : "5";
    apiService.getContent("jsi/page?jobid=" + reqestp).then((edata) => {
      console.log(edata);
      setJob(edata.job);
      setLineItem(edata.jobPayItms);
      setContr(edata.contractors);
      setSigns(edata.signs);
      setStationItem(edata.piStations);
      setStations(edata.stations);
      handlePreload();
    });
    setShowAdmin(currentUser.roles.includes("ROLE_ADMIN"));
  }, []);

  const handlePreload = () => {
    var stationdet = store.getState().station;
    console.log(stationdet);
    if (stationdet != null && Object.keys(stationdet).length !== 0) {
      console.log("Not Null");
      var row = JSON.parse(stationdet.stationdetails);
      console.log(row);
      setinitialValues({
        latitude: row.latitude,
        longitude: row.longitude,
        extNotes: row.extNotes,
        contractor: row.contractor,
        signHeight: row.signHeight,
        signWidth: row.signWidth,
        stationName: row.stationName,
        jobStation: row.jobStation,
        signCode: row.signCode,
        stationPi: row.stationPi,
        payItemCode: row.payItmCode,
        jobPayitm: row.jobPayitm,
        payitemDesc: row.payItmDesc,
        //quantityUnit: payItem != null ? payItem.unit : "",
        npi: row.nonProd,
        quantity: row.quantity,
        npiQuantity: row.npiQuantity,
        supports: row.supports != null ? String(row.supports) : "",
        measuredDt: row.measuredDt ? new Date(new Date(row.measuredDt).getTime() + new Date(row.measuredDt).getTimezoneOffset() * 60000) : null,
        engSentDt: row.engSentDt ? new Date(new Date(row.engSentDt).getTime() + new Date(row.engSentDt).getTimezoneOffset() * 60000) : null,
        cardSentDt: row.cardSentDt ? new Date(new Date(row.cardSentDt).getTime() + new Date(row.cardSentDt).getTimezoneOffset() * 60000) : null,
        cardCmpltDt: row.cardCmpltDt ? new Date(new Date(row.cardCmpltDt).getTime() + new Date(row.cardCmpltDt).getTimezoneOffset() * 60000) : null,
        delivrdLoc: row.delivrdLoc,
      });

      dispatch({
        type: CLEAR_STATION,
        payload: "",
      });
      setbtnText("Update");
      setisEdit(true);
    } else {
      console.log("Null");
    }
  };

  const contractors = contr.map((ptype) => ({
    value: ptype.bpId,
    label: ptype.name,
  }));
  const availablePayitems = lineItem.map((x) => ({
    value: x.payItmDesc,
    label: x.payItmCode,
    id: x.jobPayitmId,
  }));
  const signsList = signs.map((ptype) => ({
    value: ptype.snId + ":" + ptype.snFile,
    label: ptype.snCode + ":" + ptype.snDesc,
  }));
  const jobStationList = stations.map((ptype) => ({
    value: ptype.stationId,
    label: ptype.stationName,
  }));
  const formRef = useRef();
  const handleClick = (row) => {
    row.getRowProps({ style: { color: "blue" } });
    setdisabled(false);
    OnPayItemSelect(row.original);
  };

  const OnPayItemSelect = (payItem) => {
    console.log(payItem);

    let hasSupport = false;
    let hasMeasurements = false;
    let isSignPayItm = false;

    if (payItem) {
      const { payItmClass, typeDesc, payItmCode, payItmDesc, jobPayitmId, unit, nonProd, contractor, quantity } = payItem;

      if (typeDesc === "Sign") {
        isSignPayItm = true;
        hasSupport = payItmClass.some((e) => e.payitmClass === "Support");
        hasMeasurements = payItmClass.some((e) => e.payitmClass === "Measurements");
      }

      setpayItmHasSupport(hasSupport);
      setpayItmHasMeasurements(hasMeasurements);
      setpayItmIsSign(isSignPayItm);
      setinitialValues({
        ...formRef.current.values,
        payItemCode: payItmCode,
        payitemDesc: payItmDesc,
        jobPayitm: jobPayitmId,
        quantityUnit: unit,
        npi: nonProd,
        contractor: contractor,
        supports: hasSupport ? "1" : "",
      });
      setAvailQuant(quantity);
      calculateUsed(jobPayitmId);

    } else {
      clearPayItem();
    }
  };

  const calculateUsed = (jobPayitmId) => {
    let jpiUsed = 0;
    stationItem.forEach((si) => {
      if (si.jobPayitm == jobPayitmId) jpiUsed += si.quantity;
    });
    setUsedQuant(jpiUsed);
  };
  const DeleteStation = (data) => {
    let delJsp = data.stationPi;
    console.log("DeleteStation=" + delJsp);
    apiService
      .deleteObject("jsi/jsr/" + delJsp)
      .then((res) => {
        refreshTable();
        enqueueSnackbar("Deleted station payitem successfully", {
          variant: "success",
        });
      })
      .catch((err) =>
        enqueueSnackbar(
          "There is an error in deleting station payitem:" + err,
          { variant: "error" }
        )
      );
  };

  const EditStation = (row) => {
    setbtnText("Update");
    setisEdit(true);
    let details = stations.find((x) => x.stationId === row.jobStation);
    var sign = null;
    var ids = null;
    var payItem = lineItem.find((y) => y.jobPayitmId === row.jobPayitm);
    let payItmType = payItem?.payItmClass;
    let hasSupport = false;
    let hasMeasurements = false;
    let isSignPayItm = false;
    if (payItem?.typeDesc === "Sign") {
      isSignPayItm = true;
      if (payItmType.some((e) => e.payitmClass === "Support")) {
        hasSupport = true;
      }
      if (payItmType.some((e) => e.payitmClass === "Measurements")) {
        hasMeasurements = true;
      }
    }
    if (row.signCode) {
      sign = signsList.find((x) => x.value.includes(row.signCode));
      ids = sign.value.split(":");
    }
    setpayItmHasSupport(hasSupport);
    setpayItmHasMeasurements(hasMeasurements);
    setpayItmIsSign(isSignPayItm);
    setinitialValues({
      stationName: row.stationName,
      jobStation: row.jobStation,
      latitude: details.latitude,
      longitude: details.longitude,
      extNotes: details.extNotes,
      contractor: details.contractor,
      signCode: ids ? ids[0] : "",
      sign_image: ids ? ids[1] : "",
      sign_code_auto: sign ? sign : null,
      signHeight: row.signHeight,
      signWidth: row.signWidth,
      stationPi: row.stationPi,
      payItemCode: row.payItmCode,
      jobPayitm: row.jobPayitm,
      payitemDesc: payItem != null ? payItem.payItmDesc : "",
      quantityUnit: payItem != null ? payItem.unit : "",
      npi: payItem != null ? payItem.nonProd : "",
      quantity: row.quantity,
      npiQuantity: row.npiQuantity,
      supports: row.supports != null ? String(row.supports) : "",
      measuredDt: row.measuredDt ? new Date(new Date(row.measuredDt).getTime() + new Date(row.measuredDt).getTimezoneOffset() * 60000) : null,
      engSentDt: row.engSentDt ? new Date(new Date(row.engSentDt).getTime() + new Date(row.engSentDt).getTimezoneOffset() * 60000) : null,
      cardSentDt: row.cardSentDt ? new Date(new Date(row.cardSentDt).getTime() + new Date(row.cardSentDt).getTimezoneOffset() * 60000) : null,
      cardCmpltDt: row.cardCmpltDt ? new Date(new Date(row.cardCmpltDt).getTime() + new Date(row.cardCmpltDt).getTimezoneOffset() * 60000) : null,
      delivrdLoc: row.delivrdLoc,
    });
    //console.log(row);
  };

  const classes = useStyles();
  const history = useHistory();
  const handleEdit = () => {
    history.push("/joblist/" + job.jobId);
  };
  const handleJobPayItm = () => {
    history.push("/jobdetails/" + job.jobId);
  };
  const handleCancelClick = () => {
    if (isEdit) {
      setConfirmOpen(true);
    } else {
      history.goBack();
    }
  };
  function clearPayItem() {
    setinitialValues({
      ...formRef.current.values,
      // stationName: "", //Keep Station Name..
      contractor: "",
      latitude: "",
      longitude: "",
      payItemCode: "",
      payitemDesc: "",
      jobPayitm: "",
      quantity: "",
      quantityUnit: "",
      npi: "",
      npiQuantity: "",
      sign_code_auto: null,
      signCode: "",
      signHeight: "",
      signWidth: "",
      extNotes: "",
      supports: "",
      measuredDt: null,
      engSentDt: null,
      cardSentDt: null,
      cardCmpltDt: null,
      delivrdLoc: "",
    });
    setbtnText("Add");
    setisEdit(false);
    setpayItmHasSupport(false);
    setpayItmHasMeasurements(false);
    setpayItmIsSign(false);
  }
  function refreshTable() {
    apiService.getContent("jsi/jsp?jobid=" + job.jobId).then((edata) => {
      setStationItem(edata.piStations);
      setStations(edata.stations);
    });
  }
  function putStationPayItm(values) {
    let delJsp = values.stationPi;
    apiService
      .putContent("jsi/jsr/" + delJsp, values)
      .then((response) => response.json())
      .then((data) => console.log(data))
      .then((response) => {
        refreshTable();
        clearPayItem();

        enqueueSnackbar("Saved Successfully", { variant: "success" });
      })
      .catch((error) => {
        enqueueSnackbar(
          "There is an error in saving station payitem:" + error,
          { variant: "error" }
        );
        console.log("error: " + error);
      });
  }

  function postStationPayItm(values) {
    let details = stations.find((x) => x.stationName === values.stationName);
    let stationId = typeof details !== "undefined" ? details.stationId : "0";
    // let jobPayitm = lineItem.find(y => y.payItmId === values.payItmId);
    let submitvals = {
      ...values,
      stationId: stationId,
      job: job.jobId,
    };
    console.log(JSON.stringify(submitvals)); //POst Data to DB..
    apiService
      .addContent("jsi/", submitvals)
      .then((response) => {
        refreshTable();
        clearPayItem();
        enqueueSnackbar("Saved Successfully", { variant: "success" });
        setStNameInputRef();
      })
      .catch((error) => {
        enqueueSnackbar(
          "There is an error in saving station payitem:" + error,
          { variant: "error" }
        );
        console.log("error: " + error);
      });
  }

  function postStationInfo(values) {
    if (isEdit) {
      putStationPayItm(values);
    } else {
      postStationPayItm(values);
    }
  }
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      {/* <pre>{console.log(initialValues)}</pre> */}
      <Grid
        container
        alignItems="flex-start"
        justify="flex-end"
        direction="row"
      >
        <JobMenu jobId={job.jobId} Wday={null} headerText={"Station Details"} />
      </Grid>

      <Grid className={classes.headcontainer} container>
        {showAdmin && (
          <>
            <Grid
              item
              xs={12}
              sm={6}
              // style={disabled ? { pointerEvents: "none", opacity: "0.4" } : {}}
              className={classes.LeftCND}
            >
              <Paper className={classes.fullwidthpaperFH}>
                <Grid container spacing={1} className={classes.formHeader}>
                  <Grid item xs={12} sm={12}>
                    <Typography color="inherit" className={classes.tableFH}>
                      Job Code :{" "}
                      <a href="#">
                        <span onClick={() => handleEdit()}>{job.jobCode}</span>
                      </a>
                    </Typography>
                    <Typography color="inherit" className={classes.tableFH}>
                      Location :{" "}
                      <a href="#">
                        <span onClick={() => handleJobPayItm()}>
                          {job.jobLocation}
                        </span>
                      </a>
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={Yup.object().shape({
                  stationName: Yup.string().required(),
                  payItemCode: Yup.string().required(),
                  quantity: Yup.number(),
                  /*  .required()
                .test(
                  "valid quantity",
                  "Assigned quantity is higher than available " +
                  (availQuant - usedQuant),
                  function (value) {
                    let usedq = value + usedQuant;
                    return usedq <= availQuant && value > 0;
                  }
                ),*/
                })}
                innerRef={formRef}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  postStationInfo(values);
                  setSubmitting(false);
                  resetForm();
                }}
              >
                {({
                  errors,
                  status,
                  touched,
                  isSubmitting,
                  values,
                  handleBlur,
                  setFieldValue,
                  setTouched,
                }) => (
                  <Paper className={classes.fullwidthpaper}>
                    <Form className={classes.fullwidthform}>
                      <Grid container spacing={1}>
                        <Grid container spacing={1}>
                          <Grid item xs={12} sm={6}>
                            <Field
                              name="stationName"
                              options={jobStationList}
                              component={Autocomplete}
                              //disableListWrap
                              freeSolo
                              inputValue={values.stationName}
                              getOptionLabel={(option) => option.label}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Job Station"
                                  margin="normal"
                                  inputRef={stNameInputRef}
                                  autoFocus
                                  className={classes.formControl}
                                  onChange={(event) => {
                                    //console.log(event.target.value);
                                    setFieldValue(
                                      "stationName",
                                      event.target.value
                                    );
                                  }}
                                />
                              )}
                              onChange={(_, value) => {
                                if (value) {
                                  setFieldValue("stationName", value.label);
                                }
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FkSelectFld
                              name="contractor"
                              items={contractors}
                              label="Contractor"
                              controlClass={classes.formControl}
                            />
                          </Grid>
                        </Grid>

                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Field
                              name="payItemCode"
                              options={availablePayitems}
                              component={Autocomplete}
                              inputValue={values.payItemCode}
                              renderOption={(option) => (
                                <>
                                  {option.label} - {option.value}
                                </>
                              )}
                              getOptionLabel={(option) => option.label}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Pay Items"
                                  margin="normal"
                                  className={classes.formControl}
                                  onChange={(event) => {
                                    const { value } = event.target;
                                    console.log(value);
                                    setFieldValue("payItemCode", value);
                                  }}
                                />
                              )}
                              onChange={(_, value) => {
                                if (value) {
                                  console.log(value);
                                  setFieldValue("payItemCode", value.label);
                                  setFieldValue("jobPayItmId", value.id);
                                  OnPayItemSelect(lineItem.find((y) => y.jobPayitmId === value.id));
                                } else {
                                  setFieldValue("payItemCode", "");
                                  OnPayItemSelect(null);
                                }
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={0}>
                          <Grid item xs={12}>
                            <FkInputFld
                              name="payitemDesc"
                              label="Pay Item Description"
                              controlClass={classes.formControl}
                              disabled
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={0}>
                          <Grid item xs={12} sm={6}>
                            <FkInputFld
                              name="quantity"
                              label="Plan Quantity"
                              controlClass={classes.formControl}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FkInputFld
                              name="quantityUnit"
                              label="Quantity Unit"
                              controlClass={classes.formControl}
                              disabled
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                          <FkInputFld
                            name="npi"
                            label="Non Prod Item"
                            controlClass={classes.formControl}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FkInputFld
                            name="npiQuantity"
                            label="Non Prod Item Quantity"
                            controlClass={classes.formControl}
                          />
                        </Grid>
                      </Grid>
                      {payItmIsSign && (
                        <>
                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={8}>
                              <Grid container spacing={0}>
                                <Grid item xs={12} sm={12}>
                                  <Field
                                    name="sign_code_auto"
                                    // label="Sign Code"
                                    options={signsList}
                                    component={Autocomplete}
                                    className={classes.formControl}
                                    //disableListWrap
                                    getOptionLabel={(option) => option.label}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Sign Code"
                                        margin="normal"
                                      />
                                    )}
                                    value={values.sign_code_auto}
                                    onChange={(_, value) => {
                                      if (value) {
                                        var ids = value.value.split(":");
                                        setFieldValue("sign_image", ids[1]);
                                        setFieldValue("signCode", ids[0]);
                                        setFieldValue("sign_code_auto", value);
                                      } else {
                                        setFieldValue("sign_image", "");
                                        setFieldValue("signCode", "");
                                        setFieldValue("sign_code_auto", null);
                                      }
                                    }}
                                  />
                                </Grid>
                              </Grid>
                              <Grid container spacing={0}>
                                <Grid item xs={12} sm={6}>
                                  <FkInputFld
                                    name="signWidth"
                                    label="Sign Width"
                                    controlClass={classes.formControl}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <FkInputFld
                                    name="signHeight"
                                    label="Sign height"
                                    controlClass={classes.formControl}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <img
                                //src={"../signs/" + values.sign_image}
                                src={
                                  typeof values.sign_image !== "undefined"
                                    ? "../signs/" + values.sign_image
                                    : "../signs/notfound.png"
                                }
                                className={classes.signlogo}
                                onClick={() => {
                                  setImgOpen(true);
                                }}
                              />
                            </Grid>
                          </Grid>
                          <div>
                            {imgOpen && (
                              <Lightbox
                                mainSrc={"../signs/" + values.sign_image}
                                onCloseRequest={() => setImgOpen(false)}
                              />
                            )}
                          </div>
                        </>
                      )}
                      {payItmHasSupport && (
                        <Grid container spacing={1}>
                          <Grid item xs={12} sm={6}>
                            <FormLabel
                              component="legend"
                              className={classes.rdglvl}
                              margin={"normal"}
                            >
                              Number of Supports
                            </FormLabel>
                            <Field
                              component={RadioGroup}
                              name="supports"
                              row
                              className={classes.rdgControl}
                              onChange={(_, value) => {
                                //console.log(value);
                                setFieldValue("supports", value);
                              }}
                            >
                              <FormControlLabel
                                value="1"
                                control={<Radio disabled={isSubmitting} />}
                                label="1"
                                disabled={isSubmitting}
                              />
                              <FormControlLabel
                                value="2"
                                control={<Radio disabled={isSubmitting} />}
                                label="2"
                                disabled={isSubmitting}
                              />
                            </Field>
                          </Grid>
                        </Grid>
                      )}
                      {payItmHasMeasurements && (
                        <>
                          <Grid container spacing={0}>
                            <Grid item xs={12} sm={6}>
                              <Field
                                name="measuredDt"
                                className={classes.formControlD}
                                lableClass={classes.lvlResize}
                                component={KeyboardDatePicker}
                                label="Measured Date"
                                format="MM/dd/yyyy"
                                size="small"
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Field
                                name="engSentDt"
                                component={KeyboardDatePicker}
                                className={classes.formControlD}
                                lableClass={classes.lvlResize}
                                label="Sent to Engineer Date"
                                format="MM/dd/yyyy"
                                size="small"
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={0}>
                            <Grid item xs={12} sm={6}>
                              <Field
                                name="cardSentDt"
                                component={KeyboardDatePicker}
                                className={classes.formControlD}
                                lableClass={classes.lvlResize}
                                label="Sent to Cardinal Date"
                                format="MM/dd/yyyy"
                                size="small"
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Field
                                name="cardCmpltDt"
                                component={KeyboardDatePicker}
                                label="Cardinal Complete Date"
                                className={classes.formControlD}
                                lableClass={classes.lvlResize}
                                format="MM/dd/yyyy"
                                size="small"
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={0}>
                            <Grid item xs={12} sm={6}>
                              <FkInputFld
                                name="delivrdLoc"
                                label="Delivered Location"
                                controlClass={classes.formControl}
                              />
                            </Grid>
                          </Grid>
                        </>
                      )}
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={12}>
                          <FkInputFld
                            name="extNotes"
                            label="Internal Notes"
                            controlClass={classes.formControl}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                          <FkInputFld
                            name="latitude"
                            label="Latitude"
                            controlClass={classes.formControl}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FkInputFld
                            name="longitude"
                            label="Longitude"
                            controlClass={classes.formControl}
                          />
                        </Grid>
                      </Grid>

                      <Grid container className={classes.formFooter}>
                        <Grid item xs={6} md={6}>
                          <Button
                            variant="contained"
                            style={{ background: "red" }}
                            className={classes.button}
                            startIcon={<CancelIcon />}
                            onClick={() => handleCancelClick()}
                          >
                            Cancel
                          </Button>
                          <ConfirmDialog
                            title="Quit Editing?"
                            open={confirmOpen}
                            setOpen={setConfirmOpen}
                            onConfirm={clearPayItem}
                          >
                            Changes you made so far will not be saved?
                          </ConfirmDialog>
                        </Grid>
                        <Grid item xs={6} md={6} style={{ textAlign: "right" }}>
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            startIcon={<SaveIcon />}
                            type="submit"
                          >
                            {btnText}
                          </Button>
                        </Grid>
                      </Grid>
                    </Form>
                    {/* Dev Only  */}
                    {/* <DisplayFormikState {...values} /> */}
                  </Paper>
                )}
              </Formik>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              // style={isEdit ? { pointerEvents: "none", opacity: "0.4" } : {}}
              className={classes.RightCND}
            >
              <Paper className={classes.fullwidthpaper}>
                <TableContainer>
                  <Table2
                    columns={columns}
                    data={lineItem}
                    onRowClick={handleClick}
                  />
                </TableContainer>
              </Paper>
            </Grid>
          </>
        )}

        <Grid item xs={12} sm={12}>
          {!showAdmin && (
            <Paper className={classes.fullwidthpaperFH}>
              <Grid container spacing={1} className={classes.formHeader}>
                <Grid item xs={12} sm={12}>
                  <Typography color="inherit" className={classes.tableFH}>
                    Job Code :{" "}
                    <a href="#">
                      <span onClick={() => handleEdit()}>{job.jobCode}</span>
                    </a>
                  </Typography>
                  <Typography color="inherit" className={classes.tableFH}>
                    Location :{" "}
                    <a href="#">
                      <span onClick={() => handleJobPayItm()}>
                        {job.jobLocation}
                      </span>
                    </a>
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          )}
          <Paper className={classes.fullwidthpaper}>
            <TableContainer>
              <StationDetailsList
                data={stationItem}
                onStationDelete={DeleteStation}
                onStationEdit={EditStation}
                showEdit={showAdmin}
              />
              {/* <Table3 columns={columns_2} data={stationItem} /> */}
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

export default SubLineItem;
