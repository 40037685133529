import React from "react"
import SubLineItemForm from "../forms/SubLineItmForm"
import { Grid } from "@material-ui/core"

function SubLineItmComp({ match }) {
  return (
    <Grid container direction="row">
      <SubLineItemForm match={match} />
    </Grid>
  )
}
export default SubLineItmComp