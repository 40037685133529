import { makeStyles } from "@material-ui/core/styles";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import MaterialTable from "material-table";
import React, { useCallback } from "react";

const useStyles = makeStyles((theme) => ({
  formControl: {
    padding: "0px 8px",
    "margin-top": "8px",
  },
  headcontainer: {
    margin: "15px 0%",
  },
  fullwidthform: {
    width: "100%",
    padding: "0px 15px",
  },
  fullwidthpaper: {
    width: "100%",
  },
  button: {
    color: "white",
    fontWeight: 500,
    fontSize: 14,
    letterSpacing: 0.8,
    borderRadius: 20,
  },
  lvlResize: {
    left: "10px",
  },
  formFooter: {
    padding: "15px 18px",
    background: "#efefef",
    "margin-top": "25px",
  },
  fullwidthpaperFH: {
    width: "100%",
    "background-color": "rgb(0,176,255,0.7)",
    "border-right": "10px solid #ff5722",
  },
  formHeader: {
    padding: "10px 15px",
    "font-weight": "bold",
    "font-size": "18px",
    "letter-spacing": "0.5px",
    color: "white",
  },
  tableFH: {
    "font-weight": "500",
    "text-transform": "uppercase",
    "font-size": "14px",
  },
  LeftCND: {
    "padding ": "4px 7px 0px 0px",
  },
  RightCND: {
    "padding ": "0px 0px 0px 7px",
  },
}));

const Estimates = ({ data, onStationDelete, onStationEdit, showAdmin }) => {
  const handleDelete = useCallback(
    (data) => {
      onStationDelete(data);
    },
    [onStationDelete]
  );
  const handleEdit = useCallback(
    (row) => {
      onStationEdit(row);
    },
    [onStationEdit]
  );

  return (
    <MaterialTable
      title="Estimates Grid"
      columns={[
        { title: "Pay Item", field: "payItmCode" },
        { title: "Estimate No", field: "estimateNum", defaultGroupOrder: 0 ,defaultGroupSort:"desc",defaultSort:"desc"},
        { title: "MDOT Paid Quantity", field: "mdotQty" },
        { title: "Created On", field: "createdDate" },
        { title: "Transfer Date", field: "transferDate" },
        { title: "Prime Paid Quantity", field: "primeQty" },
        { title: "Prime Paid Date", field: "paidDate" },
      ]}
      data={data}
      options={{
        grouping: true,
        pageSize: 100,
        pageSizeOptions: [25, 50, 100, 200],
        emptyRowsWhenPaging: false,
        showTitle: false,
      }}
      editable={{
        isDeletable: (rowData) => showAdmin,
        onRowDelete: (oldData) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              handleDelete(oldData);
              resolve();
            }, 1000);
          }),
      }}
      actions={[
        {
          icon: () => <EditOutlinedIcon />,
          tooltip: "Edit",
          disabled: showAdmin == true ? false : true,
          onClick: (event, rowData) => {
            handleEdit(rowData);
          },
        },
      ]}
    />
  );
};

export default Estimates;
