import React from "react";
import EstimateForm from "../estimates/estimateHome";
import { Grid } from "@material-ui/core";

function EstimateComp({ match }) {
  
  return (
    <Grid container direction="row">
      <EstimateForm match={match} />
    </Grid>
  );
}
export default EstimateComp;
