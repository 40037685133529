import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, usePagination, useSortBy } from 'react-table'
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper'
import { CSVLink } from "react-csv";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TextField,
  Select,
  MenuItem,
} from "@material-ui/core";
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import matchSorter from 'match-sorter'
import { apiService } from '../../services/api.service';
const useStyles = makeStyles((theme) => ({
  paginationRoot: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  btnPagination: {
    color: 'rgba(0, 0, 0, 0.54)',
    'background-color': 'transparent',
    border: 'none',
    "& : disabled": {
      color: 'rgba(0, 0, 0, 0.26)',
    },
  },
  footerPagination: {
    'textAlign': 'right',
  }
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#4cc8ff',
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },

}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);


function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <span>
      Search:{' '}
      <input
        value={value || ""}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        style={{
          fontSize: '1.1rem',
          border: '0',
        }}
      />
    </span>
  )
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length

  return (
    <TextField
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  )
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  // Render a multi-select box
  return (
    <Select
      displayEmpty
      value={filterValue || ""}
      onChange={e => {
        setFilter(e.target.value || undefined)
      }}
    >
      <MenuItem value={""}>All</MenuItem>
      {options.map((option, i) => (
        <MenuItem key={i} value={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  )
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val

function Table2({ columns, data }) {
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const handleClick = (row) => {    
    history.push("/EditPayItem/" + row.original.payItmId);
  }
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  )

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 25 },
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  // Render the UI for your table
  return (
    <>
      <TableContainer component={Paper}>
        <Table {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <StyledTableCell {...column.getHeaderProps()}>
                    <span {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                    <div>
                      {column.canFilter ? column.render("Filter") : null}
                    </div>
                  </StyledTableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <StyledTableRow
                  {...row.getRowProps({ onClick: () => handleClick(row) })}
                >
                  {row.cells.map((cell) => {
                    return (
                      <StyledTableCell {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </StyledTableCell>
                    );
                  })}
                </StyledTableRow>
              );
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <StyledTableCell colSpan="6">
                <span><CSVLink data={data} filename={'payitems.csv'}>Download To CSV</CSVLink></span>
                <div className={classes.footerPagination}>
                  <span>Rows Per page: </span>{" "}
                  <select
                    value={pageSize}
                    onChange={(e) => {
                      setPageSize(Number(e.target.value));
                    }}
                  >
                    {[25, 50, 100, data.length].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        {pageSize}
                      </option>
                    ))}
                  </select>
                  <span>
                    {" "}
                    <strong>
                      {pageIndex + 1} of {pageOptions.length}
                    </strong>
                  </span>
                  <button
                    className={classes.btnPagination}
                    onClick={() => gotoPage(0)}
                    disabled={!canPreviousPage}
                  >
                    {theme.direction === "rtl" ? (
                      <LastPageIcon />
                    ) : (
                        <FirstPageIcon />
                      )}
                  </button>
                  <button
                    className={classes.btnPagination}
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                  >
                    {theme.direction === "rtl" ? (
                      <KeyboardArrowRight />
                    ) : (
                        <KeyboardArrowLeft />
                      )}
                  </button>
                  <button
                    className={classes.btnPagination}
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                  >
                    {theme.direction === "rtl" ? (
                      <KeyboardArrowLeft />
                    ) : (
                        <KeyboardArrowRight />
                      )}
                  </button>
                  <button
                    className={classes.btnPagination}
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                  >
                    {theme.direction === "rtl" ? (
                      <FirstPageIcon />
                    ) : (
                        <LastPageIcon />
                      )}
                  </button>
                </div>
              </StyledTableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      {/* 
        Pagination can be built however you'd like. 
        This is just a very basic UI implementation:
      */}
    </>
  );
}

function PayItemTable() {
  const [data, setData] = useState([])
  useEffect(() => {
    apiService.getContent("pi/")
      .then(edata => setData(edata))
  }, [])

  const columns = React.useMemo(
    () => [
      {
        Header: "Pay Item",
        accessor: "payItmCode"
      },
      {
        Header: "Description",
        accessor: "payItmDesc"
      },
      {
        Header: "Prequal Code",
        accessor: "prequalCode"
      },
      {
        Header: "Required Material",
        accessor: "reqMat"
      },
      {
        Header: "Required Material Desc",
        accessor: "reqmatDesc"
      },
      {
        Header: "Base Unit",
        accessor: "baseUnit"
      }
    ], [])

  return (
    <Table2 columns={columns} data={data} />
  )
}

export default PayItemTable