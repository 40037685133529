import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { useSnackbar } from "notistack";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmail } from "validator";
import VpnKeyIcon from "@material-ui/icons/VpnKey";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const ForgotPassword = () => {
  const form = useRef();

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const dispatch = useDispatch();

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const handleForgotPass = (e) => {
    e.preventDefault();

    if (username.length < 3 || username.length > 20) {
      setUsernameError("The username must be between 3 and 20 characters.");
      return false;
    } else setUsernameError("");
    if (!isEmail(email)) {
      setEmailError("This is not a valid email.");
      return false;
    } else setEmailError("");

    dispatch()
      .then((suc) => {
        enqueueSnackbar("Reset password link is sent to the email", {
          variant: "success",
        });
      })
      .catch((error) => {
        enqueueSnackbar("Error: " + error, { variant: "error" });
      });

    // form.current.validateAll();

    //if (checkBtn.current.context._errors.length === 0) {
    //}
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <VpnKeyIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Forgot Password
        </Typography>
        <form className={classes.form} onSubmit={handleForgotPass} ref={form}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoComplete="Uname"
                name="username"
                value={username}
                onChange={onChangeUsername}
                variant="outlined"
                required
                fullWidth
                id="username"
                label="User Name"
                autoFocus
                error={usernameError.length > 1}
                helperText={usernameError}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                value={email}
                onChange={onChangeEmail}
                autoComplete="email"
                error={emailError.length > 1}
                helperText={emailError}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Reset
          </Button>

          <Grid container justify="flex-end">
            <Grid item>
              <Link href="/login" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};
export default ForgotPassword;
