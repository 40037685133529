import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import MaterialTable from "material-table";
import React, { useCallback } from "react";

const StationDetailsList = ({
  data,
  onStationDelete,
  onStationEdit,
  showEdit,
}) => {
  const handleDelete = useCallback(
    (data) => {
      onStationDelete(data);
    },
    [onStationDelete]
  );
  const handleEdit = useCallback(
    (row) => {
      onStationEdit(row);
    },
    [onStationEdit]
  );
  return (
    <MaterialTable
      title="Station Grid"
      columns={[
        { title: "Station", field: "stationName" },
        { title: "Pay Item", field: "payItmCode" },
        { title: "Description", field: "payItmDesc", width: 450 },
        { title: "Plan Quantity", field: "quantity", type: "numeric" },
        { title: "NPI Quantity", field: "npiQuantity", type: "numeric" },
      ]}
      data={data}
      options={{
        grouping: true,
        pageSize: 50,
        pageSizeOptions: [25, 50, data.length > 100 ? data.length : 75],
        emptyRowsWhenPaging: false,
        padding: "dense",
      }}
      editable={{
        isDeletable: (rowData) => !rowData.used && showEdit,
        onRowDelete: (oldData) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              handleDelete(oldData);
              resolve();
            }, 1000);
          }),
      }}
      actions={[
        {
          icon: () => <EditOutlinedIcon />,
          tooltip: "Edit",
          onClick: (event, rowData) => {
            handleEdit(rowData);
          },
          disabled: showEdit === true ? false : true,
        },
      ]}
    />
  );
};

export default StationDetailsList;
