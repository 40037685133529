import {
  Button,
  FormControl,
  Grid,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CancelIcon from "@material-ui/icons/Cancel";
import SaveIcon from "@material-ui/icons/Save";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTable } from "react-table";
import * as Yup from "yup";
import { apiService } from "../../services/api.service";
import JobMenu from "../menu/JobLinks";
import JobPayItemList from "../tablelist/JobPayItmList";
import FkInputFld from "./formik-custom-fields/FkInputFld";
import FkSelectFld from "./formik-custom-fields/FkSelectFld";
import { useDispatch, useSelector } from "react-redux";
import ExcelUpload from "./JobPayItmExcelUpload";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#4cc8ff",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 13,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
function Table2({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });
  // Render the UI for your table
  return (
    <Table {...getTableProps()}>
      <TableHead>
        {headerGroups.map((headerGroup) => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <StyledTableCell {...column.getHeaderProps()}>
                {column.render("Header")}
                {/* Render the columns filter UI */}
                {/* <div>{column.render('Header')}</div> */}
              </StyledTableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <StyledTableRow {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <StyledTableCell {...cell.getCellProps()}>
                    {cell.render("Cell")}
                  </StyledTableCell>
                );
              })}
            </StyledTableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}
const useStyles = makeStyles((theme) => ({
  formControl: {
    padding: "0px 8px",
    "margin-top": "8px",
  },
  headcontainer: {
    margin: "15px 0%",
  },
  fullwidthform: {
    width: "100%",
    padding: "0px 15px",
  },
  fullwidthpaper: {
    width: "100%",
  },
  button: {
    color: "white",
    fontWeight: 500,
    fontSize: 14,
    letterSpacing: 0.8,
    borderRadius: 20,
  },
  lvlResize: {
    left: "10px",
  },
  formFooter: {
    padding: "15px 18px",
    background: "#efefef",
    "margin-top": "25px",
  },
  fullwidthpaperFH: {
    width: "100%",
    "background-color": "rgb(0,176,255,0.7)",
    "border-right": "10px solid #ff5722",
  },
  formHeader: {
    padding: "10px 15px",
    "font-weight": "bold",
    "font-size": "18px",
    "letter-spacing": "0.5px",
    color: "white",
  },
  tableFH: {
    "font-weight": "500",
    "text-transform": "uppercase",
    "font-size": "14px",
  },
  LeftCND: {
    "padding ": "4px 7px 0px 0px",
  },
  RightCND: {
    "padding ": "0px 0px 0px 7px",
  },
}));
const JobPayItem = ({ match }) => {
  const [contr, setContr] = useState([]);
  const [job, setJob] = useState({});
  const [payitm, setPayitm] = useState([]);
  const [payitmType, setPayitmType] = useState([]);
  const [lineItem, setLineItem] = React.useState([]);
  const [baseu, setBaseu] = useState([]);
  const [hasJobId, sethasJobId] = React.useState(true);
  const [editMode, setEditMode] = React.useState(false);
  const [initialValues, setinitialValues] = useState({
    payItmCode: "",
    payItmDesc: "",
    payItmId: "",
    pre_code: "",
    req_mat: "",
    quantity: "",
    baseUnit: "",
    unitPrice: "",
    contractor: 3,
    notes: "",
    typeId: "",
    status: 4,
    laborCost: "",
    supplPrice: "",
    subconPrice: "",
    rate:"",
  });
  const [defaultAutoCompVal, setDefaultAutoCompVal] = useState(null);
  const [defaultContractorVal, setDefaultContractorVal] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [showAdmin, setShowAdmin] = useState(false);
  const { user: currentUser } = useSelector((state) => state.auth);
  const [file, setFile] = useState([]);
  useEffect(() => {
    //console.log(match);
    if (!match.params.id) {
      sethasJobId(false);
    } else {
      let reqestp =
        typeof match !== "undefined" && match.params.id !== "0"
          ? match.params.id
          : "0";
      apiService.getContent("jpi/page?jobid=" + reqestp).then((edata) => {
        //console.log(edata);
        setContr(edata.contractors);
        setPayitmType(edata.payItmTypes);
        setBaseu(edata.baseunit);
        setJob(edata.job);
        setPayitm(edata.payItms);

        setDefaultContractorVal(edata.contractors.find(x => x.bpId == 3));
        var lineitem = mapcontractorName(edata.jobPayItms, edata.contractors)
        setLineItem(lineitem);
      });
    }
    setShowAdmin(currentUser.roles.includes("ROLE_ADMIN"));
  }, [file]);

  function mapcontractorName(data, contractorList) {
    const newList = data.map(p => contractorList.find(c => c.bpId == p.contractor) != undefined
      ? { ...p, contractorName: contractorList.find(c => c.bpId == p.contractor).name }
      : p);
    //console.log(newList);
    return newList

  }

  function refreshTable() {
    apiService.getContent("jpi/pi?jobid=" + job.jobId).then((edata) => {
      const data = mapcontractorName(edata, contr)
      setLineItem(data);
    });
  }

  const handleClose = () => {
    sethasJobId(true);
  };
  const payitmTypes = payitmType.map((ptype) => ({
    value: ptype.payitmTypeId,
    label: ptype.payitmTypeName,
  }));
  const baseunits = baseu.map((ptype) => ({
    value: ptype.buId,
    label: ptype.unit + " - " + ptype.desc,
  }));
  const contractMod = [
    { value: 4, label: "Initial" },
    { value: 5, label: "Contract Modification" },
  ];

  //const data = React.useMemo()
  const classes = useStyles();
  const history = useHistory();
  const handleJob = () => {
    // console.log(job.jobCode);
    history.push("/joblist/" + job.jobId);
  };
  const handleSite = () => {
    //console.log(job.jobCode);
    history.push("/stationlist/" + job.jobId);
  };
  const DeleteJobPayItem = (data) => {
    let delJsp = data.jobPayitmId;
    apiService
      .deleteObject("jpi/" + delJsp)
      .then((res) => {
        refreshTable();
        enqueueSnackbar("Deleted job pay item successfully", {
          variant: "success",
        });
      })
      .catch((err) =>
        enqueueSnackbar("There is an error in deleting job payitem:" + err, {
          variant: "error",
        })
      );
  };
  const EditNewJobPayItem = (data) => {
    console.log(data);
    var editpayitem = payitm.find((x) => x.payItmId == data.payItmId);
    setDefaultAutoCompVal(editpayitem);
    var editContr = contr.find(x => x.bpId == data.contractor);
    setDefaultContractorVal(editContr);
    setinitialValues({
      jobPayitmId: data.jobPayitmId,
      payItmId: data.payItmId,
      payItmCode: data.payItmCode,
      payItmDesc: data.payItmDesc,
      pre_code: data.prequalCode,
      typeId: data.typeId,
      req_mat: data.requiredMaterial,
      quantity: data.quantity,
      baseUnit: data.baseUnit,
      unitPrice: data.unitPrice,
      contractor: data.contractor,
      notes: data.notes,
      status: data.status,
      laborCost: data.laborCost,
      supplPrice: data.supplPrice,
      subconPrice: data.subconPrice,
      rate: data.rate

    });
    setEditMode(true);
  };
  const clearFormFields = () => {
    setinitialValues({
      payItmCode: "",
      payItmDesc: "",
      payItmId: "",
      pre_code: "",
      req_mat: "",
      quantity: "",
      baseUnit: "",
      unitPrice: "",
      contractor: 3,
      notes: "",
      typeId: "",
      status: 4,
      laborCost: "",
      supplPrice: "",
      subconPrice: "",
      rate:""

    });
  };
  const cancelUpload=()=>{
    setFile([]);
  }
  return (
    <>
      <Grid
        container
        alignItems="flex-start"
        justify="flex-end"
        direction="row"
      >
        <JobMenu jobId={job.jobId} Wday={null} headerText={"Job Pay Items"} payitemUpload={true} onFileSet={setFile}/>
      </Grid>

      <Grid className={classes.headcontainer} container>
        {file.length>0 && (
          <ExcelUpload file={file} uploadCancelEvent={cancelUpload} jobId={job.jobId} />
        )}
       
        {file.length<=0 && showAdmin && (
          <Grid item xs={12} sm={5} className={classes.LeftCND}>
            <Paper className={classes.fullwidthpaperFH}>
              <Grid container spacing={1} className={classes.formHeader}>
                <Grid item xs={12} sm={12}>
                  <Typography color="inherit" className={classes.tableFH}>
                    Job Code :{" "}
                    <a href="#">
                      <span onClick={() => handleJob()}>{job.jobCode}</span>
                    </a>
                  </Typography>
                  <Typography color="inherit" className={classes.tableFH}>
                    Location :{" "}
                    <a href="#">
                      <span onClick={() => handleSite()}>
                        {job.jobLocation}
                      </span>
                    </a>
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
            <pre>{/* {console.log(payitems), console.log(payitm)} */}</pre>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={Yup.object().shape({
                payItmCode: Yup.string().required(),
                quantity: Yup.string().required("Quantity is a required field"),
                unitPrice: Yup.string().required("Unit Price is a required field"),
              })}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                var jobPIsave = {
                  ...values,
                  jobId: job.jobId,
                };
                console.log(JSON.stringify(jobPIsave));

                apiService
                  .addContent("jpi/", jobPIsave)
                  .then((response) => {
                    console.log(response);
                    refreshTable();
                    enqueueSnackbar("Added job pay item successfully", {
                      variant: "success",
                    });
                    //Reset the form
                    clearFormFields();
                    setDefaultAutoCompVal(null); //Reset Autocomplete
                    setDefaultContractorVal(contr.find((x) => x.bpId == 3));
                    //----------
                  })
                  .catch((error) => {
                    console.log("error: " + error);
                    enqueueSnackbar(
                      "There is an error in adding job payitem:" + error,
                      { variant: "error" }
                    );
                  });

                setSubmitting(false);
                setEditMode(false);
              }}
            >
              {({ errors, status, touched, setFieldValue, values }) => (
                <Paper className={classes.fullwidthpaper}>
                  <Form className={classes.fullwidthform}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <FormControl
                          className={classes.formControl}
                          fullWidth={true}
                          margin={"normal"}
                          size={"medium"}
                        >
                          <Autocomplete
                            id="payItem"
                            blurOnSelect
                            //options={AutocompPayItm.map((option) => option)}
                            value={defaultAutoCompVal}
                            options={payitm}
                            getOptionLabel={(option) => option.payItmCode}
                            onChange={(_, value) => {
                              //console.log(value);
                              setDefaultAutoCompVal(value);
                              if (value) {
                                setFieldValue("payItmCode", value.payItmCode);
                                setFieldValue("payItmDesc", value.payItmDesc);
                                setFieldValue("payItmId", value.payItmId);
                                setFieldValue("pre_code", value.prequalCode);
                                setFieldValue("req_mat", value.reqMat); //TODO..Chk Id is going.. May be  req_mat
                                let a = baseu.find(
                                  (x) => x.unit === value.baseUnit
                                );
                                console.log(a);
                                if (a) {
                                  setFieldValue("baseUnit", a.buId);
                                }
                                setFieldValue("typeId", value.typeId);
                                setFieldValue("contractor", 3);
                              } else {
                                setFieldValue("payItmCode", "");
                                setFieldValue("payItmId", "");
                                setFieldValue("payItmDesc", "");
                                setFieldValue("pre_code", "");
                                setFieldValue("req_mat", ""); //TODO..Chk Id is going.. May be  req_mat
                                setFieldValue("baseUnit", "");
                                setFieldValue("typeId", "");
                              }
                            }}
                            renderOption={(option) => (
                              <React.Fragment>
                                {option.payItmCode} - {option.payItmDesc}
                              </React.Fragment>
                            )}
                            renderInput={(params) => (
                              <TextField {...params} label="Pay Item Code" />
                            )}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={8}>
                        <FkInputFld
                          name="payItmDesc"
                          label="Pay Item Description"
                          controlClass={classes.formControl}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FkInputFld
                          name="pre_code"
                          label="Prequal Code"
                          controlClass={classes.formControl}
                          disabled
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={8}>
                        <FkInputFld
                          name="req_mat"
                          label="Required Materials"
                          controlClass={classes.formControl}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FkSelectFld
                          name="baseUnit"
                          items={baseunits}
                          label="Base Unit"
                          controlClass={classes.formControl}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1}></Grid>

                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={4}>
                        <FkInputFld
                          name="quantity"
                          label="Quantity"
                          controlClass={classes.formControl}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FkSelectFld
                          name="typeId"
                          items={payitmTypes}
                          label="Payitem Type"
                          controlClass={classes.formControl}
                          lableClass={classes.lvlResize}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FkSelectFld
                          name="status"
                          items={contractMod}
                          label="Change Type"
                          controlClass={classes.formControl}
                          lableClass={classes.lvlResize}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={4}>
                        <FkInputFld
                          name="unitPrice"
                          label="Unit Price"
                          placeholder="Unit Price"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                          controlClass={classes.formControl}
                        />
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <FormControl
                          className={classes.formControl}
                          fullWidth={true}
                          margin={"normal"}
                          size={"medium"}
                        >
                          <Autocomplete
                            id="contractor"
                            // blurOnSelect
                            value={defaultContractorVal}
                            options={contr}
                            getOptionLabel={(option) => option.name}
                            onChange={(_, value) => {
                              setDefaultContractorVal(value);
                              if (value) {
                                setFieldValue("contractor", value.bpId);
                              } else {
                                setFieldValue("contractor", null);
                              }
                            }}
                            renderInput={(params) => (
                              <TextField {...params} label="Contractor" />
                            )}
                          />
                        </FormControl>
                      </Grid>

                      <Grid container spacing={1}>
                        <Grid item xs={6} sm={6}>
                          <FkInputFld
                            name="laborCost"
                            label="Labor Unit Cost"
                            placeholder="Labor Unit Cost"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                            }}
                            controlClass={classes.formControl}
                          />
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <FkInputFld
                            name="supplPrice"
                            label="Materials Cost"
                            placeholder="Materials Cost"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                            }}
                            controlClass={classes.formControl}
                          />
                        </Grid>

                      </Grid>
                      <Grid container spacing={1}>
                        <Grid item xs={6} sm={6}>
                          <FkInputFld
                            name="subconPrice"
                            label="Subcontractor Unit Price"
                            placeholder="Subcontractor Unit Price"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                            }}
                            controlClass={classes.formControl}
                          />
                          {/* <FkInputFld
                            name="subconPrice"
                            label="Subcontractor Unit Price"
                            controlClass={classes.formControl}
                          /> */}
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <FkInputFld
                            name="rate"
                            label="Rate Per Day"
                            controlClass={classes.formControl}
                          />
                          {/* <FkInputFld
                            name="subconPrice"
                            label="Subcontractor Unit Price"
                            controlClass={classes.formControl}
                          /> */}
                        </Grid>
                      </Grid>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={12}>
                          <FkInputFld
                            name="notes"
                            label="Note"
                            controlClass={classes.formControl}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container className={classes.formFooter}>
                      <Grid item xs={6} md={6}>
                        <Button
                          variant="contained"
                          style={{ background: "#ff5722" }}
                          className={classes.button}
                          startIcon={<CancelIcon />}
                          onClick={() => history.goBack()}
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item xs={6} md={6} style={{ textAlign: "right" }}>
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          startIcon={<SaveIcon />}
                          type="submit"
                        >
                          {editMode ? "Update" : "Add"}
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                </Paper>
              )}
            </Formik>
          </Grid>
        )}
        {file.length<=0 && (
        <Grid item xs={12} sm={showAdmin ? 7 : 12} className={classes.RightCND}>
          {!showAdmin && (
            <Paper className={classes.fullwidthpaperFH}>
              <Grid container spacing={1} className={classes.formHeader}>
                <Grid item xs={12} sm={12}>
                  <Typography color="inherit" className={classes.tableFH}>
                    Job Code :{" "}
                    <a href="#">
                      <span onClick={() => handleJob()}>{job.jobCode}</span>
                    </a>
                  </Typography>
                  <Typography color="inherit" className={classes.tableFH}>
                    Location :{" "}
                    <a href="#">
                      <span onClick={() => handleSite()}>
                        {job.jobLocation}
                      </span>
                    </a>
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          )}
          <Paper className={classes.fullwidthpaper}>
            {/* <TableContainer>
            <Table2 columns={columns} data={lineItem} />
          </TableContainer> */}
            <JobPayItemList
              data={lineItem}
              onJobPayItemDelete={DeleteJobPayItem}
              onJobPayItemEdit={EditNewJobPayItem}
              refreshTable={refreshTable}
              showEdit={showAdmin}
            />
          </Paper>
        </Grid>
        )}
        <div>
          {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open form dialog
      </Button> */}
          <Dialog
            open={!hasJobId}
            onClose={handleClose}
            disableBackdropClick
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Search</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Please select a Jobid to add new Pay items.
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Job Id"
                type="text"
                fullWidth
                variant="outlined"
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  history.goBack();
                }}
                color="primary"
              >
                Cancel
              </Button>
              <Button onClick={handleClose} color="primary">
                Search
              </Button>
            </DialogActions>
          </Dialog>
        </div>
         
        
      </Grid>
    </>
  );
};

export default JobPayItem;
