import { Grid, InputAdornment } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import HistoryIcon from "@material-ui/icons/History";
import MaterialTable from "material-table";
import React, { useCallback } from "react";
import { apiService } from "../../services/api.service";
const tableStyle = {
  '& .MuiTableDetailCell-root': {
    width: '50px', // Define the desired width for the DetailPanel td element
  },
  '& .MuiTableDetailPanel-icon': {
    width: '20px', // Define the desired width for the DetailPanel icon
  },
  '& .MuiTableCell-paddingCheckbox': {
    // Adjust the margin or padding as needed
    padding: '4px',
  },
};

const useStyles = makeStyles((theme) => ({
  innerTableHeading: {
    "padding-left": "17px",
    "text-transform": "uppercase",
    "font-size": "14px",
    "font-weight": "700",
  },
  formControl: {
    minWidth: 150,
  },
  displayNone: {
    display: "none",
  },
}));

const JobPayItemList = ({
  data,
  onJobPayItemDelete,
  refreshTable,
  onJobPayItemEdit,
  showEdit
}) => {
  const [open, setOpen] = React.useState(false);

  const handleDelete = useCallback(
    (data) => {
      onJobPayItemDelete(data);
    },
    [onJobPayItemDelete]
  );
  const handleClickOpen = (rowData) => {
    console.log(rowData);
    setOpen(true);
    setUpayItem({ ...upayitem, jobPayitmId: rowData.jobPayitmId });
  };
  const handleEdit = useCallback(
    (data) => {
      onJobPayItemEdit(data);
    },
    [onJobPayItemEdit]
  );
  const [upayitem, setUpayItem] = React.useState({
    recType: "",
    quantity: "",
    notes: "",
    jobPayitmId: "",
    unitPrice: "",
    laborCost: "",
    supplPrice: "",
    subconPrice: "",
    rate: ""
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    // setUpayItem((prev) => ({ ...prev, [name]: value }));
    if (event.target.name === "quantity") {
      setUpayItem({ ...upayitem, quantity: event.target.value });
    } else if (event.target.name === "notes") {
      setUpayItem({ ...upayitem, notes: event.target.value });
    } else if (event.target.name === "recType") {
      setUpayItem({ ...upayitem, recType: event.target.value });
    } else if (event.target.name === "unitPrice") {
      setUpayItem({ ...upayitem, unitPrice: event.target.value });
    }
    else if (event.target.name === "laborCost") {
      setUpayItem({ ...upayitem, laborCost: event.target.value });
    }else if (event.target.name === "supplPrice") {
      setUpayItem({ ...upayitem, supplPrice: event.target.value });
    }else if (event.target.name === "subconPrice") {
      setUpayItem({ ...upayitem, subconPrice: event.target.value });
    }else if (event.target.name === "rate") {
      setUpayItem({ ...upayitem, rate: event.target.value });
    }
};
  const handleClose = () => {
    setOpen(false);
  };
  const classes = useStyles();
  const handleAdd = () => {
    console.log(upayitem);
    apiService
      .addContent("jpi/rec", upayitem)
      .then((response) => {
        refreshTable();
      })
      .catch((error) => {
        console.log("error: " + error);
      });
    setOpen(false);
  };
  return (
    <>
      <MaterialTable
        title=""
        columns={[
          {
            title: "Pay Item",
            field: "payItmCode",
          },
          {
            title: "Desc",
            field: "payItmDesc",
            cellStyle: { width: 300 },
          },
          {
            title: "Contractor",
            field: "contractorName",
            //cellStyle: { width: 150 },
          },
          {
            title: "Unit",
            field: "unit",
            cellStyle: { width: 50 },
            align: "center",
          },
          {
            title: "Quantity",
            field: "calQty",
            type: "numeric",
            cellStyle: { width: 50 },
            align: "center",
          },
          {
            title: "Price",
            field: "unitPrice",
            type: "currency",
            cellStyle: { width: 50 },
            align: "center",
          },
          {
            title: "Labor Unit Cost",
            field: "laborCost",
            type: "currency",
            cellStyle: { width: 50 },
            align: "center",
          },
          {
            title: "Material Cost",
            field: "supplPrice",
            type: "currency",
            cellStyle: { width: 50 },
            align: "center",
          },
          {
            title: "Sub-Contractor Unit Price",
            field: "subconPrice",
            type: "currency",
            cellStyle: { width: 50 },
            align: "center",
          },
          {
            title: "Rate Per Day",
            field: "rate",
            cellStyle: { width: 25 },
            align: "center",
          },
          {
            field: "payItmId",
            hidden: true,
          },
        ]}
        data={data}
        detailPanel={[
          {
            tooltip: "Show Details",
            render: (rowData) => {
              return (
                <Box margin={1}>
                  {/* {console.log(rowData)} */}
                  <Typography
                    className={classes.innerTableHeading}
                    gutterBottom
                    component="div"
                  >
                    More Details
                  </Typography>
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        {/* <TableCell>Description</TableCell> */}
                        <TableCell>NPI</TableCell>
                        <TableCell>Required Material</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {/* {rowData.map((historyRow) => ( */}
                      <TableRow>
                        <TableCell>{rowData.nonprod}</TableCell>
                        <TableCell>{rowData.reqmat}</TableCell>
                      </TableRow>
                      {/* ))} */}
                    </TableBody>
                  </Table>
                </Box>
              );
            },
          },
          (rowData) => ({
           
            disabled: !rowData.jobPayitemRecords.length > 0,
            icon: () => (
              <span>
                <HistoryIcon
                  className={
                    !rowData.jobPayitemRecords.length > 0 && classes.displayNone
                  }
                  style={{ fontSize: 24, color: "#7090cb" }}
                />
              </span>
            ), //,color: "#fb8c00"
            tooltip: "Show History",
            render: (rowData) => {
              return (
                <Box margin={1}>
                  {/* {console.log(rowData.jobPayitemRecords)} */}
                  <Typography
                    className={classes.innerTableHeading}
                    gutterBottom
                    component="div"
                  >
                    History
                  </Typography>
                  {rowData.jobPayitemRecords.length > 0 ? (
                    <Table size="small" aria-label="purchases">
                      <TableHead>
                        <TableRow>
                          <TableCell>Date</TableCell>
                          <TableCell>Change Type</TableCell>
                          <TableCell align="right">Notes</TableCell>
                          <TableCell align="right">Quantity</TableCell>
                          <TableCell align="right">Changed By</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rowData.jobPayitemRecords.map((historyRow) => (
                          <TableRow>
                            {/* key={historyRow.date} */}
                            <TableCell component="th" scope="row">
                              {/* {dateFormat(historyRow.createdOn, "yyyy-mm-dd h:MM:ss TT")} */}
                              {historyRow.createdOn}
                            </TableCell>
                            <TableCell>{historyRow.recDesc}</TableCell>
                            <TableCell align="right">
                              {historyRow.notes}
                              {/* {Math.round(historyRow.amount * row.price * 100) / 100} */}
                            </TableCell>
                            <TableCell align="right">
                              {historyRow.quantity}
                            </TableCell>
                            <TableCell align="right">
                              {historyRow.createdBy}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  ) : (
                    <div
                      style={{
                        fontSize: 16,
                        textAlign: "center",
                      }}
                    >
                      No records to display
                    </div>
                  )}
                </Box>
              );
            },
          }),
        ]}
        editable={{
          //isDeleteHidden:showEdit, //--> use to hide the delete btn..
          isDeletable: (rowData) => rowData.delete && showEdit,
          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                handleDelete(oldData);
                resolve();
              }, 1000);
            }),
        }}
        actions={[
          {
            icon: () => (
              <EditOutlinedIcon style={{ fontSize: 24, color: "#416522" }} />
            ),
            tooltip: "Edit",
            onClick: (event, rowData) => {
              if (rowData.delete && showEdit) {
                handleEdit(rowData);
              } else if (showEdit) {
                handleClickOpen(rowData);
              }
            },
          },
        ]}
        localization={{
          header: {
            actions: "",
          },
        }}
        options={{
          pageSize: 100,
          pageSizeOptions: [25, 50, 100, 200],
          // padding:"dense",
          emptyRowsWhenPaging: false,
          showTitle: false,
          
          cellStyle: {
            padding: "1px",
          },
          headerStyle: {
            padding: "1px",
          },
        }}
        //style={tableStyle}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Modify Job PayItem</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoFocus
                margin="dense"
                label="Quantity"
                type="text"
                name="quantity"
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="unitPrice"
                margin="dense"
                label="Unit Price"
                placeholder="Unit Price"
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      $
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
            <TextField
                name="laborCost"
                margin="dense"
                label="Labor Unit Cost"
                placeholder="Labor Unit Cost"
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      $
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="supplPrice"
                margin="dense"
                label="Materials Cost"
                placeholder="Materials Cost"
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      $
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
            <TextField
                name="subconPrice"
                margin="dense"
                label="Subcontractor Unit Price"
                placeholder="Subcontractor Unit Price"
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      $
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="rate"
                margin="dense"
                label="Rate Per Day"
               
                onChange={handleChange}
                
              />
            </Grid>
          </Grid>
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel id="demo-simple-select-label">Change Type</InputLabel>
            <Select
              //id="type-select"
              value={upayitem.changeType}
              onChange={handleChange}
              name="recType"
              fullWidth
            >
              <MenuItem value={5}>Contract Modification</MenuItem>
              <MenuItem value={4}>Wrong Entry</MenuItem>
              <MenuItem value={19}>Price Change</MenuItem>
            </Select>
          </FormControl>
          <TextField
            margin="normal"
            label="Change Notes"
            type="text"
            name="notes"
            onChange={handleChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAdd} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default JobPayItemList;
