import DateFnsUtils from '@date-io/date-fns';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import {
  KeyboardDatePicker, MuiPickersUtilsProvider
} from '@material-ui/pickers';
import 'date-fns';
import { useSnackbar } from 'notistack';
import React, { useImperativeHandle, useState } from 'react';
import { apiService } from '../../services/api.service';
const WtherDayDialog = React.forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [reasonError, setReasonError] = useState("");
  
  const [allValues, setAllValues] = useState({
    job: '',
    reason: '',
    weatherDay: new Date(),
    applyToAll: false
  });
  const { enqueueSnackbar } = useSnackbar();
  const clearState = () => {
    setAllValues({ job: '',
    reason: '',
    weatherDay: new Date(),
    applyToAll: false });
  };
  const changeHandler = e => {
    setAllValues({ ...allValues, [e.target.name]: e.target.value })
  }
  const handleDateChange = (date) => {
    setAllValues({ ...allValues, weatherDay: date })
  };
  const handleSwitchChange = e => {
    setAllValues({ ...allValues, [e.target.name]: e.target.checked })
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setReasonError("");
    clearState();
  };
  function handleSubmit() {
    //setAllValues({...allValues, job: props.props})
    console.log(allValues.reason);
    if(!allValues.reason)
    {
      setReasonError("Reason is required");
      return;
    }
    var pushv = { ...allValues, job: props.props };
    apiService.postContent("job/wd", pushv)
      .then(response => {
        enqueueSnackbar("Wether day added successfully!", {
          variant: "success",
        });
        setOpen(false);
        clearState();
      },
      (error) => {
        console.log(error)
        enqueueSnackbar("Error adding wetherday: " + error, {variant: "error", });
      });
  }
  useImperativeHandle(ref, () => ({
    handleClickOpen,
    handleClose
  }));
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      {/* <form onSubmit={handleSubmit}> */}
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Add Weather Day</DialogTitle>
          <DialogContent>
            {/* <DialogContentText>
            Please enter the following details
          </DialogContentText> */}
            <TextField
              autoFocus
              margin="dense"
              name="reason"
              label="Reason for delay"
              type="text"
              onChange={changeHandler}
              fullWidth
              required
              error={reasonError.length > 1}
              helperText={reasonError}
            // helperText="Incorrect entry."
            // error={allValues.reason === ""}
            />
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              name="weatherDate"
              label="Date"
              value={allValues.weatherDay}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              fullWidth
            />
            {/* Not Needed for now..
             <FormControl margin="normal">
              <FormControlLabel
                control={
                  <Switch
                    checked={allValues.applyToAll}
                    onChange={handleSwitchChange}
                    name="applyToAll"
                    color="primary"
                  />
                }
                labelPlacement="end"
                label="Apply to all active Jobs"
              />
            </FormControl> */}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" onClick={handleSubmit} color="primary">
              Add
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      {/* </form> */}
    </MuiPickersUtilsProvider>
  );
})

export default WtherDayDialog