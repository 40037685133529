import { FormControl, Grid, TextField } from "@material-ui/core";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import { apiService } from "../../services/api.service";
import ReportFooter from "./ReportFooter";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    margin: "0.75rem 0.75rem",
    //'box-shadowhadow': 'none',
  },
  formControl: {
    minWidth: 120,
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
  },
  pos: {
    marginBottom: 12,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
});

export default function EstTotalRptCpmp() {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [estimates, setEstimates] = useState([]);
  const [selectedestimate, setSelectedestimate] = useState(null);
  const [selectedjob, setSelectedJob] = useState(null);

  useEffect(() => {
    apiService.getContent("job/joblist").then((edata) => setData(edata));
  }, []);

  const onJobChange = (_, value) => {
    setSelectedestimate(null); //Reset old selected value if any..
    setSelectedJob(value);
    fetchEstimate(value);
  };
  function fetchEstimate(jobId) {
    try {
      apiService.getContent("est/uniq?jobcode=" + jobId).then((edata) => {
        setEstimates(edata);
      });
    } catch (e) {
      console.log(e.response);
    }
  }
  const runReport = (event, rptType) => {
    if (selectedjob && selectedestimate) {
      console.log(selectedjob + " ---Job");
      console.log(selectedestimate + " ---Estimate");
      //Submit form rptype...xlsx pdf
      var qrystr = "estimatebyjob&format=" + rptType + "&job=" + selectedjob+"&estimate="+selectedestimate;
      var fileName = selectedjob + "_"+selectedestimate+"." + rptType;
      apiService.getReport(qrystr, fileName);
    } else {
      console.log("Select values..");
    }
  };
  const alljobs = data.map((x) => x);
  return (
    <>
      <CardContent>
        <Typography className={classes.title} gutterBottom>
          Estimate Total by Job
        </Typography>
        <Typography variant="h5" component="h2"></Typography>
        <Typography className={classes.pos} color="textSecondary">
          Invoice by Estimate and Job
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormControl
              className={classes.formControl}
              fullWidth={true}
              margin={"normal"}
              size={"medium"}
            >
              <Autocomplete
                id="job"
                // blurOnSelect
                options={alljobs}
                onChange={onJobChange}
                renderInput={(params) => (
                  <TextField {...params} label="Select a Job" />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              className={classes.formControl}
              fullWidth={true}
              margin={"normal"}
              size={"medium"}
            >
              <Autocomplete
                id="estimate"
                // blurOnSelect
                value={selectedestimate}
                options={estimates}
                onChange={(_, value) => {
                  setSelectedestimate(value);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Select an Estimate" />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <ReportFooter sumbit={runReport} />
      </CardActions>
    </>
  );
}
