import { Button, Paper } from "@material-ui/core";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import Carousel from "react-material-ui-carousel";
import { DisplayFormikState } from "../../helper/DisplayFormikState";

export default function JobCards(props) {
  const [data, setData] = useState([]);

  useEffect(() => {
    calculateRows();
    //setData(props.items);
    //console.log(result);
    //console.log(data);
  }, []);
  const res = props.items.map(
    ({
      job,
      division,
      status,
      jobBoardsId,
      location,
      materials,
      enterDate,
      neededStart,
      neededEnd,
      missdigStart,
      missdigEnd,
      utilityNeeded,
    }) => ({
      job,
      division,
      status,
      jobBoardsId,
      location,
      materials,
      enterDate,
      neededStart,
      neededEnd,
      missdigStart,
      missdigEnd,
      utilityNeeded
    })
  );
  function calculateRows() {
    let rows = [];
    for (var i = 0; i < res.length; i++) {
      if (i == 0) {
        rows.push(res.slice(0, 10));
      } else if (i > 0 && i * 10 < res.length) {
        rows.push(res.slice(i * 10, i * 10 + 10));
      } else {
        break;
      }
    }
    setData(rows);
  }

  return (
    <>
      <Carousel
        stopAutoPlayOnHover={false}
        interval={7000}
        indicators={false}
        animation={"fade"} //fade" | "slide
        duration={100}
      >
        {data.map((item, i) => (
          <Item key={i} item={item} />
        ))}
      </Carousel>
    </>
  );
}

function Item(props) {
  return (
    <>
      {/* <p>
        <DisplayFormikState props={props.item} />
      </p> */}

      <MaterialTable
        title="Job Boards"
        columns={[
          {
            title: "Job / Work Order",
            field: "job",
            cellStyle: { color: "blue", fontWeight: 600 },
          },
          { title: "Division", field: "division" },
          { title: "Status", field: "status", hidden: true },
          { title: "jobBoardsId", field: "jobBoardsId", hidden: true },
          { title: "Location", field: "location", filtering: false },
          {
            title: "Materials",
            field: "materials",
          },
          {
            title: "Entered Date",
            field: "enterDate",
            cellStyle: { color: "#e407a0", fontWeight: 600 },
          },
          {
            title: "Date Needed-Start Date",
            field: "neededStart",
            cellStyle: { color: "#0c09fe", fontWeight: 600 },
          },
          {
            title: "Date Needed-End Date",
            field: "neededEnd",
            cellStyle: { color: "#e407a0", fontWeight: 600 },
          },
          {
            title: "Miss Dig-Start Date",
            field: "missdigStart",
            cellStyle: { color: "#0c09fe", fontWeight: 600 },
          },
          {
            title: "Miss Dig-End Date",
            field: "missdigEnd",
            cellStyle: { color: "#e407a0", fontWeight: 600 },
          },
          {
            title: "Utility Needed On Site",
            field: "utilityNeeded",
          }
        ]}
        data={props.item}
        actions={[]}
        options={{
          //paging: false,
          pageSize: 1000,
          pageSizeOptions:[10,50,100,1000],
          emptyRowsWhenPaging: false,
          headerStyle: {
            borderBottomColor: "#4cc8ff",
            fontWeight: "600",
            borderBottomWidth: "3px",
            fontFamily: "verdana",
            position: "sticky",
            top: 0,
          }, //background: "#4cc8ff",
          maxBodyHeight: "650px",
        }}
      />
    </>
  );
}
