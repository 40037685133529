import { Card } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import React, { Fragment } from "react";
import ActiveJobRpt from "./ActJobComp";
import CmptlQtyRpt from "./CmptlQtyComp";
import ExternalJob from "./ExtJobComp";
import JobDetails from "./JobDetailsComp";
import QtyByStation from "./QtyByStation";
import WoodSlipFdn from "./WoodSlipFdn";
const useStyles = makeStyles((theme) => ({
  media: {
    height: 250,
  },
  root: {
    flexGrow: 1,
    [theme.breakpoints.up("sm")]: {},
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  projectheading: {
    fontWeight: 500,
    letterSpacing: 0.5,
    fontSize: 17,
  },
  projectbtn: {
    "& a": {
      float: "right",
      textTransform: "inherit",
      fontWeight: 500,
      fontSize: 14,
      letterSpacing: 0.8,
      borderRadius: 20,
      color: "white",
    },
  },
  divider: {
    height: 4,
    backgroundImage: "linear-gradient(90deg, #00b0ff, #00C853)",
    width: "100%",
    marginTop: 15,
  },
  headcontainer: {
    margin: "15px 0%",
  },
  spatab: {
    margin: "0.75rem 0.75rem",
    "box-shadowhadow": "none",
  },
  card: {
    margin: 16,
    display: "flex",
    flexDirection: "column",
    // Justify the content so that CardContent will always be at the top of the card,
    // and CardActions will be at the bottom
    justifyContent: "space-between",
  },
}));

export default function OperationalReports() {
  const classes = useStyles();

  return (
    <Fragment>
      <Grid container alignItems="stretch">
        {/* <Grid item xs={12} md={3}>
              <EmpRpt />
            </Grid> */}
        <Grid item component={Card} xs className={classes.card}>
          <ActiveJobRpt />
        </Grid>
        <Grid item component={Card} xs className={classes.card}>
          <JobDetails />
        </Grid>
        <Grid item component={Card} xs className={classes.card}>
          <ExternalJob />
        </Grid>
      </Grid>
      <Grid container alignItems="stretch">
        <Grid item component={Card} xs className={classes.card}>
          <QtyByStation />
        </Grid>
        <Grid item component={Card} xs className={classes.card}>
          <CmptlQtyRpt />
        </Grid>
        <Grid item component={Card} xs className={classes.card}>
          <WoodSlipFdn />
        </Grid>
      </Grid>
    </Fragment>
  );
}
