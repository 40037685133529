import React from "react"
import FMJobTable from '../tablecmp/FMJobTable'
import { Divider, Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  projectheading: {
    fontWeight: 500,
    letterSpacing: 0.5,
    fontSize: 17,
  },
  divider: {
    height: 4,
    backgroundImage: 'linear-gradient(90deg, #00b0ff, #00C853)',
    width: '100%',
    marginTop: 15,
  },
  headcontainer: {
    margin: '15px 0%',
  },
  projectbtn: {
    "& a": {
      float: "right",
      textTransform: "inherit",
      fontWeight: 500,
      fontSize: 14,
      color: 'white',
      letterSpacing: 0.8,
      borderRadius: 20,
    },
  },
}));

function FMJobList() {
  const classes = useStyles();
  return (

    <Grid className={classes.headcontainer}  item xs={12} md={12}>
      <Divider className={classes.divider} />
      <FMJobTable />
    </Grid>

  )

}
export default FMJobList