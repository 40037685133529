import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { addDays } from "date-fns";
import React, { useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const RangePicker = (props) => {
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);
  const [showSelection, setshowSelection] = useState(false);
  const useStyles = makeStyles((theme) => ({
    typography: {
      padding: theme.spacing(2),
    },
  }));

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleReset = () => {
    setOpen(false);
    setshowSelection(false);
    props.onFilterChanged(props.columnDef.tableData.id, null);
    // Pass null to clear filter
  };

  const renderSelectionValue = () => {
    return (
      <div>
        <div>Selection</div>
        {state[0].startDate.toLocaleString().split(",")[0]}
        {" - "}
        {state[0].endDate.toLocaleString().split(",")[0]}
        {/* {console.log(state.startDate.toLocaleString().split(",")[0])} */}
      </div>
    );
  };
  return (
    <div>
      {showSelection && renderSelectionValue()}
      <Button onClick={handleClickOpen}>Filter</Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Select Date Range"}</DialogTitle>
        <DialogContent style={{ overflow: "hidden" }}>
          <DateRangePicker
            onChange={(item) => {
              console.log(item.selection);
              setState([item.selection]);
              setshowSelection(true);
              console.log(props.columnDef.tableData.id);
              props.onFilterChanged(
                props.columnDef.tableData.id,
                item.selection
              );
            }}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={1}
            ranges={state}
            direction="vertical"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleReset}>Reset</Button>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RangePicker;
