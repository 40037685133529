import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  FormControlLabel,
  FormLabel,
  Grid,
  makeStyles,
  Paper,
  Radio,
  Switch,
  TextField,
  useTheme,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import SaveIcon from "@material-ui/icons/Save";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Field, Form, Formik } from "formik";
import { RadioGroup } from "formik-material-ui";
import { KeyboardDatePicker } from "material-ui-formik-components/KeyboardDatePicker";
import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { DisplayFormikState } from "../../helper/DisplayFormikState";
import { apiService } from "../../services/api.service";
import FkInputFld from "./formik-custom-fields/FkInputFld";
import { useSnackbar } from "notistack";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(1),
  },
  demo2: {
    backgroundColor: "#4cc8ff", //TopBackGround Color
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  projectheading: {
    fontWeight: 500,
    letterSpacing: 0.5,
    fontSize: 17,
  },
  divider: {
    height: 4,
    backgroundImage: "linear-gradient(90deg, #00b0ff, #00C853)",
    width: "100%",
    marginTop: 15,
  },
  divider2: {
    marginTop: 15,
    marginBottom: 15,
  },
  headcontainer: {
    margin: "15px 0%",
  },
  fullwidthform: {
    width: "100%",
  },
  fullwidthpaper: {
    width: "100%",
    "padding-top": "15px",
  },
  formFooter: {
    padding: "15px 18px",
    background: "#efefef",
    "margin-top": "25px",
  },
  button: {
    color: "white",
  },
  container: {
    display: "flex",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  formControl: {
    padding: "0px 20px",
  },
  formControlTxt: {
    padding: "0px 20px",
    "& label": {
      padding: "0px 20px",
    },
  },
  formControlD: {
    padding: "0px 20px",
  },

  formControlLvL: {
    padding: "0px 25px",
    minWidth: 200,
    "& label": {
      left: 25,
    },
  },
  rdglvl: {
    padding: "15px 20px 0px 20px",
  },
  rdgControl: {
    padding: "0px 20px",
  },
}));
const EditDailyProd = ({ match, handleClose }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const formRef = useRef();
  const [initialValues, setinitialValues] = useState({
    payItmHasSupport: false,
    payItmHasElevation: false,
    payItemHasPost: false,
    payItmHasApproach: false,
    payItmHasMeasurements: false,
    payItmHasNpi: false,
    instNpi: "",
    instQty: "",
    instDate: new Date(),
    notes: "",
    grdrlPost: "Steel",
    grdrlPostLen: "",
    number_of_supports: "",
    chrgSuppLeft: "",
    chrgSuppRight: "",
    certSuppLeft: "",
    certSuppRight: "",
    fdnElLeft: "",
    fdnElRight: "",
    approachType: "MSKT",
    isComplete: false,
  });
  const inputProps = {
    step: 0.01,
  };
  const [chrgvalues, set_values] = useState({
    chrgSuppLeft: "",
    chrgSuppRight: "",
  });
  useEffect(() => {
    console.log(match);
    Promise.all([
      apiService.getContent(
        "dp/app/daily?item=" + match.Id + "&key=" + match.Key
      ),
    ])
      .then((edata) => {
        console.log(edata[0]);
        //setinitialValues(edata[0]);
        InitiatState(edata[0]);
        //console.log("Doen2");
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const InitiatState = (edata) => {
    let payItmType = edata.payItmClass;
    // console.log(row.original);
    let hasSupport = false;
    let hasElevation = false;
    let isSignPayItm = false;
    let hasPost = false;
    let hasApproach = false;
    let HasNpi = edata.npi != null ? true : false;

    if (payItmType.some((e) => e.payitmClass === "Support")) {
      hasSupport = true;
      isSignPayItm = true;
    }
    if (payItmType.some((e) => e.payitmClass === "Elevation")) {
      hasElevation = true;
      isSignPayItm = true;
    }
    if (payItmType.some((e) => e.payitmClass === "Post")) {
      hasPost = true;
    }
    if (payItmType.some((e) => e.payitmClass === "Approach")) {
      hasApproach = true;
    }
    let newDat = new Date(edata.instDate);
    let instDate1 =  new Date(newDat.getTime() + newDat.getTimezoneOffset() * 60000);
    setinitialValues({
      ...edata,
      payItmHasSupport: hasSupport,
      payItmHasElevation: hasElevation,
      payItemHasPost: hasPost,
      payItmHasApproach: hasApproach,
      payItmIsSign: isSignPayItm,
      payItmHasNpi: HasNpi,
      instDate: instDate1,
    });
    const newValues = {
      ...chrgvalues,
      chrgSuppLeft: edata.chrgSuppLeft,
      chrgSuppRight: edata.chrgSuppRight,
    };
    set_values(newValues);
    console.log(initialValues);    
  };
  const chrgSupp_handler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    const newValues = {
      ...chrgvalues,
      [name]: value,
    };
    set_values(newValues);
    // Calling the method to sum the value
    calc_total(newValues);
  };

  const calc_total = (newValues) => {
    const { chrgSuppLeft, chrgSuppRight } = newValues;
    const newTotal =
      (chrgSuppLeft ? parseFloat(chrgSuppLeft) : 0) +
      (chrgSuppRight ? parseFloat(chrgSuppRight) : 0);
    setinitialValues({
      //...initialValues,
      ...formRef.current.values,
      chrgSuppLeft: chrgSuppLeft,
      chrgSuppRight: chrgSuppRight,
      instQty: newTotal,
    });
  };
  const postDlyprodEdit = (val) => {
    // let submitvals = {
    //   ...values,
    //   stationPi: stationPI,
    //   instBy: "user",
    //   partial: !values.isComplete,
    // };
    console.log(JSON.stringify(val)); //POst Data to DB..
    apiService
      .postContent("dp/app/update", val)
      .then((response) => {
        enqueueSnackbar("Daily Prod Item Updated", { variant: "success" });
        handleClose(true);
      })
      .catch((error) => {
        enqueueSnackbar(
          "There is an error in saving edit daily prod item:" + error,
          { variant: "error" }
        );
        console.log("error: " + error);
      });
    //handleClose(true);
  };
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid className={classes.headcontainer} container>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={Yup.object().shape({
            instQty: Yup.number().required(),
            certSuppLeft: Yup.number()
              .min(12, "Value cant be less than 12")
              .max(18, "Value cant be greater than 18"),
            certSuppRight: Yup.number()
              .min(12, "Value cant be less than 12")
              .max(18, "Value cant be greater than 18"),
          })}
          innerRef={formRef}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            postDlyprodEdit(values);
            //setSubmitting(false);
            resetForm({
              values: {
                payItmHasSupport: false,
                payItmHasElevation: false,
                payItemHasPost: false,
                payItmHasApproach: false,
                payItmHasMeasurements: false,
                payItmHasNpi: false,
                instNpi: "",
                instQty: "",
                instDate: new Date(),
                notes: "",
                grdrlPost: "Steel",
                grdrlPostLen: "",
                number_of_supports: "",
                chrgSuppLeft: "",
                chrgSuppRight: "",
                certSuppLeft: "",
                certSuppRight: "",
                fdnElLeft: "",
                fdnElRight: "",
                approachType: "MSKT",
                isComplete: false,
              },
            });
          }}
        >
          {({
            errors,
            status,
            touched,
            isSubmitting,
            values,
            handleBlur,
            setFieldValue,
            setTouched,
          }) => (
            <Paper className={classes.fullwidthpaper}>
              <Form className={classes.fullwidthform}>
                {/* Content Goes here */}
                <Grid container spacing={0}>
                  <Grid item xs={12} sm={6}>
                    <FkInputFld
                      name="instQty"
                      label="Installed Quantity"
                      controlClass={classes.formControl}
                    />
                  </Grid>

                  {values.payItmHasNpi && (
                    <Grid item xs={12} sm={6}>
                      <FkInputFld
                        name="instNpi"
                        label="Installed NPI Quantity"
                        controlClass={classes.formControl}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6}>
                    <Field
                      name="instDate"
                      component={KeyboardDatePicker}
                      label="Install Date"
                      format="MM/dd/yyyy"
                      size="small"
                      className={classes.formControlD}
                      //lableclass={classes.lvlResize}
                    />
                  </Grid>
                </Grid>
                {values.payItmHasSupport && (
                  <>
                    <Grid container>
                      <Grid item xs={12} sm={6}>
                        <FormLabel
                          component="legend"
                          className={classes.rdglvl}
                          margin={"normal"}
                        >
                          Number of Supports
                        </FormLabel>
                        <Field
                          component={RadioGroup}
                          name="number_of_supports"
                          row
                          className={classes.rdgControl}
                          onChange={(_, value) => {
                            console.log(value);
                            // setFieldValue("showGuardrail", false);
                            // setFieldValue("showSign", false);
                            // if (value.props.children === "Sign") {
                            //   setFieldValue("showSign", true);
                            // } else if (value.props.children === "Guardrail") {
                            //   setFieldValue("showGuardrail", true);
                            // }
                            setFieldValue("number_of_supports", value);
                          }}
                        >
                          <FormControlLabel
                            value="1"
                            control={<Radio disabled={isSubmitting} />}
                            label="1"
                            disabled={isSubmitting}
                          />
                          <FormControlLabel
                            value="2"
                            control={<Radio disabled={isSubmitting} />}
                            label="2"
                            disabled={isSubmitting}
                          />
                        </Field>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12} sm={6}>
                        {/* {values.number_of_supports && ( */}
                        <TextField
                          name="chrgSuppLeft"
                          fullWidth={true}
                          type={"number"}
                          margin={"normal"}
                          size={"medium"}
                          inputProps={inputProps}
                          className={classes.formControlTxt}
                          label="Charge Support Left"
                          value={chrgvalues.chrgSuppLeft}
                          onChange={chrgSupp_handler}
                        />
                        {/* )} */}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        {/* {values.number_of_supports === "2" && ( */}
                        <TextField
                          name="chrgSuppRight"
                          fullWidth={true}
                          type={"number"}
                          margin={"normal"}
                          size={"medium"}
                          inputProps={inputProps}
                          className={classes.formControlTxt}
                          label="Charge Support Right"
                          value={chrgvalues.chrgSuppRight}
                          onChange={chrgSupp_handler}
                        />
                        {/* )} */}
                      </Grid>
                    </Grid>
                    <Grid container spacing={0}>
                      <Grid item xs={12} sm={6}>
                        {/* {values.number_of_supports && ( */}
                        <FkInputFld
                          name="certSuppLeft"
                          label="Certify Support Left"
                          placeholder="Must be between 12 and 18"
                          controlClass={classes.formControl}
                        />
                        {/* )} */}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        {/* {values.number_of_supports === "2" && ( */}
                        <FkInputFld
                          name="certSuppRight"
                          label="Certify Support Right"
                          //helperText="Must be between 12 to 18"
                          placeholder="Must be between 12 and 18"
                          controlClass={classes.formControl}
                        />
                        {/* )} */}
                      </Grid>
                    </Grid>
                  </>
                )}
                {values.payItmHasElevation && (
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                      <FkInputFld
                        name="fdnElLeft"
                        label="Foundation Elevation Left"
                        controlClass={classes.formControl}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FkInputFld
                        name="fdnElRight"
                        label="Foundation Elevation Right"
                        controlClass={classes.formControl}
                      />
                    </Grid>
                  </Grid>
                )}
                {values.payItemHasPost && (
                  <Grid container spacing={1}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <FormLabel
                          component="legend"
                          className={classes.rdglvl}
                        >
                          Guardrail Post
                        </FormLabel>
                        <Field
                          component={RadioGroup}
                          name="grdrlPost"
                          row
                          className={classes.rdgControl}
                        >
                          <FormControlLabel
                            value="Steel"
                            control={<Radio />}
                            label="Steel"
                          />
                          <FormControlLabel
                            value="Wood"
                            control={<Radio />}
                            label="Wood"
                          />
                        </Field>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <FormLabel
                          component="legend"
                          className={classes.rdglvl}
                        >
                          Guardrail Post Length (ft)
                        </FormLabel>

                        <Field
                          component={RadioGroup}
                          name="grdrlPostLen"
                          row
                          className={classes.rdgControl}
                        >
                          <FormControlLabel
                            value="6"
                            control={<Radio />}
                            label="6"
                          />
                          <FormControlLabel
                            value="7"
                            control={<Radio />}
                            label="7"
                          />
                          <FormControlLabel
                            value="8"
                            control={<Radio />}
                            label="8"
                          />
                          <FormControlLabel
                            value="9"
                            control={<Radio />}
                            label="9"
                          />
                        </Field>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {values.payItmHasApproach && (
                  <Grid container spacing={0}>
                    <Grid container spacing={0}>
                      <Grid item xs={12} sm={6}>
                        <FormLabel
                          component="legend"
                          className={classes.rdglvl}
                        >
                          Approach Type
                        </FormLabel>

                        <Field
                          component={RadioGroup}
                          name="approachType"
                          row
                          className={classes.rdgControl}
                        >
                          <FormControlLabel
                            value="MSKT"
                            control={<Radio />}
                            label="MSKT"
                          />
                          <FormControlLabel
                            value="Soft Stop"
                            control={<Radio />}
                            label="Soft Stop"
                          />
                        </Field>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                <Grid container spacing={0}>
                  <Grid item xs={12} sm={6}>
                    <FkInputFld
                      name="notes"
                      label="Notes"
                      controlClass={classes.formControl}
                    />
                  </Grid>
                  {/* <Grid item xs={12} sm={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.isComplete}
                          onChange={(_, value) => {
                            console.log(value);
                            setFieldValue("isComplete", value);
                          }}
                          name="isComplete"
                          color="primary"
                        />
                      }
                      label="Is Station Completed"
                    />
                  </Grid> */}
                </Grid>
                <Grid container className={classes.formFooter}>
                  <Grid item xs={6} md={6}>
                    <Button
                      variant="contained"
                      style={{ background: "red" }}
                      className={classes.button}
                      startIcon={<CancelIcon />}
                      onClick={() => {
                        handleClose(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={6} md={6} style={{ textAlign: "right" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      startIcon={<SaveIcon />}
                      type="submit"
                    >
                      Update
                    </Button>
                  </Grid>
                </Grid>
                {/* {<DisplayFormikState {...values} /> } */}
              </Form>
            </Paper>
          )}
        </Formik>
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

export default EditDailyProd;
