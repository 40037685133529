import {
  IconButton, MenuItem, Select,
  Table, TableBody, TableCell, TableContainer,
  TableFooter, TableHead, TableRow, TextField, Tooltip
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import blue from "@material-ui/core/colors/blue";
import deepOrange from "@material-ui/core/colors/deepOrange";
import Paper from "@material-ui/core/Paper";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import { Category, FirstPage, ListAlt, MonetizationOn } from "@material-ui/icons";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import matchSorter from "match-sorter";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  useFilters, useGlobalFilter, usePagination, useSortBy, useTable
} from "react-table";
import { apiService } from "../../services/api.service";

const useStyles = makeStyles((theme) => ({
  paginationRoot: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  btnPagination: {
    color: "rgba(0, 0, 0, 0.54)",
    "background-color": "transparent",
    border: "none",
    "& : disabled": {
      color: "rgba(0, 0, 0, 0.26)",
    },
  },
  footerPagination: {
    textAlign: "right",
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#4cc8ff",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <TextField
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <Select
      displayEmpty
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <MenuItem value={""}>All</MenuItem>
      {options.map((option, i) => (
        <MenuItem key={i} value={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

function Table2({ columns, data }) {
  const theme = useTheme();
  const classes = useStyles();
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 25,
        //filters: [{ id: 'activef', value: "Yes" }]
      },
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  // Render the UI for your table
  return (
    <>
      <TableContainer component={Paper}>
        <Table {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <StyledTableCell
                    width={column.width}
                    {...column.getHeaderProps()}
                  >
                    <span {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                    <div>
                      {column.canFilter ? column.render("Filter") : null}
                    </div>
                  </StyledTableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <StyledTableRow {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <StyledTableCell {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </StyledTableCell>
                    );
                  })}
                </StyledTableRow>
              );
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <StyledTableCell colSpan="6">
                <div className={classes.footerPagination}>
                  <span>Rows Per page: </span>{" "}
                  <select
                    value={pageSize}
                    onChange={(e) => {
                      setPageSize(Number(e.target.value));
                    }}
                  >
                    {[25, 50, 100, data.length].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        {pageSize}
                      </option>
                    ))}
                  </select>
                  <span>
                    {" "}
                    <strong>
                      {pageIndex + 1} of {pageOptions.length}
                    </strong>
                  </span>
                  <button
                    className={classes.btnPagination}
                    onClick={() => gotoPage(0)}
                    disabled={!canPreviousPage}
                  >
                    {theme.direction === "rtl" ? (
                      <LastPageIcon />
                    ) : (
                      <FirstPage />
                    )}
                  </button>
                  <button
                    className={classes.btnPagination}
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                  >
                    {theme.direction === "rtl" ? (
                      <KeyboardArrowRight />
                    ) : (
                      <KeyboardArrowLeft />
                    )}
                  </button>
                  <button
                    className={classes.btnPagination}
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                  >
                    {theme.direction === "rtl" ? (
                      <KeyboardArrowLeft />
                    ) : (
                      <KeyboardArrowRight />
                    )}
                  </button>
                  <button
                    className={classes.btnPagination}
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                  >
                    {theme.direction === "rtl" ? (
                      <FirstPage />
                    ) : (
                      <LastPageIcon />
                    )}
                  </button>
                </div>
              </StyledTableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      {/* 
        Pagination can be built however you'd like. 
        This is just a very basic UI implementation:
      */}
    </>
  );
}

function JobTable() {
  const [data, setData] = useState([]);
  useEffect(() => {
    apiService.getContent("job/alljobs").then((edata) => setData(edata));
  }, []);
  const history = useHistory();
  const handleEdit = (jobid) => {
    console.log(jobid);
    history.push("/joblist/" + jobid);
  };
  const handleStation = (jobid) => {
    console.log(jobid);
    history.push("/stationlist/" + jobid);
  };
  const handleEstimates = (jobid) => {
    console.log(jobid);
    history.push("/estimate/" + jobid);
  };
  const handlePayItem = (jobid) => {
    console.log(jobid);
    history.push("/jobdetails/" + jobid);
  };
  const columns = React.useMemo(
    () => [
      {
        Header: "",
        width: 20,
        Cell: (row) => {
          return (
            <Tooltip title="Job Pay Items">
              <IconButton
                aria-label="Job Pay Items"
                style={{ padding: 2 }}
                onClick={() => handlePayItem(row.cell.row.original.jobId)}
              >
                <ListAlt style={{ color: blue[500], fontSize: 25 }} />
              </IconButton>
            </Tooltip>
          );
          // return <img height={23} src="images/edit.jfif"
          //   onClick={() => handlePayItem(row.cell.row.original.jobId)} />
        },
        id: "atmid",
      },
      {
        Header: "",
        width: 20,
        Cell: (row) => {
          return (
            <Tooltip title="Job Station Items">
              <IconButton
                aria-label="Job Station Items"
                style={{ padding: 2 }}
                onClick={() => handleStation(row.cell.row.original.jobId)}
              >
                <Category style={{ color: deepOrange[500], fontSize: 20 }} />
              </IconButton>
            </Tooltip>
          );
          // return <img height={23} src="images/site.png"
          //   onClick={() => handleStation(row.cell.row.original.jobId)} />
        },
        id: "atmsite",
      },
      {
        Header: "",
        width: 20,
        Cell: (row) => {
          return (
            <Tooltip title="Job Estimates">
              <IconButton
                aria-label="Job Estimates"
                style={{ padding: 2 }}
                onClick={() => handleEstimates(row.cell.row.original.jobId)}
              >
                <MonetizationOn style={{ color: green[500], fontSize: 20 }} />
              </IconButton>
            </Tooltip>
          );
        },
        id: "estimate",
      },
      {
        Header: "ATM Job",
        accessor: "jobCode",
        filter: "includes",
        width: 100,
        Cell: (row) => {
          return (
            <a
              href="#"
              title="Job Details"
              onClick={() => handleEdit(row.cell.row.original.jobId)}
            >
              {row.cell.row.original.jobCode}
            </a>
          );
        },
      },
      {
        Header: "Active",
        accessor: (d) => {
          return d.active ? "Yes" : "No";
        },
        Filter: SelectColumnFilter,
        id: "activef",
      },
      {
        Header: "Type",
        accessor: "jobType",
        // Filter: SelectColumnFilter,
      },
      {
        Header: "State Job",
        accessor: "stateJobNumber",
        // Use our custom `fuzzyText` filter on this column
        // filter: 'fuzzyText',
      },
      {
        Header: "Location",
        accessor: "jobLocation",
      },
      {
        Header: "Quote",
        accessor: "quoteNumber",
      },
      {
        Header: "Counties",
        accessor: "counties",
      },
      {
        Header: "Contractor",
        accessor: "companyName",
      },
    ],
    []
  );
  return <Table2 columns={columns} data={data} />;
}

export default JobTable;
