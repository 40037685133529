import Dialog from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import React, { useImperativeHandle } from "react";
import DlyProdEdit from "../forms/EditDailyProd";
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);
const DailyProdEditDialog = React.forwardRef((props, ref) => {
  const [open, setOpen] = React.useState(false);

  function handleClose(isRefresh = false) {
    setOpen(false);
    if (isRefresh) {
      console.log("Refresh is called... ");
      props.refreshTable();
    }
  }
  const handleOpen = () => {
    setOpen(true);
  };

  useImperativeHandle(ref, () => ({
    handleOpen,
    handleClose,
  }));
  // useEffect(() => {
  //   console.log(props);
  // }, []);
  return (
    <div>
      <Dialog
        onClose={() => {
          handleClose(false);
        }}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          id="editProd-dialog-title"
          onClose={() => {
            handleClose(false);
          }}
        >
          Edit Daily Prod
        </DialogTitle>
        <DialogContent dividers>
          <DlyProdEdit match={props.props} handleClose={handleClose} />
        </DialogContent>
        {/* <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Save changes
          </Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
});
export default DailyProdEditDialog;
