import { SET_STATION, CLEAR_STATION } from "../actions/type";

const initialState = {};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case SET_STATION:
            return { stationdetails: payload };

        case CLEAR_STATION:
            return {};

        default:
            return state;
    }
}