import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, usePagination, useSortBy } from 'react-table'
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper'
import { apiService } from '../../services/api.service';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  IconButton,
  TextField,
  Select,
  MenuItem,

} from "@material-ui/core";
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import matchSorter from 'match-sorter'
const useStyles = makeStyles((theme) => ({
  paginationRoot: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  btnPagination: {
    color: 'rgba(0, 0, 0, 0.54)',
    'background-color': 'transparent',
    border: 'none',
    "& : disabled": {
      color: 'rgba(0, 0, 0, 0.26)',
    },
  },
  footerPagination: {
    'text-align': 'right',
  }
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#4cc8ff',
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },

}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);


function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <span>
      Search:{' '}
      <input
        value={value || ""}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        style={{
          fontSize: '1.1rem',
          border: '0',
        }}
      />
    </span>
  )
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length

  return (
    <TextField
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  )
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach(row => {
      options.add(row.values[id]);
    });
    return ["All", ...options.values()]; // Include "All" in the options
  }, [id, preFilteredRows]);

  return (
    <Select
      displayEmpty
      value={filterValue || "All"}
      onChange={e => {
        setFilter(e.target.value === "All" ? undefined : e.target.value);
      }}
    >
      {options.map((option, i) => (
        <MenuItem key={i} value={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  );
}

function statusFilterFn(rows, id, filterValue) {
  if (filterValue === "All" || filterValue === undefined) {
    return rows;
  }
  return rows.filter(row => {
    const rowValue = row.values[id];
    return rowValue === filterValue;
  });
}
function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val

function BpTable() {
  const [data, setData] = useState([])
  useEffect(() => {
    apiService.getContent("bp/")
      .then(edata => {
        const newarr = edata.map(x => x.scId === 1 ? { ...x, scId: "OE 324" } : { ...x, scId: "" });
        setData(newarr);
      });
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "Type",
        accessor: "bpType",
        Filter: SelectColumnFilter,
        filter: 'includes',
      },
      {
        Header: "Company",
        accessor: "companyName"
      },
      {
        Header: "Last Name",
        accessor: "lastName",
      },
      {
        Header: "First Name",
        accessor: "firstName"
      },
      {
        Header: "Phone",
        accessor: "phone"
      },
      {
        Header: "Email",
        accessor: "email"
      },
      {
        Header: "Status",
        accessor: "statusDesc",
        Filter: SelectColumnFilter,
        filter: statusFilterFn, // Use the custom filter function
      },
      {
        Header: "Special Conditions",
        accessor: "scId",
      }
    ], []);

  return (
    <Table2 columns={columns} data={data} />
  );
}

function Table2({ columns, data }) {
  const theme = useTheme();
  const classes = useStyles();
  const filterTypes = React.useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );
  const history = useHistory();
  const handleClick = (row) => {
    history.push("/bpitem/" + row.original.bpId);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 25,
        filters: [
          { id: "statusDesc", value: "Active" } // Set default filter value for "Status" column
        ]
      },
      defaultColumn,
      filterTypes,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      <TableContainer component={Paper}>
        <Table {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <StyledTableCell {...column.getHeaderProps()}>
                    <span {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>

                    <div>
                      {column.canFilter ? column.render("Filter") : null}
                    </div>
                  </StyledTableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <StyledTableRow
                  {...row.getRowProps({ onClick: () => handleClick(row) })}
                >
                  {row.cells.map((cell) => (
                    <StyledTableCell {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              );
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <StyledTableCell colSpan="7">
                <div className={classes.footerPagination}>
                  <span>Rows Per page: </span>{" "}
                  <select
                    value={pageSize}
                    onChange={(e) => {
                      setPageSize(Number(e.target.value));
                    }}
                  >
                    {[25, 50, 100, (data.length)].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        {pageSize}
                      </option>
                    ))}
                  </select>
                  <span>
                    {" "}
                    <strong>
                      {pageIndex + 1} of {pageOptions.length}
                    </strong>
                  </span>
                  <button
                    className={classes.btnPagination}
                    onClick={() => gotoPage(0)}
                    disabled={!canPreviousPage}
                  >
                    {theme.direction === "rtl" ? (
                      <LastPageIcon />
                    ) : (
                        <FirstPageIcon />
                      )}
                  </button>
                  <button
                    className={classes.btnPagination}
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                  >
                    {theme.direction === "rtl" ? (
                      <KeyboardArrowRight />
                    ) : (
                        <KeyboardArrowLeft />
                      )}
                  </button>
                  <button
                    className={classes.btnPagination}
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                  >
                    {theme.direction === "rtl" ? (
                      <KeyboardArrowLeft />
                    ) : (
                        <KeyboardArrowRight />
                      )}
                  </button>
                  <button
                    className={classes.btnPagination}
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                  >
                    {theme.direction === "rtl" ? (
                      <FirstPageIcon />
                    ) : (
                        <LastPageIcon />
                      )}
                  </button>
                </div>
              </StyledTableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
}


export default BpTable