import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { SnackbarProvider } from "notistack";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { logout } from "./actions/auth";
import { clearMessage } from "./actions/message";
import "./App.css";
import AddJobComp from "./components/content/AddJobComp";
import BoardsComp from "./components/content/BoardsComp";
import CpReportComp from "./components/content/CpReportComp";
import ApprovalComp from "./components/content/DailyProdApprovalComp";
import EstComp from "./components/content/EstimateComp";
import NewLineItem from "./components/content/JobLineItmComp";
import MainComp from "./components/content/MainComp";
import MyDlyProdComp from "./components/content/MyDailyProdComp";
import OpReportComp from "./components/content/OpReportComp";
import AddPayItem from "./components/content/PayItemComp";
import ResComp from "./components/content/ResourcesComp";
import SubLineItem from "./components/content/SubLineItmComp";
import FMJobDetails from "./components/foreman/FManJobDetails";
import NewBusPat from "./components/forms/BpForm";
import EmpRpt from "./components/reports/EmpRpt";
import HotelComp from "./components/content/HotelListComp";
import {
  default as EmplAtm,
  default as EmplComp
} from "./components/tableitm/BpComp";
import BpList from "./components/tablelist/BpList";
import FMJobList from "./components/tablelist/FMJobList";
import JobList from "./components/tablelist/JobList";
import PayItemList from "./components/tablelist/PayItemList";
import ScrollToTop from "./helper/ScrollToTop";
import Layout from "./Layout";
import ForgotPass from "./logincomps/ForgotPassword";
import GuardedRoute from "./logincomps/GaurdedRoute";
import Login from "./logincomps/Login";
import ManageUser from "./logincomps/ManageUsers";
import Profile from "./logincomps/Profile";
import Register from "./logincomps/Register";
import UserDetails from "./logincomps/UserDetails";
import { history } from "./utils/history";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#00C853",
    },
    secondary: {
      main: "#00b0ff",
      light: "#00b0ff",
    },
    background: {
      default: "#fff",
    },
  },
  typography: {
    fontFamily: [
      "Segoe UI",
      "Roboto",
      '"Oxygen"',
      "Ubuntu",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});
//console.log(theme);
function App() {
  const [showAdmin, setShowAdmin] = useState(false);
  const [showForeman, setShowForeman] = useState(false);
  const [showManager, setShowManager] = useState(false);
  const [showCorporate, setShowCorporate] = useState(false);
  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    history.listen((location) => {
      dispatch(clearMessage()); // clear message when changing location
    });
  }, [dispatch]);

  useEffect(() => {
    if (currentUser) {
      setShowForeman(currentUser.roles.includes("ROLE_FOREMAN"));
      setShowAdmin(currentUser.roles.includes("ROLE_ADMIN"));
      setShowManager(currentUser.roles.includes("ROLE_MANAGER"));
      setShowCorporate(currentUser.roles.includes("ROLE_CORPORATE"));
    }
  }, [currentUser]);

  const logOut = () => {
    dispatch(logout());
  };
  var stateUser = {
    currentUser: currentUser,
    showAdmin: showAdmin,
    showForeman: showForeman,
    showManager: showManager,
    showCorporate:showCorporate,
  };
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <Router history={history}>
          <Switch>
            <Layout stateprops={stateUser}>
              <ScrollToTop />
              <Route path="/" exact component={ResComp} />
              <Route path="/main" component={MainComp} />
              <Route path="/employee" exact component={EmplComp} />
              <GuardedRoute path='/joblist' component={JobList} auth={showAdmin || showManager || showCorporate} exact/>
              <GuardedRoute path='/joblist/:id' component={AddJobComp} auth={showAdmin || showManager || showCorporate}/>                
              <GuardedRoute path="/jobdetails/:id" component={NewLineItem} auth={true}/>  {/*//TODO Chk with Gopi if it's the correct approach */}
              <Route path="/addjob" exact component={AddJobComp} />              
              <GuardedRoute path="/CreateBusPat" component={NewBusPat} auth={showAdmin}/>
              <GuardedRoute path="/JobPayItem" component={NewLineItem} auth={showAdmin}/>
              <GuardedRoute path="/SubLineItem" component={SubLineItem} auth={showAdmin}/>              
              <GuardedRoute path="/stationlist/:id" component={SubLineItem} auth={true}/>  {/*//TODO Chk with Gopi if it's the correct approach */}
              <GuardedRoute path="/AddPayItem/:id" component={AddPayItem} auth={showAdmin} />
              <Route path="/PayItemList" exact component={PayItemList} />
              <Route path="/EditPayItem/:id" component={AddPayItem} />              
              <GuardedRoute path='/atmbp' component={BpList} auth={showAdmin || showManager || showCorporate} exact/>
              <GuardedRoute path='/bpitem/:id' component={EmplAtm} auth={showAdmin|| showManager || showCorporate}/>
              <Route path="/foreman" component={FMJobList} />
              <Route path="/FMJobDetails/:id" component={FMJobDetails} />              
              <GuardedRoute path="/ProdApproval" component={ApprovalComp} auth={showAdmin}/>
              <GuardedRoute path="/MyWorkList" component={MyDlyProdComp} auth={showForeman}/>
              <Route path="/OpReports" component={OpReportComp} />
              <GuardedRoute path='/CpReports' component={CpReportComp} auth={showAdmin || showManager || showCorporate}/>
              <GuardedRoute path='/Boards' component={BoardsComp} auth={showAdmin || showManager}/>
              <Route path='/Hotels' component={HotelComp} />
              <Route path="/Estimate/:id" component={EstComp} />
              <Route path="/Resources" component={ResComp} />
              <Route path="/EmpTable" component={EmpRpt} />
              {/* Testing Login Component */}
              <Route path="/login" exact component={Login} />
              <Route path="/register" exact component={Register} />
              <Route path="/profile" exact component={Profile} />
              <Route path="/forgotPass" exact component={ForgotPass} />
              <Route path="/userdetails" exact component={UserDetails} />
              <GuardedRoute path='/manageuser' exact component={ManageUser} auth={showAdmin ||showManager}/>
            </Layout>
          </Switch>
        </Router>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
