import { FormControl, Grid, TextField } from "@material-ui/core";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import { apiService } from "../../services/api.service";
import ReportFooter from "./ReportFooter";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    margin: "0.75rem 0.75rem",
    //'box-shadowhadow': 'none',
  },
  formControl: {
    minWidth: 120,
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
  },
  pos: {
    marginBottom: 12,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
});

export default function SteelPostByJob() {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [selectedjob, setSelectedJob] = useState(null);
  useEffect(() => {
    apiService.getContent("job/joblist").then((edata) => setData(edata));
  }, []);

  const runReport = (event, rptType) => {
    if (selectedjob) {
      console.log(selectedjob + " ---Job");
      //Submit form rptype...xlsx pdf
      var qrystr = "postsbyjob&format=" + rptType + "&job=" + selectedjob;
      var fileName = selectedjob + "_steelposts." + rptType;
      apiService.getReport(qrystr, fileName);
    } else {
      console.log("Please select a ---Job");
    }
  };
  const alljobs = data.map((x) => x);
  return (
    <>
      <CardContent>
        <Typography className={classes.title} gutterBottom>
          Steel Posts per Job
        </Typography>
        <Typography variant="h5" component="h2"></Typography>
        <Typography className={classes.pos} color="textSecondary"></Typography>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormControl
              className={classes.formControl}
              fullWidth={true}
              margin={"normal"}
              size={"medium"}
            >
              <Autocomplete
                id="job"
                // blurOnSelect
                options={alljobs}
                onChange={(_, value) => {
                  setSelectedJob(value);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Select a Job" />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <ReportFooter sumbit={runReport} />
      </CardActions>
      {/* </Card> */}
    </>
  );
}
