import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import MaterialTable from "material-table";
import { apiService } from '../../services/api.service';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';

function FMJobTable() {
  const [data, setData] = useState([]);
  const history = useHistory();
  useEffect(() => {
    apiService.getContent("job/active")
      .then((edata) => setData(edata));
  }, []);

  return (
    <>
      {/* <pre>{console.log(data)}</pre> */}
      <MaterialTable
        title="All Active Jobs"
        columns={[
          { title: "ATM Job", field: "jobCode" },
          { title: "Type", field: "jobType" },
          { title: "State Job", field: "stateJobNumber" },
          { title: "Job Location", field: "jobLocation", width: 200, },
          { title: "Location Type", field: "jobLocType" },
          { title: "Counties", field: "counties" },
        ]}
        data={data}
        actions={[
            {
              icon:() => <DynamicFeedIcon  style={{ fontSize: 30,color: "#ff5722" }}/>, //,color: "#fb8c00" 
              tooltip: 'Goto Station',
              onClick: (event, rowData) => history.push("/FMJobDetails/"+rowData.jobId)
              // 
            }
          ]}
        options={{
          filtering: false,
          paging:false,
          // pageSize:10,
          // pageSizeOptions:[5,10,25,50],
          padding:"dense",
          showTitle:true,
          //tableLayout:"fixed"
        }}
        localization={{
            header: {
              actions: "",
            },
          }}
      />
    </>
  );
}

export default FMJobTable;
