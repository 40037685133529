import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, useField, Form } from 'formik';
import { TextField, Button, Grid, Paper } from "@material-ui/core";
import * as Yup from 'yup';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import { makeStyles } from '@material-ui/core/styles';
import FkInputFld from "./formik-custom-fields/FkInputFld"
import FkSelectFld from "./formik-custom-fields/FkSelectFld"
import { apiService } from '../../services/api.service';
import { useSnackbar } from "notistack";
import { useSelector } from 'react-redux';
const useStyles = makeStyles((theme) => ({
  formControl: {
    padding: '0px 25px',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  headcontainer: {
    margin: '15px 0%',
  },
  button: {
    color: 'white',
    fontWeight: 500,
    fontSize: 14,
    letterSpacing: 0.8,
    borderRadius: 20,

  },
  bussinessform: {
    width: '100%',
  },
  fullwidthpaper: {
    width: '100%',
  },
  formFooter: {
    padding: '15px 25px',
    background: '#efefef',
    'margin-top': '25px',
  },
  lvlResize: {
    'left': "25px"
  },
}));

const MyTextField = ({
  label,
  ...props
}) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  return (
    <TextField
      label={label}
      {...field}
      helperText={errorText}
      error={!!errorText}
    />
  );
};

const relations = [
  {
    label: "Child",
    value: "Child"
  },
  {
    label: "Family",
    value: "Family"
  },
  {
    label: "Friends",
    value: "Friends"
  },
  {
    label: "Other",
    value: "Other"
  },
  {
    label: "Parent",
    value: "Parent"
  },
  {
    label: "Relative",
    value: "Relative"
  },
  {
    label: "Sibling",
    value: "Sibling"
  },
  {
    label: "Spouse",
    value: "Spouse"
  },
];
const roles = [
  {
    label: "Office",
    value: "Office"
  },
  {
    label: "Admin",
    value: "Admin"
  },
  {
    label: "Superintendent",
    value: "Superintendent"
  },
  {
    label: "Corporate",
    value: "Corporate"
  }
];

const NewBusinessPartner = ({ match }) => {

  const { enqueueSnackbar } = useSnackbar();
  const [bpTypes, setBpTypes] = useState([])
  const [spTypes, setSpTypes] = useState([])
  const [stTypes, setStTypes] = useState([])
  const [employee, setEmployee] = useState({})
  const [newbp, setNewbp] = useState({})
  const [bp, setBp] = useState({})
  const [showAdmin, setShowAdmin] = useState(false);
  const [showCorporate, setShowCorporate] = useState(false);
  const { user: currentUser } = useSelector((state) => state.auth);
  const clearBp = () => {
    setEmployee({
      "bp_id": "", "companyName": "", "prefix": "", "lastName": "", "middleName": "",
      "firstName": "", "email": "", "phone": "", "status": "1", "scId": "0", "typeId": "1",
      "contact1Name": "", "relation1": "", "cell1Phone": "", "home1Phone": "",
      "email1": "", "contact2Name": "", "relation2": "", "cell2Phone": "",
      "home2Phone": "", "email2": ""
    })
    setNewbp(true)
    setBp(0)
  }
  if (match.params.id !== bp && match.params.id === '0' && !newbp) {
    clearBp()
  }
  useEffect(() => {
    apiService.getContent("bp/options")
      .then((edata) => {
        setBpTypes(edata.partnerTypes);
        setSpTypes(edata.specialConditions);
        setStTypes(edata.status);
      })
    if (typeof match !== 'undefined' && match.params.id !== '0') {
      let reqestp = match.params.id;
      apiService.getContent("bp/" + reqestp)
        .then(edata => setEmployee(edata))
      setNewbp(false)
      setBp(match.params.id);
    } else {
      clearBp()
    }
    setShowAdmin(currentUser.roles.includes("ROLE_ADMIN"));
    setShowCorporate(currentUser.roles.includes("ROLE_CORPORATE"))
  }, [])

  const bpType = bpTypes.map((ptype) =>
  ({
    "value": ptype.typeId,
    "label": ptype.description
  }));
  const spType = spTypes.map((ptype) =>
  ({
    "value": ptype.cid,
    "label": ptype.conditionDesc
  }));
  const stType = stTypes.map((ptype) =>
  ({
    "value": ptype.statusId,
    "label": ptype.statusDesc
  }));
  const classes = useStyles();
  //const theme = useTheme();
  const history = useHistory();
  return (
    <Grid className={classes.headcontainer} container spacing={6}>
      <Formik
        enableReinitialize
        initialValues={{
          typeId: employee.typeId,
          companyName: employee.companyName,
          role: "",
          status: employee.status,
          scId: employee.scId,
          firstName: employee.firstName,
          lastName: employee.lastName,
          phone: employee.phone,
          email: employee.email,
          contact1Name: employee.contact1Name,
          relation1: employee.relation1,
          cell1Phone: employee.cell1Phone,
          home1Phone: employee.home1Phone,
          contact2Name: employee.contact2Name,
          relation2: employee.relation2,
          cell2Phone: employee.cell2Phone,
          home2Phone: employee.home2Phone,
        }}
        validationSchema={Yup.object().shape({
          companyName: Yup.string().required("Company Name is required"),
          //firstName: Yup.string().required("First Name is required"),
          //lastName: Yup.string().required("Last Name is required"),
          email: Yup.string()
            .email("Email is invalid")
            .nullable()
          //  .required("Email is required"),
        })}
        onSubmit={(values, { setSubmitting }) => {
          var uemployee = { ...values, bpId: employee.bpId, createdOn: employee.createdOn };
          apiService.postContent("bp/", uemployee)
      .then((saved) => {
        enqueueSnackbar("Business Partner Created/Updated successfully!", { variant: 'success' });
        setSubmitting(false);
        history.push("/atmbp"); // Redirect after the POST request is successful
      })
      .catch((error) => {
        enqueueSnackbar("Error Creating/Updating Business Partner:" + error, { variant: 'error', });
        setSubmitting(false);
      });
        }}
      >
        {({ errors, status, touched,isSubmitting }) => (
          <Paper className={classes.fullwidthpaper}>
            <Form className={classes.bussinessform}>
              <Grid container spacing={0}>
                <Grid item xs={12} md={6}>
                  <FkSelectFld
                    name="typeId"
                    items={bpType}
                    label="Business Partner Type"
                    controlClass={classes.formControl}
                    lableClass={classes.lvlResize}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FkInputFld
                    label="Company Name"
                    name="companyName"
                    controlClass={classes.formControl}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={0}>
                <Grid item xs={12} md={3}>
                  <FkSelectFld
                    name="scId"
                    items={spType}
                    label="Special Conditions"
                    controlClass={classes.formControl}
                    lableClass={classes.lvlResize}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FkSelectFld
                    name="status"
                    items={stType}
                    label="Status"
                    controlClass={classes.formControl}
                    lableClass={classes.lvlResize}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FkSelectFld
                    name="role"
                    items={roles}
                    label="Company Role"
                    controlClass={classes.formControl}
                    lableClass={classes.lvlResize}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={0}>
                <Grid item xs={12} md={6}>
                  <FkInputFld
                    label="Last Name"
                    name="lastName"
                    controlClass={classes.formControl}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FkInputFld
                    label="First Name"
                    name="firstName"
                    controlClass={classes.formControl}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={0}>
                <Grid item xs={12} md={6}>
                  <FkInputFld
                    label="Phone"
                    name="phone"
                    controlClass={classes.formControl}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FkInputFld
                    label="Email"
                    name="email"
                    controlClass={classes.formControl}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={0}>
                <Grid item xs={12} md={6}>
                  <FkInputFld
                    label="Primary Contact Name"
                    name="contact1Name"
                    controlClass={classes.formControl}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FkSelectFld
                    name="relation1"
                    items={relations}
                    label="Relationship"
                    controlClass={classes.formControl}
                    lableClass={classes.lvlResize}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={0}>
                <Grid item xs={12} md={6}>
                  <FkInputFld
                    label="Cell Phone"
                    name="cell1Phone"
                    controlClass={classes.formControl}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FkInputFld
                    label="Home Phone"
                    name="home1Phone"
                    controlClass={classes.formControl}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={0}>
                <Grid item xs={12} md={6}>
                  <FkInputFld
                    label="Secondary Contact Name"
                    name="contact2Name"
                    controlClass={classes.formControl}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FkSelectFld
                    name="relation2"
                    items={relations}
                    label="Relationship"
                    controlClass={classes.formControl}
                    lableClass={classes.lvlResize}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={0}>
                <Grid item xs={12} md={6}>
                  <FkInputFld
                    label="Cell Phone"
                    name="cell2Phone"
                    controlClass={classes.formControl}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FkInputFld
                    label="Home Phone"
                    name="home2Phone"
                    controlClass={classes.formControl}
                  />
                </Grid>
              </Grid>
              {(showAdmin || showCorporate) && (
              <Grid container className={classes.formFooter}>
                <Grid item xs={6} md={6}>
                  <Button
                    variant="contained"
                    style={{ background: "#ff5722" }}
                    className={classes.button}
                    startIcon={<CancelIcon />}
                    onClick={() => history.goBack()}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={6} md={6} style={{ textAlign: "right" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    disabled={isSubmitting}
                    startIcon={<SaveIcon />}
                    type="submit"
                  >
                    Save
                  </Button>
                </Grid>
              </Grid> )}
            </Form>
          </Paper>
        )}
      </Formik>
    </Grid>
  );
};
export default NewBusinessPartner