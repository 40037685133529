import React from "react"
import JobPayItemForm from "../forms/JobPayItmForm"
import { Grid } from "@material-ui/core"

function JobLineItmComp({ match }) {
  return (
    <Grid container direction="row">
      <JobPayItemForm match={match} />
    </Grid>
  )
}
export default JobLineItmComp