
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { useSnackbar } from 'notistack';
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmail } from "validator";
import { register } from "../actions/auth";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const validEmail = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const vusername = (value) => {
  if (value.length < 3 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        The username must be between 3 and 20 characters.
      </div>
    );
  }
};

const vpassword = (value) => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        The password must be between 6 and 40 characters.
      </div>
    );
  }
};
const Register = () => {
  const form = useRef();
  const checkBtn = useRef();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [successful, setSuccessful] = useState(false);
 
  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };
  
  const handleRegister = (e) => {
    e.preventDefault();
  
    setSuccessful(false);
    if (username.length < 3 || username.length > 20) {
      setUsernameError("The username must be between 3 and 20 characters.");
      return false;
    } else setUsernameError("");
    if (!isEmail(email)) {
      setEmailError("This is not a valid email.");
      return false;
    } else setEmailError("");
    if (password.length < 6 || password.length > 40) {
      setPasswordError("The password must be between 6 and 40 characters");
      return false;
    } else setPasswordError("");

    dispatch(register(username, email, password))
      .then((suc) => {
        setSuccessful(true);
        enqueueSnackbar("User created successfully!", {
          variant: "success",
        });
      })
      .catch((error) => {
        enqueueSnackbar("Error creating user: " + error, {variant: "error" });
        setSuccessful(false);
      });

  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <form className={classes.form} onSubmit={handleRegister} ref={form}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoComplete="Uname"
                name="username"
                value={username}
                onChange={onChangeUsername}
                variant="outlined"
                required
                fullWidth
                id="username"
                label="User Name"
                autoFocus
                error={usernameError.length > 1}
                helperText={usernameError}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                value={email}
                onChange={onChangeEmail}
                autoComplete="email"
                error={emailError.length > 1}
                helperText={emailError}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                value={password}
                onChange={onChangePassword}
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                error={passwordError.length > 1}
                helperText={passwordError}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign Up
          </Button>
         
          {/* {!successful && (
            <div className="form-group">
              <div className="alert alert-danger" role="alert">
                Account Created Failed!!!
              </div>
            </div>
          )} */}
          <Grid container justify="flex-end">
            <Grid item>
              <Link href="/login" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};
export default Register;
