import DateFnsUtils from "@date-io/date-fns";
import { Checkbox, FormControl, Grid, TextField } from "@material-ui/core";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import React, { useEffect, useState } from "react";
import { apiService } from "../../services/api.service";
import ReportFooter from "./ReportFooter";
const useStyles = makeStyles({
  root: {
    minWidth: 275,
    margin: "0.75rem 0.75rem",
    //'box-shadowhadow': 'none',
  },
  formControl: {
    minWidth: 160,
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
  },
  pos: {
    marginBottom: 12,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
});

export default function PayItmByYear() {
  const classes = useStyles();
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const [payItems, setPayitems] = useState([]);
  const [selectedpayItems, setSelectedPayitems] = useState([]);
  const [selectedYear, handleYearChange] = useState(null);
  useEffect(() => {
    apiService.getContent("jpi/used").then((edata) => {
      setPayitems(edata);
    });
  }, []);
  const runReport = (event, rptType) => {
    if (selectedpayItems && selectedYear) {
      let res = selectedpayItems.map(x => "'" + x.payItmCode + "'").toString();
      console.log(res + " ---payItmCode");
      console.log(selectedYear.getFullYear() + " ---Year");
      //Submit form rptype...xlsx pdf
      var qrystr =
        "jobpayitembyyear&format=" +
        rptType +
        "&payitem=" +
         res +
        "&year=" +
        selectedYear.getFullYear();
      var fileName =
        "Selected payItems" + "." + selectedYear.getFullYear() + "." + rptType;
      apiService.getReport(qrystr, fileName);
    } else {
      console.log("Please select a ---Job and year");
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <CardContent>
        <Typography className={classes.title} gutterBottom>
          Pay Item Production by Year
        </Typography>
        <Typography variant="h5" component="h2"></Typography>
        <Typography className={classes.pos} color="textSecondary">
          Pay item completed by year
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormControl
              className={classes.formControl}
              fullWidth={true}
              margin={"normal"}
              size={"medium"}
            >
              <Autocomplete
                multiple
                id="checkboxes-tags"
                options={payItems}
                getOptionLabel={(option) => option.payItmCode}
                limitTags={5}
                // value={myTempVal}
                disableCloseOnSelect
                onChange={(event, newValue) => {
                  setSelectedPayitems(newValue);
                }}
                //getOptionLabel={(option) => option}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.payItmCode} - {option.payItmDesc}
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Payitem"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                  />
                )}
              />
              <DatePicker
                views={["year"]}
                label="Year"
                value={selectedYear}
                margin={"normal"}
                onChange={handleYearChange}
              />
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <ReportFooter sumbit={runReport} />
      </CardActions>
    </MuiPickersUtilsProvider>
  );
}
