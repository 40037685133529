import { Card, Grid } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import { green } from "@material-ui/core/colors";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import PostAddIcon from "@material-ui/icons/PostAdd";
import ViewListIcon from "@material-ui/icons/ViewList";
import React, { useState } from "react";
import ActiveJobRpt from "./ActJobComp";
import ActJobVsCmpJobDetails from "./ActJobVsCmpJobDetails";
import ActJobVsCmpJobSummary from "./ActJobVsCmpJobSummary";
import BrgClearSign from "./BridgeClearanceSigns";
import BrgStruConnStatus from "./BridgeStructConnStatus";
import CardinalJob from "./CardinalJobs";
import CmptlQtyRpt from "./CmptlQtyComp";
import ComparisionRpt from "./ContractComp";
import DBEstnonpay from "./DBEstNonPayment";
import ProdexcContract from "./DlyProdExceedContract";
import EmpRpt from "./EmpRptComp";
import EstNoTransDt from "./EstNoTransDtReport";
import EstSummary from "./EstSummaryRpt";
import EstimateTotal from "./EstTotalRptCpmp";
import ExtNotes from "./ExternalNotes";
import ExternalJob from "./ExtJobComp";
import FieldRpt from "./FieldRptComp";
import GuardrailCerts from "./GuardrailCertsComp";
import InterJobComplete from "./InternalJobComp";
import JobBrkElv from "./JobBreakElevationsComp";
import JobDetails from "./JobDetailsComp";
import PayItmByCont from "./JobPayItmByCont";
import JobProdByCategory from "./JobProdByCategory";
import MdotEst from "./MDOTEstRpt.";
import MDOTOverdue from "./MDOTOverdue";
import PayitemCompl from "./PayItmAllJob";
import PayItmByYear from "./PayItmByYear";
import PlanIss from "./PlanIssues";
import PostCerts from "./PostCertsComp";
import CountyMaintenance from "./ProdCounMaintenance";
import QtyByStation from "./QtyByStation";
import StaQtyComparison from "./StationQtyComparison";
import StPostByJob from "./SteelPostByJob";
import TotalByPeriodRpt from "./TtlByPrdComp";
import WoodPosts from "./WoodPosts";
import WoodSlipFdn from "./WoodSlipFdn";
import JobProdCatSum from "./JobProdCatSummary";
import JobProdByPrequal from "./JobProdByPrequal";
import JobProdByPrequalSummary from "./JobProdByPrequalSummary";
import JobProdSummary from "./JobProdSummary";
import QuoteToActual from "./QuoteToActual";
import QuoteToActualCompByDivision from "./QuoteToActualByDivision";
import QuoteToActualCompAllDivision from "./QuoteToActualAllDivision";
import JobProdByCategoryInstDate from "./JobProdByCategotyInstDate";
import JobProdByCategoryInstDateDetails from "./JobProdByCategoryInstDateDetails";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // width: "100%",
    // maxWidth: 360,
    // backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  demo: {
    width: "100%",
    flexGrow: 1,
    //maxWidth: 450,
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    maxHeight: 500,
    padding: theme.spacing(2),
  },
  card: {
    display: "block",
    width: "30vw",
    transitionDuration: "0.3s",
    height: "45vw",
  },
}));

export default function NewCorporateRpt() {
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [showRpt, setShowRpt] = useState(false);
  const [reportComp, setReportComp] = useState(null);
  const [openGrp1, setOpenGrp1] = React.useState(false);
  const [openGrp2, setOpenGrp2] = React.useState(false);
  const [openGrp3, setOpenGrp3] = React.useState(false);
  const [openGrp4, setOpenGrp4] = React.useState(false);
  const [openGrp5, setOpenGrp5] = React.useState(false);
  const [openGrp6, setOpenGrp6] = React.useState(false);
  const reports = [
    {
      key: 1,
      groupName: "Emp",
      header: "Employee",
      description: "Report displays all employee contact information",
    },
    {
      key: 2,
      groupName: "Job Info",
      header: "Active Jobs",
      description: "Report display all active jobs information",
    },
    {
      key: 3,
      groupName: "Job Info",
      header: "Job Pay Item Details",
      description: "All Pay items quantities and value by Job",
    },
    {
      key: 4,
      groupName: "Take-off",
      header: "Pay Items to Complete Jobs",
      description: "Pay item to complete all active Jobs",
    },
    {
      key: 5,
      groupName: "Production",
      header: "Complete Quantity Comparison",
      description: "",
    },
    {
      key: 6,
      groupName: "Production",
      header: "Internal Daily Production",
      description: "Job Daily Production - quantities and value by Job",
    },
    {
      key: 7,
      groupName: "Production",
      header: "External Daily Production",
      description: "Daily Production - quantities by Job",
    },
    {
      key: 8,
      groupName: "Production",
      header: "Quantities by Station",
      description:
        "All Approved Quantities by Station, Payitem and Installed Date",
    },
    {
      key: 9,
      groupName: "Estimates",
      header: "ATM-MDOT-Prime Contract Comparison",
      description: "Contract - Earned Amount Comparison",
    },
    {
      key: 10,
      groupName: "Production",
      header: "Total $ by Period",
      description: "",
    },
    {
      key: 11,
      groupName: "Production",
      header: "Field Report",
      description: "Complete Quantity & Value Comparison by Job & Payitem Type",
    },
    {
      key: 12,
      groupName: "Estimates",
      header: "Estimate Total by Job",
      description: "Invoice by Estimate and Job",
    },
    {
      key: 13,
      groupName: "Estimates",
      header: "Estimate Summary by Job",
      description: "Shows total By Estimates and Job",
    },
    {
      key: 14,
      groupName: "Estimates",
      header: "Estimates with No Transfer Date",
      description: "Shows all Estimates with no transfer date.",
    },
    {
      key: 15,
      groupName: "Estimates",
      header: "Unpaid/Non-Transferred MDOT Estimates",
      description:
        "Shows all MDOT estimates with transfer date and no prime qty",
    },
    {
      key: 16,
      groupName: "Take-off",
      header: "Job Breakaway Elevations",
      description:
        "Shows all elevations for W8 x 13 and W8 x 18 breakaways by Job",
    },
    {
      key: 17,
      groupName: "Production",
      header: "3# Post Cert Quantities",
      description:
        "Shows all cert count for 3# post by daily production date range",
    },
    {
      key: 18,
      groupName: "Production",
      header: "Guardrail Cert Info",
      description: "Shows all cert info for guardrail by day and job",
    },
    {
      key: 19,
      groupName: "Production",
      header: "Production Detail for County Maintenance",
      description: "Production Detail by Job and Station",
    },
    {
      key: 20,
      groupName: "Take-off",
      header: "Plan Quantity vs Contract Quantity",
      description:
        "Shows all payitems in Job, compares the quantity at payitem vs station",
    },
    {
      key: 21,
      groupName: "Estimates",
      header: "Production Exceeds Contract Quantity",
      description:
        "Shows all active jobs, where sum of installed quantity is more than contract quantity.",
    },
    {
      key: 22,
      groupName: "Estimates",
      header: "DB Jobs Estimate Non-Payment",
      description: "Shows all Direct Bill Jobs that do not have an estimate",
    },
    {
      key: 23,
      groupName: "Take-off",
      header: "Bridge Connection/Structure Status",
      description:
        "Shows all payitems with classification measurement for all active jobs",
    },
    {
      key: 24,
      groupName: "Take-off",
      header: "Bridge Clearance Sign Form",
      description:
        "Template by job to enter approach, center, exit for Sign Code W12-3",
    },
    {
      key: 25,
      groupName: "Job Info",
      header: "Cardinal Job Numbers",
      description: "All Active Jobs for Cardinal",
    },
    {
      key: 26,
      groupName: "Job Info",
      header: "Job Pay Items by Contractor",
      description: "",
    },
    {
      key: 27,
      groupName: "Production",
      header: "Pay Item Production by Year",
      description: "",
    },
    {
      key: 28,
      groupName: "Take-off",
      header: "Steel Posts per Job",
      description: "",
    },
    {
      key: 29,
      groupName: "Take-off",
      header: "Plan Issues",
      description: "",
    },
    {
      key: 30,
      groupName: "Production",
      header: "External Notes",
      description: "",
    },
    {
      key: 31,
      groupName: "Take-off",
      header: "Wood Posts by Job",
      description: "",
    },
    {
      key: 32,
      groupName: "Estimates",
      header: "MDOT Quantities Overdue",
      description: "",
    },
    {
      key: 33,
      groupName: "Production",
      header: "Wood-Slip Base Foundations",
      description: "This shows all NPI Items by Job",
    },
    {
      key: 34,
      groupName: "Pre-Qual",
      header: "All Completed Jobs - Detail",
      description:
        "Shows contract value and completed value for all jobs by pay item and pre-qual",
    },
    {
      key: 35,
      groupName: "Pre-Qual",
      header: "All Completed Jobs - Summary",
      description:
        "Shows contract value and completed value for all jobs by job type",
    },
    {
      key: 36,
      groupName: "Pre-Qual",
      header: "Job Production By Category - Detail",
      description: "",
    },
    {
      key: 37,
      groupName: "Pre-Qual",
      header: "Job Production By Category - Summary",
      description: "",
    },
    {
      key: 38,
      groupName: "Pre-Qual",
      header: "Job Production by PreQual - Detail",
      description: "",
    },
    {
      key: 39,
      groupName: "Pre-Qual",
      header: "Job Production by PreQual - Summary",
      description: "",
    },
    {
      key: 40,
      groupName: "Pre-Qual",
      header: "Job Production Summary",
      description: "",
    },
    {
      key: 41,
      groupName: "Production",
      header: "Quote to Actual Comparision",
      description: "Compare Costs at Payitem by Job",
    },
    {
      key: 42,
      groupName: "Production",
      header: "Quote to Actual Comparision all Active Jobs by Division",
      description: "Compare Costs by Payitem",
    },
    {
      key: 43,
      groupName: "Production",
      header: "Quote to Actual Comparision all Active Jobs (All Division)",
      description: "Compare Costs for all Payitem",
    },
    {
      key: 44,
      groupName: "Pre-Qual",
      header: "Job Production by Category & Install Date - Summary",
      description: "",
    },
    {
      key: 45,
      groupName: "Pre-Qual",
      header: "Job Production by Category & Install Date - Detail",
      description: "",
    }
  ];
  const grp1Click = () => {
    setOpenGrp1(!openGrp1);
  };
  const grp2Click = () => {
    setOpenGrp2(!openGrp2);
  };
  const grp3Click = () => {
    setOpenGrp3(!openGrp3);
  };
  const grp4Click = () => {
    setOpenGrp4(!openGrp4);
  };
  const grp5Click = () => {
    setOpenGrp5(!openGrp5);
  };
  const grp6Click = () => {
    setOpenGrp6(!openGrp6);
  };
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    setShowRpt(true);

    switch (index) {
      case 1:
        setReportComp(<EmpRpt />);
        break;
      case 2:
        setReportComp(<ActiveJobRpt />);
        break;
      case 3:
        setReportComp(<JobDetails />);
        break;
      case 4:
        setReportComp(<PayitemCompl />);
        break;
      case 5:
        setReportComp(<CmptlQtyRpt />);
        break;
      case 6:
        setReportComp(<InterJobComplete />);
        break;
      case 7:
        setReportComp(<ExternalJob />);
        break;
      case 8:
        setReportComp(<QtyByStation />);
        break;
      case 9:
        setReportComp(<ComparisionRpt />);
        break;
      case 10:
        setReportComp(<TotalByPeriodRpt />);
        break;
      case 11:
        setReportComp(<FieldRpt />);
        break;
      case 12:
        setReportComp(<EstimateTotal />);
        break;
      case 13:
        setReportComp(<EstSummary />);
        break;
      case 14:
        setReportComp(<EstNoTransDt />);
        break;
      case 15:
        setReportComp(<MdotEst />);
        break;
      case 16:
        setReportComp(<JobBrkElv />);
        break;
      case 17:
        setReportComp(<PostCerts />);
        break;
      case 18:
        setReportComp(<GuardrailCerts />);
        break;
      case 19:
        setReportComp(<CountyMaintenance />);
        break;
      case 20:
        setReportComp(<StaQtyComparison />);
        break;
      case 21:
        setReportComp(<ProdexcContract />);
        break;
      case 22:
        setReportComp(<DBEstnonpay />);
        break;
      case 23:
        setReportComp(<BrgStruConnStatus />);
        break;
      case 24:
        setReportComp(<BrgClearSign />);
        break;
      case 25:
        setReportComp(<CardinalJob />);
        break;
      case 26:
        setReportComp(<PayItmByCont />);
        break;
      case 27:
        setReportComp(<PayItmByYear />);
        break;
      case 28:
        setReportComp(<StPostByJob />);
        break;
      case 29:
        setReportComp(<PlanIss />);
        break;
      case 30:
        setReportComp(<ExtNotes />);
        break;
      case 31:
        setReportComp(<WoodPosts />);
        break;
      case 32:
        setReportComp(<MDOTOverdue />);
        break;
      case 33:
        setReportComp(<WoodSlipFdn />);
        break;
      case 34:
        setReportComp(<ActJobVsCmpJobDetails />);
        break;
      case 35:
        setReportComp(<ActJobVsCmpJobSummary />);
        break;
      case 36:
        setReportComp(<JobProdByCategory />);
        break;
      case 37:
        setReportComp(<JobProdCatSum />);
        break;
      case 38:
        setReportComp(<JobProdByPrequal />);
        break;
      case 39:
        setReportComp(<JobProdByPrequalSummary />);
        break;
      case 40:
        setReportComp(<JobProdSummary />);
        break;
      case 41:
        setReportComp(<QuoteToActual />);
        break;
      case 42:
        setReportComp(<QuoteToActualCompByDivision />);
        break;
      case 43:
        setReportComp(<QuoteToActualCompAllDivision />);
        break;
        case 44:
        setReportComp(<JobProdByCategoryInstDate />);
        break;
        case 45:
          setReportComp(<JobProdByCategoryInstDateDetails />);
          break;
      default:
        setReportComp(null);
    }
  };

  return (
    <Grid container direction="row" className={classes.root}>
      {/* <Typography variant="h6" className={classes.title}>
          Corporate Reports
        </Typography> */}
      <Grid item xs={5} className={classes.demo}>
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          // subheader={
          //   <ListSubheader component="div" id="nested-list-subheader">
          //     Nested List Items
          //   </ListSubheader>
          // }
          className={classes.root}
        >
          {/* ```````````````````For each group render all this ```````````````````*/}
          <ListItem button onClick={grp6Click}>
            <ListItemIcon>
              <ViewListIcon style={{ color: "#00b1f9" }} />
            </ListItemIcon>
            <ListItemText primary="Employee" />
            {openGrp6 ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openGrp6} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {reports
                .filter((x) => {
                  if (x.groupName === "Emp") {
                    return true;
                  } else {
                    return false;
                  }
                })
                .map((x) => {
                  return (
                    <ListItem
                      button
                      className={classes.nested}
                      key={x.key}
                      selected={selectedIndex === x.key}
                      onClick={(event) => handleListItemClick(event, x.key)}
                    >
                      <ListItemIcon>
                        <PostAddIcon style={{ color: green[500] }} />
                      </ListItemIcon>
                      <ListItemText
                        primary={x.header} //{x.key + ". " + x.header}
                        secondary={x.description ? x.description : ""}
                      />
                    </ListItem>
                  );
                })}
            </List>
          </Collapse>
          <ListItem button onClick={grp1Click}>
            <ListItemIcon>
              <ViewListIcon style={{ color: "#00b1f9" }} />
            </ListItemIcon>
            <ListItemText primary="Job Info" />
            {openGrp1 ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openGrp1} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {/* ``````````````````````````````````````````Foreach Item inside that Group render all this````````````````` */}
              {reports
                .filter((x) => {
                  if (x.groupName === "Job Info") {
                    return true;
                  } else {
                    return false;
                  }
                })
                .map((x) => {
                  return (
                    <ListItem
                      button
                      className={classes.nested}
                      key={x.key}
                      selected={selectedIndex === x.key}
                      onClick={(event) => handleListItemClick(event, x.key)}
                    >
                      {/* <ListItemAvatar>
                        <Avatar className={classes.purple}>
                          <PostAddIcon style={{ color: green[500] }} />
                        </Avatar>
                      </ListItemAvatar> */}
                      <ListItemIcon>
                        <PostAddIcon style={{ color: green[500] }} />
                      </ListItemIcon>
                      <ListItemText
                        primary={x.header} //{x.key + ". " + x.header}
                        secondary={x.description ? x.description : ""}
                      />
                    </ListItem>
                  );
                })}
            </List>
          </Collapse>
          <ListItem button onClick={grp2Click}>
            <ListItemIcon>
              <ViewListIcon style={{ color: "#00b1f9" }} />
            </ListItemIcon>
            <ListItemText primary="Take-off" />
            {openGrp2 ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openGrp2} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {reports
                .filter((x) => {
                  if (x.groupName === "Take-off") {
                    return true;
                  } else {
                    return false;
                  }
                })
                .map((x) => {
                  return (
                    <ListItem
                      button
                      className={classes.nested}
                      key={x.key}
                      selected={selectedIndex === x.key}
                      onClick={(event) => handleListItemClick(event, x.key)}
                    >
                      <ListItemIcon>
                        <PostAddIcon style={{ color: green[500] }} />
                      </ListItemIcon>
                      <ListItemText
                        primary={x.header} //{x.key + ". " + x.header}
                        secondary={x.description ? x.description : ""}
                      />
                    </ListItem>
                  );
                })}
            </List>
          </Collapse>
          <ListItem button onClick={grp3Click}>
            <ListItemIcon>
              <ViewListIcon style={{ color: "#00b1f9" }} />
            </ListItemIcon>
            <ListItemText primary="Production" />
            {openGrp3 ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openGrp3} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {reports
                .filter((x) => {
                  if (x.groupName === "Production") {
                    return true;
                  } else {
                    return false;
                  }
                })
                .map((x) => {
                  return (
                    <ListItem
                      button
                      className={classes.nested}
                      key={x.key}
                      selected={selectedIndex === x.key}
                      onClick={(event) => handleListItemClick(event, x.key)}
                    >
                      <ListItemIcon>
                        <PostAddIcon style={{ color: green[500] }} />
                      </ListItemIcon>
                      <ListItemText
                        primary={x.header} //{x.key + ". " + x.header}
                        secondary={x.description ? x.description : ""}
                      />
                    </ListItem>
                  );
                })}
            </List>
          </Collapse>
          <ListItem button onClick={grp4Click}>
            <ListItemIcon>
              <ViewListIcon style={{ color: "#00b1f9" }} />
            </ListItemIcon>
            <ListItemText primary="Estimates" />
            {openGrp4 ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openGrp4} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {reports
                .filter((x) => {
                  if (x.groupName === "Estimates") {
                    return true;
                  } else {
                    return false;
                  }
                })
                .map((x) => {
                  return (
                    <ListItem
                      button
                      className={classes.nested}
                      key={x.key}
                      selected={selectedIndex === x.key}
                      onClick={(event) => handleListItemClick(event, x.key)}
                    >
                      <ListItemIcon>
                        <PostAddIcon style={{ color: green[500] }} />
                      </ListItemIcon>
                      <ListItemText
                        primary={x.header} //{x.key + ". " + x.header}
                        secondary={x.description ? x.description : ""}
                      />
                    </ListItem>
                  );
                })}
            </List>
          </Collapse>
          <ListItem button onClick={grp5Click}>
            <ListItemIcon>
              <ViewListIcon style={{ color: "#00b1f9" }} />
            </ListItemIcon>
            <ListItemText primary="Pre-Qual" />
            {openGrp5 ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openGrp5} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {reports
                .filter((x) => {
                  if (x.groupName === "Pre-Qual") {
                    return true;
                  } else {
                    return false;
                  }
                })
                .map((x) => {
                  return (
                    <ListItem
                      button
                      className={classes.nested}
                      key={x.key}
                      selected={selectedIndex === x.key}
                      onClick={(event) => handleListItemClick(event, x.key)}
                    >
                      <ListItemIcon>
                        <PostAddIcon style={{ color: green[500] }} />
                      </ListItemIcon>
                      <ListItemText
                        primary={x.header} //{x.key + ". " + x.header}
                        secondary={x.description ? x.description : ""}
                      />
                    </ListItem>
                  );
                })}
            </List>
          </Collapse>
        </List>
      </Grid>
      <Grid item xs={7}>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: "50vh" }}
        >
          <Grid item xs={6} component={Card} className={classes.card}>
            {showRpt ? reportComp : ""}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
