import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  TextField,
  Typography
} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import "date-fns";
import { Field, Form, Formik } from "formik";
import { CheckboxWithLabel } from "formik-material-ui";
import { KeyboardDatePicker } from "material-ui-formik-components/KeyboardDatePicker";
import { Select } from "material-ui-formik-components/Select";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import FileUploader from "../../helper/FileUploader";
import { apiService } from "../../services/api.service";
import JobMenu from "../menu/JobLinks";
import FkInputFld from "./formik-custom-fields/FkInputFld";
import FkSelectFld from "./formik-custom-fields/FkSelectFld";
import { DateTime } from "luxon";



const useStyles = makeStyles((theme) => ({
  formControl: {
    padding: "0px 25px",
    minWidth: 200,
  },
  formControlLvL: {
    padding: "0px 25px",
    minWidth: 200,
    "& label": {
      left: 25,
    },
  },
  formControlD: {
    padding: "0px 25px",
    minWidth: 200,
  },
  jobLink: {
    padding: "0px 25px",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  headcontainer: {
    margin: "15px 0%",
  },
  button: {
    color: "white",
    fontWeight: 500,
    fontSize: 14,
    letterSpacing: 0.8,
    borderRadius: 20,
  },
  fullwidthform: {
    width: "100%",
  },
  fullwidthpaper: {
    width: "100%",
  },
  formFooter: {
    padding: "15px 25px",
    background: "#efefef",
    "margin-top": "25px",
  },

  lvlResize: {
    left: "25px",
  },
  dvdform: {
    margin: "40px 0px 10px 0px",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  fileList: {
    backgroundColor: theme.palette.background.paper,
    "padding-left": "9px",
  },
  fileNameTitle: {
    fontSize: "18px",
    fontWeight: "500",
    margin: "30px 0px 5px 0px",
    paddingLeft: 25,
    color: "#00C853",
  },
}));

const AddJob = ({ match }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [ShowWeather, setShowWeather] = useState(
    typeof match !== "undefined" && typeof match.params.id !== "undefined"
  );
  const { enqueueSnackbar } = useSnackbar();
  const [completeDt1, setcompleteDt1] = useState({});
  const [completeDt2, setcompleteDt2] = useState({});
  const [awardDt, setawardDt] = useState({});
  const [closeDt, setcloseDt] = useState({});
  const [finalWaiverDt, setfinalWaiverDt] = useState({});
  const [mdotAcceptedDt, setmdotAcceptedDt] = useState({});
  const [countyName, setCountyName] = useState([]);

  const [contr, setContr] = useState([]);
  const [projEng, setProjEng] = useState([]);
  const [contact, setContact] = useState([]);
  const [mjobtype, setMjobType] = useState([]);
  const [jboloc, setJobloc] = useState([]);
  const [region, setRegion] = useState([]);
  const [job, setJob] = useState({});
  const [myTempVal, setMyTempVal] = useState([]);
  const [jtTempVal, setJtTempVal] = useState([]);
  const [wdays, setWeatherDay] = useState([]);
  const [extFiles, setExtFiles] = useState([]);
  const [files, setFiles] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [showAdmin, setShowAdmin] = useState(false);
  const { user: currentUser } = useSelector((state) => state.auth);
  const defJob = (edata, countyRegions) => {
    if (
      typeof match !== "undefined" &&
      typeof match.params.id !== "undefined"
    ) {
      setJob(edata);
      initJobCounties(edata.regionName, countyRegions, edata.counties);
      setJtTempVal(edata.jobTypeStr ? edata.jobTypeStr : []);
      setIsEditMode(true);
    } else {
      setJob({
        jobCode: "",
        quoteNumber: "",
        activeFlag: true,
        directBillFlag: false,
        verifyFlag: false,
        stateJobNumber: "",
        regionName: "NORTH",
        jobLocType: "",
        jobLocation: "",
        jobType: "",
        contractor: "",
        projectEng: "",
        contact: "",
        cardinalJob: "",
        planLink: "",
        pwlink: "",
        shpoint: "",
        completeDt1: null,
        completeDt2: null,
        awardDt: null,
        closeDt: null,
        finalWaiverDt: null,
        mdotAcceptedDt: null
      });
      setcompleteDt1(new Date());
      setcompleteDt2(new Date());
      setawardDt(new Date());
      setcloseDt(new Date());
      setmdotAcceptedDt(new Date());
      setfinalWaiverDt(new Date());
      initJobCounties("NORTH", countyRegions, []);
      setJtTempVal([]);
    }
  };
  const initJobCounties = (regionName, countyRegions, counties) => {
    var regn = regionName ? regionName : "NORTH";
    var cnty = counties ? counties : [];
    var result = countyRegions.find((obj) => {
      return obj.region === regn;
    });
    setCountyName(result.rcounty.map((ptype) => ptype.county).sort());
    setMyTempVal(cnty);
  };
  useEffect(() => {
    //console.log(match);
    let reqestp =
      typeof match !== "undefined" && match.params.id !== "0"
        ? match.params.id
        : "";
    apiService.getContent("job/page?jobid=" + reqestp).then((edata) => {
      populateScreen(edata);
    });
  }, []);

  const contractors = contr.map((ptype) => ({
    value: ptype.bpId,
    label: ptype.name,
  }));

  const projEngrs = projEng.map((ptype) => ({
    value: ptype.bpId,
    label: ptype.name,
  }));

  const contacts = contact.map((ptype) => ({
    value: ptype.bpId,
    label: ptype.name,
  }));
  const jobLocs = jboloc.map((ptype) => ({
    value: ptype.jobLocationId,
    label: ptype.description,
  }));
  const regions = region.map((ptype) => ({
    value: ptype.region,
    label: ptype.region,
  }));
  function populateScreen(edata) {
    //console.log(edata);
    setContr(edata.contractors);
    setProjEng(edata.projEngineer);
    setContact(edata.contact);
    setMjobType(edata.jobTypes.map((ptype) => ptype.division));
    setJobloc(edata.jobLocations);
    setRegion(edata.countyRegions);
    defJob(edata.job, edata.countyRegions);
    setWeatherDay(edata.wdays);
    setExtFiles(edata.s3files);
    setShowAdmin(currentUser.roles.includes("ROLE_ADMIN"));
  }
  function getExtFileNames() {
    if (extFiles) {
      const upStyle = {
        borderBottom: "1px solid #dedede",
        marginLeft: "16px",
        paddingLeft: "0px",
        paddingBottom: "10px",
      };
      //console.log(extFiles.length);
      return extFiles.map((node) => (
        <ListItem key={Math.floor(Math.random() * node.length)} style={upStyle}>
          <ListItemText primary={node} />
          <ListItemSecondaryAction style={{ right: "-19px" }}>
            <IconButton
              edge="end"
              aria-label="download"
              onClick={downloadS3file(node)}
            >
              <CloudDownloadIcon />
            </IconButton>
            {(showAdmin) && (
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={deleteS3file(node)}
              >
                <DeleteIcon />
              </IconButton>)}
          </ListItemSecondaryAction>
        </ListItem>
      ));
    }
    // return extFiles||["No File Found"].map((value) =>
    //   React.cloneElement(element, {
    //     key: value,
    //   }),
    // );
  }

  const downloadS3file = (fileName) => () => {
    console.log(fileName);
    apiService.getFile(fileName);
  };

  const deleteS3file = (fileName) => () => {
    console.log(fileName);
    apiService.deleteFile(fileName).then(
      (saved) => {
        enqueueSnackbar("File deleted successfully!", {
          variant: "success",
        });
        apiService.getContent("job/page?jobid=" + job.jobId).then((edata) => {
          populateScreen(edata);
        });
      },
      (error) => {
        console.log(error);
        enqueueSnackbar("Error deleting the file:" + error, {
          variant: "error",
        });
      }
    );
  };
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const history = useHistory();

  // function removeTimeZoneInfo(date) {
  //   console.log("Before ="+date);
  //   const isoString = date.toISOString();
  //   const dateTimeString = isoString.substring(0, 10);
  //   console.log("After ="+dateTimeString);
  //   return dateTimeString;
  // }
  function removeTimeZoneInfo(date) {
    console.log("Before = " + date);
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Months are 0-based in JavaScript
    const day = ('0' + date.getDate()).slice(-2); // Add leading 0 if day is less than 10
    const dateString = `${year}-${month}-${day}`;
    console.log("After = " + dateString);
    return dateString;
  }


  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      {ShowWeather && (
        <Grid
          container
          alignItems="flex-start"
          justify="flex-end"
          direction="row"
        >
          <JobMenu
            jobId={job.jobId}
            Wday={wdays}
            headerText={"Create/Update Job"}
            IsAdmin={showAdmin}
          />
        </Grid>
      )}
      <Grid className={classes.headcontainer} container spacing={6}>
        <Formik
          enableReinitialize
          initialValues={{
            jobCode: job.jobCode,
            quoteNumber: job.quoteNumber,
            activeFlag: job.activeFlag,
            directBillFlag: job.directBillFlag,
            verifyFlag: job.verifyFlag,
            stateJobNumber: job.stateJobNumber,
            regionName: job.regionName ? job.regionName : "NORTH",
            jobLocType: job.jobLocType,
            jobLocation: job.jobLocation,
            jobType: job.jobType,
            contractor: job.contractor,
            projectEng: job.projectEng,
            contact: job.contact,
            cardinalJob: job.cardinalJob,
            planLink: job.planLink,
            pwlink: job.pwlink,
            shpoint: job.shpoint,
            completeDt1: job.completeDt1 ? DateTime.fromFormat(job.completeDt1, 'yyyy-MM-dd').toJSDate() : null,
            completeDt2: job.completeDt2 ? DateTime.fromFormat(job.completeDt2, 'yyyy-MM-dd').toJSDate() : null,
            awardDt: job.awardDt ? DateTime.fromFormat(job.awardDt, 'yyyy-MM-dd').toJSDate() : null,
            closeDt: job.closeDt ? DateTime.fromFormat(job.closeDt, 'yyyy-MM-dd').toJSDate() : null,
            finalWaiverDt: job.finalWaiverDt ? DateTime.fromFormat(job.finalWaiverDt, 'yyyy-MM-dd').toJSDate() : null,
            mdotAcceptedDt: job.mdotAcceptedDt ? DateTime.fromFormat(job.mdotAcceptedDt, 'yyyy-MM-dd').toJSDate() : null,

            contractorAuto: job.contractor
              ? contractors.find((x) => x.value == job.contractor)
              : null,
            projectEngAuto: job.projectEng
              ? projEngrs.find((x) => x.value == job.projectEng)
              : null,
            atmContactAuto: job.contact
              ? contacts.find((x) => x.value == job.contact)
              : null,
          }}
          validationSchema={Yup.object().shape({
            jobCode: Yup.string().required("Job Code is required"),
            // quoteNumber: Yup.string().required("Job Quote is required"),
            //jobLocType: Yup.number().required("Job location is required"),
          })}
          onSubmit={(values, { setSubmitting }) => {
            if (values.completeDt1) {
              console.log(values.completeDt1);
              values.completeDt1 = DateTime.fromJSDate(values.completeDt1).toFormat('yyyy-MM-dd');
            }

            if (values.completeDt2) {
              values.completeDt2 = DateTime.fromJSDate(values.completeDt2).toFormat('yyyy-MM-dd');
            }

            if (values.awardDt) {
              values.awardDt = DateTime.fromJSDate(values.awardDt).toFormat('yyyy-MM-dd');
            }

            if (values.closeDt) {
              values.closeDt = DateTime.fromJSDate(values.closeDt).toFormat('yyyy-MM-dd');
            }

            if (values.finalWaiverDt) {
              values.finalWaiverDt = DateTime.fromJSDate(values.finalWaiverDt).toFormat('yyyy-MM-dd');
            }

            if (values.mdotAcceptedDt) {
              values.mdotAcceptedDt = DateTime.fromJSDate(values.mdotAcceptedDt).toFormat('yyyy-MM-dd');
            }

            var temp = {
              ...values,
              jobId: job.jobId,
              //jobCode: job.jobCode,
              counties: myTempVal,
              jobTypeStr: jtTempVal,
              createdOn: job.createdOn,
              createdBy: job.createdBy,
            };
            //delete autocomplete variables..
            //delete jobsave.jobType;
            let {
              jobType,
              contractorAuto,
              projectEngAuto,
              atmContactAuto,
              ...jobsave
            } = temp;
            //console.log(jobsave);

            console.log(JSON.stringify(jobsave, null, 2));
            //POst Data to DB..
            apiService
              .postContent("job/", jobsave)
              // .then((data) => {
              //   apiService.postFile(files, jobsave.jobCode);
              // })
              .then(
                (saved) => {
                  enqueueSnackbar("Job Created/Updated successfully!", {
                    variant: "success",
                  });
                  if (files.length > 0) {
                    apiService.postFile(files, jobsave.jobCode).then(
                      (saved) => {
                        enqueueSnackbar("File(s) Loaded successfullyss!", {
                          variant: "success",
                        });
                        
                      },
                      (error) => {
                        console.log(error);
                        enqueueSnackbar(
                          "Error Creating/Updating Job:" + error,
                          { variant: "error" }
                        );
                      }
                    );
                    // apiService
                    //   .getContent("job/page?jobid=" + job.jobId)
                    //   .then((edata) => {
                    //     populateScreen(edata);
                    //   });
                  }
                  history.goBack();
                  //history.push("joblist/" + saved.jobId);
                },
                (error) => {
                  console.log(error);
                  enqueueSnackbar("Error Creating/Updating Job:" + error, {
                    variant: "error",
                  });
                  history.goBack();
                }
              );
            setSubmitting(false);
            history.goBack();
          }}
        >
          {({ errors, status, touched, setFieldValue, values }) => (
            <Paper className={classes.fullwidthpaper}>
              {/* <DisplayFormikState {...values} /> */}
              <Form className={classes.fullwidthform}>
                <Grid container spacing={0}>
                  <Grid item xs={12} md={6}>
                    <FkInputFld
                      controlClass={classes.formControl}
                      label="JOB #"
                      name="jobCode"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FkInputFld
                      label="Quote"
                      name="quoteNumber"
                      controlClass={classes.formControl}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={0}>
                  <Grid item xs={12} md={12}>
                    <Field
                      type="checkbox"
                      color="primary"
                      component={CheckboxWithLabel}
                      name="activeFlag"
                      Label={{
                        label: "Active",
                        labelPlacement: "end",
                        className: "checkBoxlbl",
                      }}
                    />
                    <Field
                      type="checkbox"
                      color="primary"
                      component={CheckboxWithLabel}
                      name="directBillFlag"
                      Label={{
                        label: "Direct Bill",
                        labelPlacement: "end",
                        className: "checkBoxlbl",
                      }}
                    />
                    <Field
                      type="checkbox"
                      color="primary"
                      component={CheckboxWithLabel}
                      name="verifyFlag"
                      Label={{
                        label: "Verify",
                        labelPlacement: "end",
                        className: "checkBoxlbl",
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={0}>
                  <Grid item xs={12} md={6}>
                    <FkInputFld
                      label="State Job Number"
                      name="stateJobNumber"
                      controlClass={classes.formControl}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl
                      className={classes.formControl}
                      fullWidth={true}
                      margin={"normal"}
                      size={"medium"}
                    >
                      <Autocomplete
                        multiple
                        id="checkboxes-jobtype"
                        options={mjobtype}
                        limitTags={3}
                        value={jtTempVal}
                        disableCloseOnSelect
                        onChange={(event, newValue) => {
                          setJtTempVal(newValue);
                        }}
                        getOptionLabel={(option) => option}
                        renderOption={(option, { selected }) => (
                          <React.Fragment>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option}
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Job Type"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password",
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={0}>
                  <Grid item xs={12} md={6}>
                    <Field
                      name="regionName"
                      label="Job Region"
                      options={regions}
                      component={Select}
                      className={classes.formControlLvL}
                      onChange={(_, value) => {
                        var result = region.find((obj) => {
                          return obj.region === value.props.value;
                        });
                        //Remove PreSelected Values if Region is Changed..
                        setMyTempVal([]);
                        setCountyName(
                          result.rcounty.map((ptype) => ptype.county)
                        );
                        setFieldValue("regionName", value.props.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl
                      className={classes.formControl}
                      fullWidth={true}
                      margin={"normal"}
                      size={"medium"}
                    >
                      <Autocomplete
                        multiple
                        id="checkboxes-tags-demo"
                        options={countyName}
                        limitTags={3}
                        value={myTempVal}
                        disableCloseOnSelect
                        onChange={(event, newValue) => {
                          setMyTempVal(newValue);
                          //console.log(newValue);
                        }}
                        getOptionLabel={(option) => option}
                        renderOption={(option, { selected }) => (
                          <React.Fragment>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option}
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Job County"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password",
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container spacing={0}>
                  <Grid item xs={12} md={6}>
                    <FkInputFld
                      controlClass={classes.formControl}
                      label="Job Location"
                      name="jobLocation"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FkSelectFld
                      name="jobLocType"
                      items={jobLocs}
                      label="Job Location Type"
                      controlClass={classes.formControl}
                      lableClass={classes.lvlResize}
                    />
                  </Grid>
                </Grid>

                <Divider className={classes.dvdform} />
                <Grid container spacing={0}>
                  <Grid item xs={12} md={6}>
                    <Field
                      name="contractor"
                      options={contractors}
                      component={Autocomplete}
                      value={values.contractorAuto}
                      renderOption={(option) => (
                        <React.Fragment>{option.label}</React.Fragment>
                      )}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Contractor"
                          name="contractor"
                          margin="normal"
                          className={classes.formControlLvL}
                        />
                      )}
                      onChange={(_, value) => {
                        if (value) {
                          console.log(value);
                          setFieldValue("contractor", value.value);
                        } else {
                          setFieldValue("contractor", "");
                        }
                        setFieldValue("contractorAuto", value);
                      }}
                    />
                    {/* <FkSelectFld
                      name="contractor"
                      items={contractors}
                      label="Contractor"
                      controlClass={classes.formControl}
                      lableClass={classes.lvlResize}
                    /> */}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      name="projectEng"
                      options={projEngrs}
                      component={Autocomplete}
                      value={values.projectEngAuto}
                      renderOption={(option) => (
                        <React.Fragment>{option.label}</React.Fragment>
                      )}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Project Engineer"
                          name="projectEng"
                          margin="normal"
                          className={classes.formControlLvL}
                        />
                      )}
                      onChange={(_, value) => {
                        if (value) {
                          console.log(value);
                          setFieldValue("projectEng", value.value);
                        } else {
                          setFieldValue("projectEng", "");
                        }
                        setFieldValue("projectEngAuto", value);
                      }}
                    />
                    {/* <FkSelectFld
                      name="projectEng"
                      items={projEngrs}
                      label="Project Engineer"
                      controlClass={classes.formControl}
                      lableClass={classes.lvlResize}
                    /> */}
                  </Grid>
                </Grid>
                <Grid container spacing={0}>
                  <Grid item xs={12} md={6}>
                    <Field
                      name="contact"
                      options={contacts}
                      component={Autocomplete}
                      value={values.atmContactAuto}
                      renderOption={(option) => (
                        <React.Fragment>{option.label}</React.Fragment>
                      )}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Contact"
                          name="contact"
                          margin="normal"
                          className={classes.formControlLvL}
                        />
                      )}
                      onChange={(_, value) => {
                        if (value) {
                          console.log(value);
                          setFieldValue("contact", value.value);
                        } else {
                          setFieldValue("contact", "");
                        }
                        setFieldValue("atmContactAuto", value);
                      }}
                    />

                    {/* <FkSelectFld
                      name="contact"
                      items={contacts}
                      label="ATM Contact"
                      controlClass={classes.formControl}
                      lableClass={classes.lvlResize}
                    /> */}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FkInputFld
                      label="Cardinal Job"
                      name="cardinalJob"
                      controlClass={classes.formControl}
                    />
                  </Grid>
                </Grid>
                <Divider className={classes.dvdform} />
                <Grid container spacing={0}>
                  <Grid item xs={12} md={6}>
                    <FkInputFld
                      label="Notes"
                      name="planLink"
                      controlClass={classes.formControl}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FkInputFld
                      label="PW Link"
                      name="pwlink"
                      controlClass={classes.formControl}
                    />
                    {isEditMode && values.pwlink && (
                      <Link
                        className={classes.jobLink}
                        href={values.pwlink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Navigate to PW Link
                      </Link>
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={0}>

                  <Grid item xs={12} md={6}>
                    <FkInputFld
                      label="Sharepoint Link"
                      name="shpoint"
                      controlClass={classes.formControl}
                    />
                    {isEditMode && values.shpoint && (
                      <Link
                        className={classes.jobLink}
                        href={values.shpoint}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Navigate to SharePoint
                      </Link>
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={0}>
                  <Grid item xs={12} md={6}>
                    <Field
                      name="completeDt1"
                      component={KeyboardDatePicker}
                      label="Completion Date 1"
                      format="MM/dd/yyyy"
                      size="small"
                      className={classes.formControlLvL}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      name="completeDt2"
                      component={KeyboardDatePicker}
                      label="Completion Date 2"
                      format="MM/dd/yyyy"
                      size="small"
                      className={classes.formControlLvL}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={0}>
                  <Grid item xs={12} md={6}>
                    <Field
                      name="awardDt"
                      component={KeyboardDatePicker}
                      label="Award Date"
                      format="MM/dd/yyyy"
                      size="small"
                      className={classes.formControlLvL}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      name="closeDt"
                      component={KeyboardDatePicker}
                      label="Close Date"
                      format="MM/dd/yyyy"
                      size="small"
                      className={classes.formControlLvL}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={0}>
                  <Grid item xs={12} md={6}>
                    <Field
                      name="finalWaiverDt"
                      component={KeyboardDatePicker}
                      label="Final Waiver Signed"
                      format="MM/dd/yyyy"
                      size="small"
                      className={classes.formControlLvL}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      name="mdotAcceptedDt"
                      component={KeyboardDatePicker}
                      label="MDOT Accepted"
                      format="MM/dd/yyyy"
                      size="small"
                      className={classes.formControlLvL}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <FileUploader
                      maxFiles={4}
                      acceptedFileTypes="application/pdf"
                      fileNames={files}
                      onDrop={(acceptedFiles) => {
                        setFiles(acceptedFiles.map((file) => file));
                        //apiService.postFile(acceptedFiles.map((file) => file), "201600")
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography className={classes.fileNameTitle}>
                      Uploaded Files
                    </Typography>
                    <div className={classes.fileList}>
                      <List dense>{getExtFileNames()}</List>
                    </div>
                  </Grid>
                </Grid>

                {showAdmin && (
                  <Grid container className={classes.formFooter}>
                    <Grid item xs={6} md={6}>
                      <Button
                        variant="contained"
                        style={{ background: "#ff5722" }}
                        className={classes.button}
                        startIcon={<CancelIcon />}
                        onClick={() => history.goBack()}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item xs={6} md={6} style={{ textAlign: "right" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        startIcon={<SaveIcon />}
                        type="submit"
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Form>
            </Paper>
          )}
        </Formik>
      </Grid>
    </MuiPickersUtilsProvider>
  );
};
export default AddJob;
