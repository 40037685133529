import DateFnsUtils from "@date-io/date-fns";
import { FormControl, Grid, TextField } from "@material-ui/core";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import React, { useState } from "react";
import { apiService } from "../../services/api.service";
import ReportFooter from "./ReportFooter";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    margin: "0.75rem 0.75rem",
    //'box-shadowhadow': 'none',
  },

  title: {
    fontSize: 14,
    fontWeight: 600,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function MDOTEstRpt() {
  const classes = useStyles();
  const [days, setDays] = useState();

  const runReport = (event, rptType) => {
    if (days) {
      console.log(days + " ---Days");
      var qrystr = "esttransfervardate&format=" + rptType + "&days_int=" + days;
      var fileName = "MdotTransDays." + days + "." + rptType;
      apiService.getReport(qrystr, fileName);
    } else {
      console.log("Please input days..");
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      {/* <Card className={classes.root} variant="outlined"> */}
      <CardContent>
        <Typography className={classes.title} gutterBottom>
          Unpaid/Non-Transferred MDOT Estimates
        </Typography>
        <Typography variant="h5" component="h2"></Typography>
        <Typography className={classes.pos} color="textSecondary"></Typography>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                type="number"
                id="standard-basic"
                label="Days"
                onChange={(event) => {
                  setDays(event.target.value);
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <ReportFooter sumbit={runReport} />
      </CardActions>
      {/* </Card> */}
    </MuiPickersUtilsProvider>
  );
}
