import { Button, FormControl, FormControlLabel, Grid, IconButton, Radio, RadioGroup, Tooltip } from "@material-ui/core";
import React from "react";
import ExplicitIcon from '@material-ui/icons/Explicit';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { green } from "@material-ui/core/colors";

const ReportFooter = (props) => {
  return (
    <Grid container spacing={1} justify="flex-end">
      {/* space-between // Use this when Move btn to Left and Right*/}
      <Tooltip title="Get Excel" aria-label="Excel">
        <IconButton
          aria-label="Excel"
          onClick={(event) => props.sumbit(event, "xlsx")}
        >
          <ExplicitIcon fontSize="large" style={{ color: green[500] }} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Get PDF" aria-label="PDF">
        <IconButton
          aria-label="pdf"
          onClick={(event) => props.sumbit(event, "pdf")}
        >
          <PictureAsPdfIcon fontSize="large" style={{ color: "red" }} />
        </IconButton>
      </Tooltip>
      {/* <Grid item>
        <FormControl component="fieldset">
         
          <RadioGroup
            row
            aria-label="ReportType"
            name="rptType"
            value={props.rptType}
            onChange={props.RadioChange}
          >
            <FormControlLabel
              value="pdf"
              labelPlacement="end"
              control={<Radio size="small" />}
              label="PDF"
            />
            <FormControlLabel
              value="excel"
              labelPlacement="end"
              control={<Radio size="small" />}
              label="EXCEL"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item>
        <Button size="small" onClick={props.sumbit}>Get Report</Button>
      </Grid> */}
    </Grid>
  );
};
export default ReportFooter;
