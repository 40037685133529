import DateFnsUtils from "@date-io/date-fns";
import {
  Button, Grid, Paper, Table,
  TableBody, TableCell, TableContainer,
  TableHead, TableRow, TextField
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Field, Form, Formik } from 'formik';
import { KeyboardDatePicker } from "material-ui-formik-components/KeyboardDatePicker";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useRef, useState } from "react";
import "react-image-lightbox/style.css";
import { useHistory } from 'react-router-dom';
import { useTable } from 'react-table';
import * as Yup from 'yup';
import { apiService } from '../../services/api.service';
import FkInputFld from "../forms/formik-custom-fields/FkInputFld";
import Estimates from "./estimateList";
import JobMenu from "../menu/JobLinks";
import { useDispatch, useSelector } from "react-redux";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#4cc8ff',
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 13,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function Table2({ columns, data, onRowClick }) {
  // Use the state and functions returned from useTable to build your UI
  const handleRowClick = useCallback(
    (data) => {
      onRowClick(data);
    },
    [onRowClick]
  );
  const [selected, setselected] = useState(-1);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  })
  // Render the UI for your table
  return (
    <Table  {...getTableProps()}>
      <TableHead>
        {headerGroups.map(headerGroup => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <StyledTableCell {...column.getHeaderProps()}>
                {column.render('Header')}
                {/* Render the columns filter UI */}
                {/* <div>{column.render('Header')}</div> */}
              </StyledTableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <StyledTableRow {...row.getRowProps({ onClick: () => handleRowClick(row) })}>
              {row.cells.map(cell => {
                return <StyledTableCell {...cell.getCellProps()}>{cell.render('Cell')}</StyledTableCell>
              })}
            </StyledTableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    padding: "0px 12px",
    'margin-top': '8px',
  },
  formControlD: {
    padding: "0px 12px",
    "& label": {
      padding: "0px 12px",
    }
  },
  rdgControl: {
    padding: "0px 12px",
    'margin-top': '8px',
  },
  headcontainer: {
    margin: "15px 0%",
  },
  fullwidthform: {
    width: '100%',
    padding: '0px 15px',
  },
  fullwidthpaper: {
    width: "100%",
  },
  fullwidthpaperFH: {
    width: "100%",
    'background-color': 'rgb(0,176,255,0.7)',
    'border-right': '10px solid #ff5722',
  },
  button: {
    color: 'white',
  },
  formFooter: {
    padding: "15px 18px",
    background: "#efefef",
    "margin-top": "25px",
  },
  signlogo: {
    width: '180px',
    height: '155px',
    padding: '0px 0px',
  },
  lvlResize: {
    'left': "15px"
  },
  rdglvl: {
    'padding-left': '12px',
    'margin-top': '10px',
  },
  grayOut: {
    background: 'rgb(0,0,0,0.1)',
    margin: '25px 0px',
  },
  formHeader: {
    padding: '10px 15px',
    'font-weight': 'bold',
    'font-size': '18px',
    'letter-spacing': '0.5px',
    color: 'white',
  },
  tableFH: {
    'font-weight': '500',
    'text-transform': 'uppercase',
    'font-size': '14px',
  },
  LeftCND: {
    'padding ': '4px 7px 0px 0px',
  },
  RightCND: {
    'padding ': '0px 0px 0px 7px',
  }
}));
const EstimateHome = ({ match }) => {

  const columns = React.useMemo(
    () => [
      {
        Header: 'Pay Item',
        accessor: 'payItmCode',
      },
      {
        Header: 'Description',
        accessor: 'payItmDesc',
      },
      {
        Header: 'Quantity',
        accessor: 'quantity',
      },
      {
        Header: 'Unit',
        accessor: 'unit',
      }
    ],
    []
  )
  const [btnText, setbtnText] = useState("Add");
  const [lineItem, setLineItem] = useState([]);
  const [job, setJob] = useState({});
  const [isEdit, setisEdit] = useState(false);
  const [estimateItem, setEstimateItem] = useState([]);
  const [directBill, setDirectBill] = useState(false);
  const [initialValues, setinitialValues] = useState({
    payItemCode: "",
    payitemDesc: "",
    estimateNum: "",
    createdDate: new Date(),
    transferDate: null,
    primeQty: "",
    paidDate: null,
    mdotQty: "",
  });
  const [showAdmin, setShowAdmin] = useState(false);
  const [showCorporate, setShowCorporate] = useState(false);
  const { user: currentUser } = useSelector((state) => state.auth);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    console.log(match);
    let reqestp =
      typeof match !== "undefined" && typeof match.params.id !== "undefined"
        ? match.params.id
        : "5";
    apiService.getContent("est/page?jobid=" + reqestp)
      .then((edata) => {
        //console.log(edata);
        setJob(edata.job);
        setLineItem(edata.jobPayItms);
        setEstimateItem(edata.estimates);
      });
      setShowAdmin(currentUser.roles.includes("ROLE_ADMIN"));
      setShowCorporate(currentUser.roles.includes("ROLE_CORPORATE"))
  }, []);

  const availablePayitems = lineItem.map((x) => ({
    value: x.payItmCode,
    label: x.payItmCode + " - " + x.payItmDesc,
  }));
  function refreshTable() {
    apiService.getContent("est/page?jobid=" + job.jobId)
      .then((edata) => {
        console.log(edata);
        setJob(edata.job);
        setLineItem(edata.jobPayItms);
        setEstimateItem(edata.estimates);
      });
  }

  const formRef = useRef();
  const handleClick = (row) => {
    row.getRowProps({ style: { color: 'blue' } })
    OnPayItemSelect(row.original);
  }

  const OnPayItemSelect = (payItem) => {

    if (payItem) {
      setinitialValues({
        ...formRef.current.values,
        payItemCode: payItem.payItmCode,
        payitemDesc: payItem.payItmDesc,
      });
    } else {
      //Clear value..
      setinitialValues({
        ...formRef.current.values,
        payItemCode: "",
        payitemDesc: "",
      });
    }
  };
  const [estimateId, setestimateId] = useState();
  const EditStation = (row) => {
    setbtnText("Update");
    setisEdit(true);
    console.log(row);
    console.log(JSON.stringify(formRef.current.values));
    //Pre-Poplulate 
    let jobPayitmc = lineItem.find(y => y.payItmCode === row.payItmCode);
    setinitialValues({
      ...formRef.current.values,
      payItemCode: row.payItmCode,
      payitemDesc: jobPayitmc.payItmDesc,
      estimateNum: row.estimateNum,
      createdDate: dateShift(row.createdDate),
      transferDate: dateShift(row.transferDate),
      primeQty: row.primeQty,
      paidDate: dateShift(row.paidDate),
      mdotQty: row.mdotQty,
    });
    setestimateId(row.estimateId);
  }

  const dateShift = (date) => {
    if(date){
      let newDat = new Date(date);
      return new Date(newDat.getTime() + newDat.getTimezoneOffset() * 60000)
    }
    return null;    
  }
  const postEstimates = (values) => {
    let jobPayitm = lineItem.find(y => y.payItmCode === values.payItemCode);
    console.log(jobPayitm)
    let submitvals = {
      ...values,
      //"job": job.jobId,
      "jobPayitem": jobPayitm.jobPayitmId,
      "createdBy": "USerNmae",
    };
    //console.log(submitvals)
    apiService.addContent("est/", submitvals)
      .then((response) => {
        refreshTable();
        clearPayItem();
        enqueueSnackbar("Saved Successfully", { variant: "success" });
      })
      .catch((error) => {
        enqueueSnackbar("There is an error in saving estimate:" + error, { variant: 'error', });
        console.log('error: ' + error);
      });
  }
  const putEstimates = (data) => {
    let jobPayitm = lineItem.find(y => y.payItmCode === data.payItemCode);
    let submitvals = {
      ...data,
      //"job": job.jobId,
      "jobPayitem": jobPayitm.jobPayitmId,
      "createdBy": "USerNmae",
    };
    apiService.putContent("est/" + estimateId, submitvals)
      .then(response => response.json())
      .then(data => console.log(data))
      .then((response) => {
        refreshTable();
        clearPayItem();
        enqueueSnackbar("Updated Successfully", { variant: "success" });
      })
      .catch((error) => {
        enqueueSnackbar("There is an error in saving Estimate:" + error, { variant: 'error', });
        console.log('error: ' + error);
      });
  }
  const DeleteStation = (data) => {
    let delJsp = data.estimateId;
    //console.log(data);
    apiService.deleteObject("est/" + delJsp)
      .then(res => {
        refreshTable()
        enqueueSnackbar("Deleted Estimate successfully", { variant: "success" });
      })
      .catch(err => enqueueSnackbar("There is an error in deleting Estimate:" + err, { variant: 'error', }))
  }

  const classes = useStyles();
  const history = useHistory();
  const handleEdit = () => {
    history.push("/joblist/" + job.jobId);
  }
  const handleJobPayItm = () => {
    history.push("/jobdetails/" + job.jobId);
  }
  function clearPayItem() {
    setinitialValues({
      ...formRef.current.values,
      payItemCode: "",
      payitemDesc: "",
      estNo: "",
      createdDate: new Date(),
      transferDate: null,
      paidQty: "",
      paidDate: null,
    });
    setbtnText("Add");
    setisEdit(false);
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      {/* <pre>{console.log(initialValues)}</pre> */}
      <JobMenu jobId={job.jobId} Wday={null} headerText={"Estimates"} />
      <Grid className={classes.headcontainer} container>
        {(showAdmin || showCorporate) && (
          <Grid
            item
            xs={12}
            sm={4}
            // style={disabled ? { pointerEvents: "none", opacity: "0.4" } : {}}
            className={classes.LeftCND}
          >
            <Paper className={classes.fullwidthpaperFH}>
              <Grid container spacing={1} className={classes.formHeader}>
                <Grid item xs={12} sm={12}>
                  <Typography color="inherit" className={classes.tableFH}>
                    Job Code :{" "}
                    <a href="#">
                      <span onClick={() => handleEdit()}>{job.jobCode}</span>
                    </a>
                  </Typography>
                  <Typography color="inherit" className={classes.tableFH}>
                    Location :{" "}
                    <a href="#">
                      <span onClick={() => handleJobPayItm()}>
                        {job.jobLocation}
                      </span>
                    </a>
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={Yup.object().shape({})}
              innerRef={formRef}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                if (isEdit) {
                  putEstimates(values);
                } else {
                  postEstimates(values);
                }
                // setEstimateItem(oldVal=>[...oldVal,values]);
                console.log(values);
                setSubmitting(false);
                resetForm();
              }}
            >
              {({
                errors,
                status,
                touched,
                isSubmitting,
                values,
                handleBlur,
                setFieldValue,
                setTouched,
              }) => (
                <Paper className={classes.fullwidthpaper}>
                  <Form className={classes.fullwidthform}>
                    <Grid container spacing={1}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}></Grid>
                        <Grid item xs={12} md={6}>
                          {/* <FkSelectFld
                            name="contractor"
                            items={contractors}
                            label="Contractor"
                            controlClass={classes.formControl}
                          /> */}
                        </Grid>
                      </Grid>

                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          {/* <FkInputFld
                            name="payItemCode"
                            label="Pay Item Code"
                            controlClass={classes.formControl}
                            
                          /> */}
                          <Field
                            name="payItemCode"
                            options={availablePayitems}
                            component={Autocomplete}
                            //disableListWrap

                            inputValue={values.payItemCode}
                            getOptionLabel={(option) => option.label}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Pay Items"
                                margin="normal"
                                className={classes.formControl}
                                onChange={(event) => {
                                  //console.log(event.target.value);
                                  setFieldValue(
                                    "payItemCode",
                                    event.target.value
                                  );
                                }}
                              />
                            )}
                            onChange={(_, value) => {
                              if (value) {
                                setFieldValue("payItemCode", value.value);
                                //console.log(lineItem.find(y => y.payItmCode === value.label));
                                OnPayItemSelect(
                                  lineItem.find(
                                    (y) => y.payItmCode === value.value
                                  )
                                );
                              } else {
                                setFieldValue("payItemCode", "");
                                OnPayItemSelect(null);
                                //Clear all set values..may be..TODO
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FkInputFld
                            name="payitemDesc"
                            label="Pay Item Description"
                            controlClass={classes.formControl}
                            disabled
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={0}>
                        <Grid item xs={12}>
                          <FkInputFld
                            name="estimateNum"
                            label="Estimate No"
                            controlClass={classes.formControl}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FkInputFld
                            name="mdotQty"
                            label="MDOT Paid Quantity"
                            controlClass={classes.formControl}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            name="createdDate"
                            component={KeyboardDatePicker}
                            label="Created On Date"
                            variant="inline"
                            format="MM/dd/yyyy"
                            size="small"
                            className={classes.formControlD}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Field
                          name="transferDate"
                          component={KeyboardDatePicker}
                          label="Transfer Date"
                          variant="inline"
                          format="MM/dd/yyyy"
                          size="small"
                          className={classes.formControlD}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <FkInputFld
                          name="primeQty"
                          label="Prime Paid Quantity"
                          controlClass={classes.formControl}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Field
                          name="paidDate"
                          component={KeyboardDatePicker}
                          variant="inline"
                          label="Prime Paid Date"
                          format="MM/dd/yyyy"
                          size="small"
                          className={classes.formControlD}
                        />
                      </Grid>
                      <Grid item xs={12}></Grid>
                    </Grid>

                    <Grid container className={classes.formFooter}>
                      <Grid item xs={6} md={6}>
                        <Button
                          variant="contained"
                          style={{ background: "red" }}
                          className={classes.button}
                          startIcon={<CancelIcon />}
                          onClick={() => {
                            isEdit ? setisEdit(false) : history.goBack();
                          }}
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item xs={6} md={6} style={{ textAlign: "right" }}>
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          startIcon={<SaveIcon />}
                          type="submit"
                        >
                          {btnText}
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                  {/* Dev Only  */}
                  {/* <DisplayFormikState {...values} /> */}
                </Paper>
              )}
            </Formik>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sm={(showAdmin || showCorporate)?8:12}
          // style={isEdit ? { pointerEvents: "none", opacity: "0.4" } : {}}
          className={classes.RightCND}
        >
          <Paper className={classes.fullwidthpaper}>
            <TableContainer>
              <Estimates
                data={estimateItem}
                onStationDelete={DeleteStation}
                onStationEdit={EditStation}
                showAdmin={(showAdmin || showCorporate)}
              />
            </TableContainer>
          </Paper>
        </Grid>
        {(showAdmin || showCorporate) && (
        <Grid item xs={12} sm={12}>
          <Paper className={classes.fullwidthpaper}>
            <TableContainer>
              <Table2
                columns={columns}
                data={lineItem}
                onRowClick={handleClick}
              />
            </TableContainer>
          </Paper>
        </Grid>
        )}
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

export default EstimateHome;
