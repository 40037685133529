import {
    makeStyles
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import MaterialTable, { MTableToolbar } from "material-table";
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import ResetUserDialog from "../logincomps/ResetUserDialog";
import { apiService } from "../services/api.service";
const useStyles = makeStyles((theme) => ({
  innerTableHeading: {
    "padding-left": "17px",
    "text-transform": "uppercase",
    "font-size": "14px",
    "font-weight": "700",
  },
  formControl: {
    //margin: theme.spacing(1),
    minWidth: 150,
  },
  displayNone: {
    display: "none",
  },
}));
const ManageUsers = () => {
  const modalRef = useRef(null);
  const classes = useStyles();
  const [allUser, setAllUser] = React.useState([]);
  const [selUser, setselUser] = React.useState([]);
  const[reload, setReload] = React.useState(false);
  const { user: currentUser } = useSelector((state) => state.auth);

  function OnUserSelect(rowData) {
    const exRole = rowData.atmUserRole?.map((x) => ({
      title: x.atmRole == 1 ? "Admin" : x.atmRole == 2 ? "Foreman" : x.atmRole == 3 ? "Manager":"Corporate",
      id: x.atmRole,
    }));
    setselUser(rowData);
    modalRef.current.handleClickOpen(exRole);
  }

  useEffect(() => {
    //console.log(match);
    if (!currentUser) {
      return <Redirect to="/login" />;
    }
    apiService.getContent("user/allusers").then((edata) => {
      console.log(edata);
      setAllUser(edata);
      setReload(false);
    });
  }, [reload]);
  return (
    <>
      <ResetUserDialog ref={modalRef} props={selUser} callBack={setReload}></ResetUserDialog>
      <MaterialTable
        title={"User Management"}
        columns={[
          { title: "User Name", field: "userName" },
          { title: "Email", field: "email" },
          { title: "Access", field: "pwd" },
          { title: "Created On", field: "createdOn", type: "date" },
          { title: "Last Login", field: "lastLogin", type: "datetime" },
          { title: "Roles", field: "atmUserRole", hidden: true },
          { title: "UserId", field: "userId", hidden: true },
          //      render: rowData => <span>{Object.keys(rowData.atmUserRole).map(function(k){return rowData[k]}).join(", ")}</span> },
        ]}
        data={allUser}
        actions={[
          {
            icon: () => <EditIcon style={{ fontSize: 25, color: "#fb8c00" }} />, //,color: "#fb8c00"
            tooltip: "Edit User",
            onClick: (event, rowData) => OnUserSelect(rowData),
            //
          },
        ]}
        options={{
          filtering: false,
          // grouping: true,
          // selection:true,
          // showSelectAllCheckbox:false,
          pageSize: 25, //Diaplay all no of reords..
          pageSizeOptions: [25, 50, 100],
          //padding: "dense",
          showTitle: true,
          emptyRowsWhenPaging: false,
          //tableLayout:"fixed"
        }}
        components={{
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} />
              <div style={{ padding: "10px 10px" }}>
                {/* <InputLabel>Pay Items</InputLabel>
            <Select
              labelId="payitem-list-checkbox-label"
              id="payitem-list-checkbox"
              multiple
              limitTags={3}
              value={personName}
              onChange={handleChange}
              input={<Input />}
              renderValue={(selected) => selected.join(", ")}
             // MenuProps={MenuProps}
            >
              {payItems.map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={personName.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select> */}
              </div>
            </div>
          ),
        }}
        // onSelectionChange={(rows) =>  alert('You selected ' + rows.length + ' rows')}
        localization={{
          header: {
            actions: "",
          },
        }}
      />
    </>
  );
};

export default ManageUsers;
