import DateFnsUtils from "@date-io/date-fns";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Switch
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import "date-fns";
import { useSnackbar } from "notistack";
import React, { useImperativeHandle, useState } from "react";
import { apiService } from "../services/api.service";

const roles = [
  { title: "Admin", id: 1 },  
  { title: "Foreman", id: 2 },
  { title: "Manager", id: 3 },
  { title: "Corporate", id: 4 },
];
const ResetUserDialog = React.forwardRef((props, ref) => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const [open, setOpen] = useState(false);
  const [pwdError, setpwdError] = useState("");
  const [compwdError, setCompwdError] = useState("");
  const [userRoles, setUserRoles] = useState([]);
  const [allValues, setAllValues] = useState({
    pwd: "",
    email: "",
    compwd: "",
    roles: "",
    disabled: false, //Future..
  });
  const { enqueueSnackbar } = useSnackbar();
  const clearState = () => {
    setAllValues({
      pwd: "",
      email: "",
      roles: "",
      compwd: "",
      disabled: false,
    });
    setUserRoles([]);
  };

  const changeHandler = (e) => {
    setAllValues({ ...allValues, [e.target.name]: e.target.value });
  };

  const handleSwitchChange = (e) => {
    setAllValues({ ...allValues, [e.target.name]: e.target.checked });
  };
  const handleClickOpen = (exRole) => {
    setOpen(true);
    setUserRoles(exRole);
  };

  const handleClose = () => {
    setOpen(false);
    setpwdError("");
    setCompwdError("");
    clearState();
  };
  function handleSubmit() {
    //setAllValues({...allValues, job: props.props})
    if (!allValues.pwd) {
      setpwdError("Password is required");
      return;
    } else if (allValues.pwd.length < 6 || allValues.pwd.length > 20) {
      setpwdError("Password must be between 6 and 20 characters.");
      return;
    } else {
      setpwdError("");
    }
    if (!allValues.compwd) {
      setCompwdError("Confirm password is required");
      return;
    } else {
      setCompwdError("");
    }
    if (allValues.compwd != allValues.pwd) {
      setCompwdError("Password doesn't match");
      return;
    }
    var pushv = { pwd: allValues.pwd, userName: props.props.userName }; //,roles:userRoles
    apiService.postContent("user/reset", pushv).then(
      (response) => {
        enqueueSnackbar("User settings updated successfully!", {
          variant: "success",
        });
        setOpen(false);
        clearState();
      },
      (error) => {
        console.log(error);
        enqueueSnackbar("Error updating user: " + error, { variant: "error" });
      }
    );
  }
  function handleSubmitRoles() {

    //TODO Need to add validation atleast one Role required..
    //Preselcting values not correct...
    const roleIds = userRoles.map((x) => x.id);
    var pushv = {
      pwd: allValues.pwd,
      userName: props.props.userName,
      roles: roleIds,
    }; //,roles:userRoles
    apiService.postContent("user/resetroles", pushv).then(
      (response) => {
        enqueueSnackbar("User roles updated successfully!", {
          variant: "success",
        });
        setOpen(false);
        clearState();
        props.callBack(true);
      },
      (error) => {
        console.log(error);
        enqueueSnackbar("Error updating user roles: " + error, {
          variant: "error",
        });
      }
    );
  }
  useImperativeHandle(ref, () => ({
    handleClickOpen,
    handleClose,
  }));
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Update User: {props.props.userName}
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              name="pwd"
              label="New Password"
              type="password"
              onChange={changeHandler}
              fullWidth
              required
              error={pwdError.length > 1}
              helperText={pwdError}
            // helperText="Incorrect entry."
            // error={allValues.reason === ""}
            />
            <TextField
              margin="dense"
              name="compwd"
              label="Confirm Password"
              type="password"
              onChange={changeHandler}
              fullWidth
              required
              error={compwdError.length > 1}
              helperText={compwdError}
            // helperText="Incorrect entry."
            // error={allValues.reason === ""}
            />

            <FormControl
              // className={classes.formControl}
              fullWidth={true}
              margin={"normal"}
              size={"medium"}
            >
              <Autocomplete
                multiple
                id="checkboxes-role"
                options={roles}
                limitTags={4}
                value={userRoles}
                disableCloseOnSelect
                onChange={(event, newValue) => {
                  //console.log(newValue);
                  setUserRoles(newValue);
                }}
                getOptionLabel={(option) => option.title}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.title}
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="User Roles"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                  />
                )}
              />              
            </FormControl>
            {/* Not Needed for now.. */}
            <FormControl margin="normal">
              <FormControlLabel
                control={
                  <Switch
                    checked={allValues.disabled}
                    onChange={handleSwitchChange}
                    name="disabled"
                    color="primary"
                  />
                }
                labelPlacement="end"
                label="Disable User"
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" onClick={handleSubmit} color="primary">
              Update Password
            </Button>
            <Button type="submit" onClick={handleSubmitRoles} color="primary">
              Update Roles
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      {/* </form> */}
    </MuiPickersUtilsProvider>
  );
});

export default ResetUserDialog;
