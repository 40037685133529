import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { apiService } from '../../services/api.service';

const EmpRpt = () => {  
  const [data, setdata] = useState([]);
  useEffect(() => {
    apiService.getContent("bp/empls")
      .then((res) => {
        setdata(res);
      });
  }, []);
  return (
    <>
      <MaterialTable
        title="Employee List"
        columns={[
          { title: "First Name", field: "firstName" },
          { title: "Last Name", field: "lastName" },
          { title: "Phone", field: "phone" },
          { title: "Email", field: "email" },
          { title: "Primary Contact", field: "contact1Name" },
          { title: "PC Phone", field: "cell1Phone" },
          { title: "Relationship", field: "relation1" },
          { title: "Secondary Contact", field: "contact2Name" },
          { title: "SC Phone", field: "cell2Phone" },
          { title: "Relationship", field: "relation2" },
        ]}
        data={data}
        options={{
          filtering: false,
          exportButton: true,
          paging: false,          
          padding: "dense",
          showTitle: true,
          //tableLayout:"fixed"
        }}
        // onSelectionChange={(rows) =>  alert('You selected ' + rows.length + ' rows')}
        localization={{
          header: {
            actions: "",
          },
        }}
      />
    </>
  );
};

export default EmpRpt;
