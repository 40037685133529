import React from "react";
import MaterialTable from "material-table";
import LoupeIcon from '@material-ui/icons/Loupe';

const FManPayItm = ({ data, AddStation }) => {
  
  return (
    <>
      <MaterialTable
        title="Available Job Pay Items"
        columns={[
          { title: "Pay Item", field: "payItmCode" },
          { title: "Description", field: "payItmDesc" },
          { title: "Unit", field: "unit", },
          { title: "Quantity", field: "quantity", type: "numeric" },
          { title: "Price", field: "unitPrice", type: "currency" },
        ]}
        data={data}
        options={{
          filtering: false,
          //grouping: true,
          // selection:true,
          // showSelectAllCheckbox:false,
          pageSize: 100,
          pageSizeOptions: [25, 50, 100, 200],
          padding: "dense",
          showTitle: true,
          emptyRowsWhenPaging: false,
          //tableLayout:"fixed"
        }}
        actions={[
          {
            icon: () => <LoupeIcon style={{ fontSize: 25, color: "#ff5722" }} />, //,color: "#fb8c00"             
            tooltip: "Add Station Item",
            onClick: (event, rowData) => AddStation(rowData),
          },
        ]}
        // onSelectionChange={(rows) =>  alert('You selected ' + rows.length + ' rows')}
        localization={{
          header: {
            actions: "",
          },
        }}
      />
    </>
  );
};

export default FManPayItm;
