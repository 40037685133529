import { Checkbox, FormControl, Grid, TextField } from "@material-ui/core";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import { apiService } from "../../services/api.service";
import ReportFooter from "./ReportFooter";
const useStyles = makeStyles({
  root: {
    minWidth: 275,
    margin: "0.75rem 0.75rem",
    //'box-shadowhadow': 'none',
  },
  formControl: {
    minWidth: 160,
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
  },
  pos: {
    marginBottom: 12,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
});

export default function PayItmCompRpt() {
  const classes = useStyles();
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;  
  const [payItems, setPayitems] = useState([]);
  const [selectedpayItems, setSelectedPayitems] = useState([]);
  useEffect(() => {
    apiService.getContent("jpi/used").then((edata) => {
      setPayitems(edata);
    });
    
  }, []);
  const runReport = (event, rptType) => {
    if (selectedpayItems) {
      let res = selectedpayItems.map((x) => x.payItmId).toString();
      console.log(res + " ---IDs");
      var qrystr = "payitmcomplete&format=" + rptType + "&payitem=" + res;
      var fileName = "SelectedPayitems" + "." + rptType;
      apiService.getReport(qrystr, fileName);
    } else {
      console.log("Please select a ---Job");
    }
  };
 
  return (
    <>
      <CardContent>
        <Typography className={classes.title} gutterBottom>
          Pay Items to Complete Jobs
        </Typography>
        <Typography variant="h5" component="h2"></Typography>
        <Typography className={classes.pos} color="textSecondary">
          Pay item to complete all active Jobs
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormControl
              className={classes.formControl}
              fullWidth={true}
              margin={"normal"}
              size={"medium"}
            >
              <Autocomplete
                multiple
                id="checkboxes-tags"
                options={payItems}
                getOptionLabel={(option) => `${option.payItmCode} - ${option.payItmDesc}`}
                limitTags={4}
                // value={myTempVal}
                disableCloseOnSelect
                onChange={(event, newValue) => {
                  setSelectedPayitems(newValue);
                }}
                //getOptionLabel={(option) => option}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.payItmCode} - {option.payItmDesc}
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Payitem"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <ReportFooter sumbit={runReport} />
      </CardActions>
    </>
    //</Card>
  );
}

//Backup Code..If needed in future..

  /*  Multiselect JOb Drop down */


  /* <Autocomplete 
                //multiple
                id="checkboxes-tags"
                options={payItems}
                // limitTags={3}
                // value={myTempVal}
                disableCloseOnSelect
                onChange={(event, newValue) => {
                  setSelectedJob(newValue);
                  //console.log(newValue);
                }}
                getOptionLabel={(option) => option}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option}
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Jobs"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                  />
                )}
              /> */


//

  /* <Grid item xs={12}>
            <FormControl fullWidth={true} className={classes.formControl}>
            <InputLabel id="select-label">Payitem Type</InputLabel>
            <Select
              labelId="mutiple-chip-label"
              id="mutiple-chip"
              multiple
              value={payItmType}
              onChange={handleChange}
              input={<Input id="select-multiple-chip" />}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={value}
                      className={classes.chip}
                    />
                  ))}
                </div>
              )}
              //  MenuProps={MenuProps}
            >
              {jobtype}
            </Select>
            
          </FormControl>
        </Grid> */

