import DateFnsUtils from "@date-io/date-fns";
import {
  Backdrop,
  Button,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormLabel,
  Grid,
  Grow,
  Paper,
  Radio,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { deepOrange, lime, red } from "@material-ui/core/colors";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import BuildIcon from "@material-ui/icons/BuildRounded";
import CancelIcon from "@material-ui/icons/Cancel";
import CategoryIcon from "@material-ui/icons/CategoryRounded";
import ListAltIcon from "@material-ui/icons/ListAltRounded";
import SaveIcon from "@material-ui/icons/Save";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Field, Form, Formik, validateYupSchema, yupToFormErrors } from "formik";
import { RadioGroup, TextField as MTextField } from 'formik-material-ui';
import { KeyboardDatePicker } from "material-ui-formik-components/KeyboardDatePicker";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import SwipeableViews from "react-swipeable-views";
import * as Yup from "yup";
import { apiService } from "../../services/api.service";
import FkInputFld from "../forms/formik-custom-fields/FkInputFld";
import FManJobInfo from "./FManJobInfo";
import FManPayItm from "./FManPayItm";
import FMStationItm from "./FManStationItm";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(1),
  },
  demo2: {
    backgroundColor: "#4cc8ff", //TopBackGround Color
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  projectheading: {
    fontWeight: 500,
    letterSpacing: 0.5,
    fontSize: 17,
  },
  divider: {
    height: 4,
    backgroundImage: "linear-gradient(90deg, #00b0ff, #00C853)",
    width: "100%",
    marginTop: 15,
  },
  divider2: {
    marginTop: 15,
    marginBottom: 15,
  },
  headcontainer: {
    margin: "15px 0%",
  },
  fullwidthform: {
    width: "100%",
  },
  fullwidthpaper: {
    width: "100%",
    "padding-top": "15px",
  },
  formFooter: {
    padding: "15px 18px",
    background: "#efefef",
    "margin-top": "25px",
  },
  button: {
    color: "white",
  },
  container: {
    display: "flex",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  formControl: {
    padding: "0px 20px",
  },
  formControlTxt: {
    padding: "0px 20px",
    "& label": {
      padding: "0px 20px",
    },
  },
  formControlD: {
    padding: "0px 20px",
  },
  helpText: {
    padding: "0px 20px",
    color: "red"
  },
  formControlLvL: {
    padding: "0px 25px",
    minWidth: 200,
    "& label": {
      left: 25,
    },
  },
  rdglvl: {
    padding: "15px 20px 0px 20px",
  },
  rdgControl: {
    padding: "0px 20px",
  },
  signlogo: {
    width: "200px",
    height: "135px",
    padding: "0px 0px",
  },
}));
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#4cc8ff",
    color: theme.palette.common.black,
    fontSize: 13,
  },
  body: {
    fontSize: 16,
    fontWeight: 600,
  },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.common.white,
    },
  },
}))(TableRow);
const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 60,
      width: "100%",
      backgroundColor: "#fb8c00",
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    color: "#212121",
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    "&:focus": {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
const scrollToRef = (ref) => {
  window.scrollTo(0, ref ? ref.current.offsetTop : 0);
  //console.log(ref);
};

function FManJobDetails({ match }) {
  const classes = useStyles();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const formRef = useRef();
  const stationFormRef = useRef();
  const formdivRef = useRef(null);
  const formScroll = () => scrollToRef(formdivRef);
  //const topRef = useRef(null)
  const topScroll = () => scrollToRef();

  //const [data, setData] = useState([]);

  const [imgOpen, setImgOpen] = useState(false);
  const [btnText, setbtnText] = useState("Add");
  const [lineItem, setLineItem] = useState([]);
  const [contr, setContr] = useState([]);
  const [signs, setSigns] = useState([]);
  const [job, setJob] = useState({});
  const [isEdit, setisEdit] = useState(false);
  const [disabled, setdisabled] = useState(true);
  const [stationItem, setStationItem] = useState([]);
  const [stations, setStations] = useState([]);
  const [jobDetails, setJobDetails] = useState([]);
  const [s3Files, setS3Files] = useState([]);
  const [showForm, setShowForm] = React.useState(false);
  const [showStationForm, setShowStationForm] = React.useState(false);

  useEffect(() => {
    console.log(match);
    let reqestp =
      typeof match !== "undefined" && typeof match.params.id !== "undefined"
        ? match.params.id
        : "5";

    Promise.all([
      apiService.getContent("dp/page?jobid=" + reqestp),
      //apiService.getContent("job/page?jobid=" + reqestp),
      apiService.getContent("job/foremanjob?jobid=" + reqestp),
    ])
      .then((edata) => {
        console.log(edata);
        setJob(edata[0].job);
        setLineItem(edata[0].jobPayItms);
        setContr(edata[0].contractors);
        setSigns(edata[0].signs);
        calcStationItem(edata[0].piStations, edata[0].jobPayItms);
        setStations(edata[0].stations);
        setJobDetails(edata[1].job);
        setS3Files(edata[1].s3files);
        setBackDrop(false); //Loading Gif...
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  function calcStationItem(piStations, jobPayItms) {
    var pitmst = [];
    //let ss = stationItem.find((x) => x.stationPi == value);
    piStations.forEach((pist) => {
      let pi = getPi(pist, jobPayItms);
      let expand = !(pist.dlyProdItm === null);
      let complt = pist.dlyProdItm ? pist.dlyProdItm.dpiStatus == 17 : false;
      if (pi)
        pitmst.push({
          ...pist,
          payItmDesc: pi.payItmDesc,
          PayItmType: pi.typeDesc,
          complete: complt,
          expandable: expand,
          npiItem: pi.nonProd,
          npiDesc: pi.nonProdDesc,
        });
    });
    setStationItem(pitmst);
  }
  function getPi(ss, jobPayItms) {
    var pi = jobPayItms.find((x) => x.jobPayitmId == ss.jobPayitm);
    return pi;
  }
  const [value, setValue] = React.useState(0);
  const [backDrop, setBackDrop] = React.useState(true);
  const [initialValues, setinitialValues] = useState({
    payItmHasSupport: false,
    payItmHasElevation: false,
    payItemHasPost: false,
    payItmHasApproach: false,
    payItmHasMeasurements: false,
    payItmHasNpi: false,
    instNpi: "",
    instQty: "",
    instDate: new Date(),
    notes: "",
    grdrlPost: "Steel",
    grdrlPostLen: "",
    number_of_supports: "",
    chrgSuppLeft: "",
    chrgSuppRight: "",
    certSuppLeft: "",
    certSuppRight: "",
    fdnElLeft: "",
    fdnElRight: "",
    approachType: "MSKT",
    isComplete: false,
    sign_image: "",
  });
  const [initialStationValues, setinitialStationValues] = useState({
    stationName: "",
    payItemCode: "",
    payitemDesc: "",
    quantity: "",
    quantityUnit: "",
    npi: "",
    npiQuantity: "",
    intNotes: "",
    number_of_supports: "",
    instDate: new Date(),
    //New Fields Added need maping to DB-a-
    grdrlPost: "Steel",
    grdrlPostLen: "",
    chrgSuppLeft: "",
    chrgSuppRight: "",
    certSuppLeft: "",
    certSuppRight: "",
    fdnElLeft: "",
    fdnElRight: "",
    approachType: "MSKT",
    //---------------------------
    payItmHasSupport: false,
    payItmHasElevation: false,
    payItemHasPost: false,
    payItmHasApproach: false,
    stRange: null,
    endRange: null,
    isComplete: false
  });
  const jobStationList = stations.map((ptype) => ({
    value: ptype.stationId,
    label: ptype.stationName,
  }));
  // const signsList = signs.map((ptype) => ({
  //   value: ptype.snId + ":" + ptype.snFile,
  //   label: ptype.snCode + ":" + ptype.snDesc,
  // }));
  const contractors = contr.map((ptype) => ({
    value: ptype.bpId,
    label: ptype.name,
  }));
  const handleBackDropClose = () => {
    setBackDrop(false);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };
  function createHeaderInfo(
    sno,
    payItm,
    Qty,
    used,
    unit,
    npiQty,
    npiItm,
    type
  ) {
    return { sno, payItm, Qty, used, unit, npiQty, npiItm, type };
  }

  //Add New Station Code
  const AddStation = (payItm) => {
    //console.log(payItm);
    let payItmType = payItm.payItmClass;
    // console.log(row.original);
    let HasNpi = payItm.nonProd != null ? true : false;
    let hasSupport = false;
    let hasElevation = false;
    let isSignPayItm = false;
    let hasPost = false;
    let hasApproach = false;
    let isPartial = true;
    // if (payItm.typeDesc === "Sign") {
    //   isSignPayItm = true;
    //   if (payItmType.some((e) => e.payitmClass === "Support")) {
    //     hasSupport = true;
    //   }
    //   if (payItmType.some((e) => e.payitmClass === "Measurements")) {
    //     hasMeasurements = true;
    //   }
    // }
    if (payItm.typeDesc === "Sign") {
      isSignPayItm = true;
      if (payItmType.some((e) => e.payitmClass === "Support")) {
        hasSupport = true;
      }
      if (payItmType.some((e) => e.payitmClass === "Elevation")) {
        hasElevation = true;
      }
      isPartial = false;
    } else if (payItm.typeDesc === "Guardrail") {
      if (payItmType.some((e) => e.payitmClass === "Post")) {
        hasPost = true;
      }
      if (payItmType.some((e) => e.payitmClass === "Approach")) {
        hasApproach = true;
      }
    }
    if (HasNpi) {
      isPartial = true;
    }
    setinitialStationValues({
      //...initialValues,
      ...stationFormRef.current.values,
      payItemCode: payItm.payItmCode,
      payitemDesc: payItm.payItmDesc,
      quantityUnit: payItm.unit,
      npi: payItm.nonProd,
      payItmHasSupport: hasSupport,
      payItmHasElevation: hasElevation,
      payItemHasPost: hasPost,
      payItmHasApproach: hasApproach,
      isComplete: !isPartial,
      payItmIsSign: isSignPayItm,
    });
    formScroll();
    setShowStationForm(true);
  };

  function postStationInfo(values) {
    console.log(values);
    let details = stations.find((x) => x.stationName === values.stationName);
    let stationId = typeof details !== "undefined" ? details.stationId : "0";
    let jobPayitm = lineItem.find((y) => y.payItmCode === values.payItemCode); //TODO Chk if used in Server..
    let submitvals = {
      ...values,
      stationId: stationId,
      job: job.jobId,
      jobPayitm: jobPayitm.jobPayitmId,
      partial: !values.isComplete,
    };
    //alert(JSON.stringify(submitvals, null, 2));
    //console.log(JSON.stringify(submitvals)); //POst Data to DB..
    apiService
      .addContent("jsi/foreman/", submitvals)
      .then((response) => {
        enqueueSnackbar("Station Created Successfully", { variant: "success" });
        topScroll();
        refreshTable();
        setShowStationForm(false); //Close the form
      })
      .catch((error) => {
        enqueueSnackbar(
          "There is an error in saving station payitem:" + error,
          { variant: "error" }
        );
        console.log("error: " + error);
      });
  }
  //end Add new Station..
  const [headerData, setHeaderData] = useState([]);
  const [stationPI, setStationPI] = useState([]);
  const OnStationSelect = (value) => {
    formScroll();
    setShowForm(true);
    setStationPI(value);
    let ss = stationItem.find((x) => x.stationPi == value);
    let pi = lineItem.find((x) => x.jobPayitmId == ss.jobPayitm);
    //console.log(pi);
    //Build Header Data
    setHeaderData([
      createHeaderInfo(
        ss.stationName,
        ss.payItmCode + "-" + pi.payItmDesc,
        ss.quantity,
        ss.used,
        pi.unit,
        ss.npiQuantity,
        pi.nonProd != null ? pi.nonProd + "-" + pi.nonProdDesc : "",
        pi.typeDesc
      ),
    ]);
    //Decide Input form fields..
    //need  to assign values back to formik  and then decide..
    let payItmType = pi.payItmClass;
    // console.log(payItmType);
    // console.log(pi.typeDesc);
    let HasNpi = pi.nonProd != null ? true : false;
    let HasSupport = false;
    let HasElevation = false;
    let HasMeasurements = false;
    let HasPost = false;
    let HasApproach = false;
    let isPartial = true;
    let stR = null;
    let endR = null;
    if (pi.typeDesc === "Sign") {
      if (payItmType.some((e) => e.payitmClass === "Support")) {
        HasSupport = true;
        stR = pi.range?.split("|")[0];
        endR = pi.range?.split("|")[1];
      }
      if (payItmType.some((e) => e.payitmClass === "Elevation")) {
        HasElevation = true;
      }
      if (payItmType.some((e) => e.payitmClass === "Measurements")) {
        HasMeasurements = true;
      }
      isPartial = false;
    } else if (pi.typeDesc === "Guardrail") {
      //console.log("Innn");
      if (payItmType.some((e) => e.payitmClass === "Post")) {
        HasPost = true;
      }
      if (payItmType.some((e) => e.payitmClass === "Approach")) {
        HasApproach = true;
      }
    }
    if (HasNpi) {
      isPartial = true;
    }
    setinitialValues({
      //...initialValues,
      ...formRef.current.values,
      payItmHasSupport: HasSupport,
      payItmHasElevation: HasElevation,
      payItemHasPost: HasPost,
      payItmHasApproach: HasApproach,
      payItmHasMeasurements: HasMeasurements,
      payItmHasNpi: HasNpi,
      isComplete: !isPartial,
      number_of_supports: ss.supports ? String(ss.supports) : "",
      sign_image: ss.snFile,
      endRange: endR,
      stRange: stR,
    });
    set_values({
      chrgSuppLeft: "",
      chrgSuppRight: "",
    });
  };
  const chrgSupp_handler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    const newValues = {
      ...values,
      [name]: value,
    };
    set_values(newValues);
    // Calling the method to sum the value
    calc_total(newValues);
  };
  const [values, set_values] = useState({
    chrgSuppLeft: "",
    chrgSuppRight: "",
  });
  const calc_total = (newValues) => {
    const { chrgSuppLeft, chrgSuppRight } = newValues;
    const newTotal =
      (chrgSuppLeft ? parseFloat(chrgSuppLeft) : 0) +
      (chrgSuppRight ? parseFloat(chrgSuppRight) : 0);
    setinitialValues({
      //...initialValues,
      ...formRef.current.values,
      chrgSuppLeft: chrgSuppLeft,
      chrgSuppRight: chrgSuppRight,
      instQty: newTotal,
    });
  };

  function refreshTable() {
    apiService.getContent("dp/page?jobid=" + job.jobId).then((edata) => {
      //        setJob(edata.job);
      setLineItem(edata.jobPayItms);
      //        setContr(edata.contractors);
      //        setSigns(edata.signs);
      calcStationItem(edata.piStations, edata.jobPayItms);
      setStations(edata.stations);
      setBackDrop(false); //Loading Gif...
    });
  }
  function postDlyprodItem(values) {
    let submitvals = {
      ...values,
      stationPi: stationPI,
      instBy: "user",
      partial: !values.isComplete,
    };
    console.log(JSON.stringify(submitvals)); //POst Data to DB..
    apiService
      .addContent("dp/", submitvals)
      .then((response) => {
        enqueueSnackbar("Created Successfully", { variant: "success" });
        refreshTable();
        topScroll();
        setShowForm(false);
      })
      .catch((error) => {
        enqueueSnackbar(
          "There is an error in saving daily prod item:" + error,
          { variant: "error" }
        );
        console.log("error: " + error);
      });
  }
  const inputProps = {
    step: 0.01,
  };
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container direction="row">
        <Grid
          className={classes.headcontainer}
          container
          item
          xs={12}
          md={12}
        ></Grid>
        <Grid container direction="row">
          <div className={classes.root}>
            <div className={classes.demo2}>
              <StyledTabs
                value={value}
                onChange={handleChange}
                variant="fullWidth"
                aria-label="tabs"
              >
                <StyledTab
                  label={"STATION DETAILS- " + job.jobCode}
                  icon={
                    <CategoryIcon
                      style={{
                        color: deepOrange[500],
                        fontSize: 30,
                      }}
                    />
                  }
                  {...a11yProps(0)}
                />
                <StyledTab
                  label="MANUAL ENTRY"
                  icon={
                    <ListAltIcon style={{ color: lime[500], fontSize: 30 }} />
                  }
                  {...a11yProps(1)}
                />
                <StyledTab
                  label="JOB DETAILS/PLANS"
                  icon={
                    <BuildIcon style={{ color: "#ef5350", fontSize: 30 }} />
                  }
                  {...a11yProps(2)}
                />
              </StyledTabs>

              <Typography className={classes.padding} />
            </div>
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel value={value} index={0} dir={theme.direction}>
                <FMStationItm
                  data={stationItem}
                  OnStationSelect={OnStationSelect}
                  job={job}
                />
                <Divider variant="middle" className={classes.divider2} />
                <Grow
                  in={showForm}
                  style={{ transformOrigin: "0 0 0" }}
                  {...(showForm ? { timeout: 500 } : {})}
                >
                  <div ref={formdivRef}>
                    {/* {showForm && ( */}

                    {/* Display Station Details.. 
                    1.Station Number
                    2.Pay Item Code/ Description..
                    3.Plan Quantity
                    4.Quantity Unit
                    5.Non-Production Item **if Avlaible
                    6. NON-Prod Quantity **if Avlaible
                    */}

                    <TableContainer component={Paper}>
                      <Table
                        className={classes.table}
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell align="center">
                              Station Number
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              Pay Item Code / Description
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              Plan Quantity
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              Installed
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              Unit
                            </StyledTableCell>
                            {/* <TableCell align="right">NPI</TableCell> */}
                            <StyledTableCell align="center">
                              NPI Quantity
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              NPI Item
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              Type
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {headerData.map((row) => (
                            <StyledTableRow key={123}>
                              <StyledTableCell align="center">
                                {row.sno}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {row.payItm}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {row.Qty}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {row.used}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {row.unit}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {row.npiQty}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {row.npiItm}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {row.type}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Grid className={classes.headcontainer} container>
                      <Formik
                        enableReinitialize
                        initialValues={initialValues}
                        validationSchema={Yup.object().shape({
                          payItmHasNpi: Yup.boolean(),
                          instQty: Yup.number().when('payItmHasNpi', {
                            is: false,
                            then: Yup.number().required(
                              "Installed quantity is required"
                            )
                          }),
                          certSuppLeft: Yup.number()
                            .when(
                              "number_of_supports",
                              {
                                is: (val) => val === "1" || val === "2",
                                then: Yup.number()
                                  .required("This field is required")
                                  .min(initialValues.stRange, "Value cant be less than "+initialValues.stRange)
                                  .max(initialValues.endRange, "Value cant be greater than "+initialValues.endRange),
                              }
                            ),
                          certSuppRight: Yup.number().when(
                            "number_of_supports",
                            {
                              is: "2",
                              then: Yup.number()
                                .required("This field is required")
                                .min(initialValues.stRange, "Value cant be less than "+initialValues.stRange)
                                .max(initialValues.endRange, "Value cant be greater than "+initialValues.endRange),
                            }
                          ),
                          chrgSuppLeft: Yup.number().when(
                            "number_of_supports",
                            {
                              is: (val) => val === "1" || val === "2",
                              then: Yup.number().required(
                                "This field is required"
                              ),
                            }
                          ),
                          chrgSuppRight: Yup.number().when(
                            "number_of_supports",
                            {
                              is: "2",
                              then: Yup.number().required(
                                "This field is required"
                              ),
                            }
                          ),
                        })}
                        innerRef={formRef}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                          postDlyprodItem(values);
                          setSubmitting(false);
                          resetForm({
                            values: {
                              payItmHasSupport: false,
                              payItmHasElevation: false,
                              payItemHasPost: false,
                              payItmHasApproach: false,
                              payItmHasMeasurements: false,
                              payItmHasNpi: false,
                              instNpi: "",
                              instQty: "",
                              instDate: new Date(),
                              notes: "",
                              grdrlPost: "Steel",
                              grdrlPostLen: "",
                              number_of_supports: "",
                              chrgSuppLeft: "",
                              chrgSuppRight: "",
                              certSuppLeft: "",
                              certSuppRight: "",
                              fdnElLeft: "",
                              fdnElRight: "",
                              approachType: "MSKT",
                              isComplete: false,
                            },
                          });
                        }}
                      >
                        {({
                          errors,
                          status,
                          touched,
                          isSubmitting,
                          values,
                          handleBlur,
                          setFieldValue,
                          setTouched,
                        }) => (
                          <Paper className={classes.fullwidthpaper}>
                            <Form className={classes.fullwidthform}>
                              {/* Content Goes here */}
                              <Grid container spacing={0}>
                                <Grid item xs={12} sm={6}>
                                  <FkInputFld
                                    name="instQty"
                                    label="Installed Quantity"
                                    controlClass={classes.formControl}
                                  // disabled={values.payItmHasNpi}
                                  />
                                </Grid>

                                {values.payItmHasNpi && (
                                  <Grid item xs={12} sm={6}>
                                    <FkInputFld
                                      name="instNpi"
                                      label="Installed NPI Quantity"
                                      controlClass={classes.formControl}
                                    />
                                  </Grid>
                                )}
                                <Grid item xs={12} sm={6}>
                                  <Field
                                    name="instDate"
                                    component={KeyboardDatePicker}
                                    label="Install Date"
                                    format="MM/dd/yyyy"
                                    size="small"
                                    className={classes.formControlD}
                                  //lableclass={classes.lvlResize}
                                  />
                                </Grid>
                              </Grid>
                              {values.payItmHasSupport && (
                                <>
                                  <Grid container>
                                    <Grid item xs={12} sm={6}>
                                      <FormLabel
                                        component="legend"
                                        className={classes.rdglvl}
                                        margin={"normal"}
                                      >
                                        Number of Supports
                                      </FormLabel>
                                      <Field
                                        component={RadioGroup}
                                        name="number_of_supports"
                                        row
                                        className={classes.rdgControl}
                                        onChange={(_, value) => {
                                          console.log(value);
                                          // setFieldValue("showGuardrail", false);
                                          // setFieldValue("showSign", false);
                                          // if (value.props.children === "Sign") {
                                          //   setFieldValue("showSign", true);
                                          // } else if (value.props.children === "Guardrail") {
                                          //   setFieldValue("showGuardrail", true);
                                          // }
                                          setFieldValue(
                                            "number_of_supports",
                                            value
                                          );
                                        }}
                                      >
                                        <FormControlLabel
                                          value="1"
                                          control={
                                            <Radio disabled={isSubmitting} />
                                          }
                                          label="1"
                                          disabled={isSubmitting}
                                        />
                                        <FormControlLabel
                                          value="2"
                                          control={
                                            <Radio disabled={isSubmitting} />
                                          }
                                          label="2"
                                          disabled={isSubmitting}
                                        />
                                      </Field>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                      <img
                                        src={
                                          typeof values.sign_image !== "undefined"
                                            ? "../signs/" + values.sign_image
                                            : "../signs/notfound.png"
                                        }
                                        className={classes.signlogo}
                                        onClick={() => {
                                          setImgOpen(true);
                                        }}
                                      />
                                    </Grid>
                                    <div>
                                      {imgOpen && (
                                        <Lightbox
                                          mainSrc={"../signs/" + values.sign_image}
                                          onCloseRequest={() =>
                                            setImgOpen(false)
                                          }
                                        />
                                      )}
                                    </div>
                                  </Grid>
                                  <Grid container>
                                    <Grid item xs={12} sm={6}>
                                      {values.number_of_supports && (
                                        <Field
                                          component={MTextField}
                                          fullWidth={true}
                                          type={"number"}
                                          label="Charge Support Left"
                                          name="chrgSuppLeft"
                                          margin={"normal"}
                                          size={"medium"}
                                          className={classes.formControlTxt}
                                          InputProps={{
                                            onChange: chrgSupp_handler,
                                          }}
                                        />
                                      )}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                      {values.number_of_supports === "2" && (
                                        <Field
                                          component={MTextField}
                                          fullWidth={true}
                                          type={"number"}
                                          label="Charge Support Right"
                                          name="chrgSuppRight"
                                          margin={"normal"}
                                          size={"medium"}
                                          className={classes.formControlTxt}
                                          InputProps={{
                                            onChange: chrgSupp_handler,
                                          }}
                                        />
                                      )}
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={0}>
                                    <Grid item xs={12} sm={6}>
                                      {values.number_of_supports && (
                                        <div>
                                          <FkInputFld
                                            name="certSuppLeft"
                                            label="Certify Support Left"
                                            placeholder={`Must be between ${values.stRange} and ${values.endRange}`}
                                            controlClass={classes.formControl}
                                          />
                                          {/* <div className={classes.helpText}>{`Please enter a value between ${values.stRange} and ${values.endRange}`}</div> */}
                                        </div>
                                      )}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                      {values.number_of_supports === "2" && (
                                        <div>
                                          <FkInputFld
                                            name="certSuppRight"
                                            label="Certify Support Right"
                                            //helperText="Must be between 12 to 18"
                                            placeholder={`Must be between ${values.stRange} and ${values.endRange}`}
                                            controlClass={classes.formControl}
                                          />
                                          {/* <div className={classes.helpText}>{`Please enter a value between ${values.stRange} and ${values.endRange}`}</div> */}
                                        </div>
                                      )}
                                    </Grid>
                                  </Grid>
                                </>
                              )}
                              {values.payItmHasElevation && (
                                <Grid container spacing={1}>
                                  <Grid item xs={12} sm={6}>
                                    <FkInputFld
                                      name="fdnElLeft"
                                      label="Foundation Elevation Left"
                                      controlClass={classes.formControl}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <FkInputFld
                                      name="fdnElRight"
                                      label="Foundation Elevation Right"
                                      controlClass={classes.formControl}
                                    />
                                  </Grid>
                                </Grid>
                              )}
                              {values.payItemHasPost && (
                                <Grid container spacing={1}>
                                  <Grid container spacing={1}>
                                    <Grid item xs={12} sm={6}>
                                      <FormLabel
                                        component="legend"
                                        className={classes.rdglvl}
                                      >
                                        Guardrail Post
                                      </FormLabel>
                                      <Field
                                        component={RadioGroup}
                                        name="grdrlPost"
                                        row
                                        className={classes.rdgControl}
                                      >
                                        <FormControlLabel
                                          value="Steel"
                                          control={<Radio />}
                                          label="Steel"
                                        />
                                        <FormControlLabel
                                          value="Wood"
                                          control={<Radio />}
                                          label="Wood"
                                        />
                                      </Field>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                      <FormLabel
                                        component="legend"
                                        className={classes.rdglvl}
                                      >
                                        Guardrail Post Length (ft)
                                      </FormLabel>

                                      <Field
                                        component={RadioGroup}
                                        name="grdrlPostLen"
                                        row
                                        className={classes.rdgControl}
                                      >
                                        <FormControlLabel
                                          value="6"
                                          control={<Radio />}
                                          label="6"
                                        />
                                        <FormControlLabel
                                          value="7"
                                          control={<Radio />}
                                          label="7"
                                        />
                                        <FormControlLabel
                                          value="8"
                                          control={<Radio />}
                                          label="8"
                                        />
                                        <FormControlLabel
                                          value="9"
                                          control={<Radio />}
                                          label="9"
                                        />
                                      </Field>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              )}
                              {values.payItmHasApproach && (
                                <Grid container spacing={0}>
                                  <Grid container spacing={0}>
                                    <Grid item xs={12} sm={6}>
                                      <FormLabel
                                        component="legend"
                                        className={classes.rdglvl}
                                      >
                                        Approach Type
                                      </FormLabel>

                                      <Field
                                        component={RadioGroup}
                                        name="approachType"
                                        row
                                        className={classes.rdgControl}
                                      >
                                        <FormControlLabel
                                          value="MSKT"
                                          control={<Radio />}
                                          label="MSKT"
                                        />
                                        <FormControlLabel
                                          value="Soft Stop"
                                          control={<Radio />}
                                          label="Soft Stop"
                                        />
                                      </Field>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              )}

                              <Grid container spacing={0}>
                                <Grid item xs={12} sm={6}>
                                  <FkInputFld
                                    name="notes"
                                    label="Notes"
                                    controlClass={classes.formControl}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        checked={values.isComplete}
                                        onChange={(_, value) => {
                                          console.log(value);
                                          setFieldValue("isComplete", value);
                                        }}
                                        name="isComplete"
                                        color="primary"
                                      />
                                    }
                                    label="Is Station Completed"
                                  />
                                </Grid>
                              </Grid>
                              <Grid container className={classes.formFooter}>
                                <Grid item xs={6} md={6}>
                                  <Button
                                    variant="contained"
                                    style={{ background: "red" }}
                                    className={classes.button}
                                    startIcon={<CancelIcon />}
                                    onClick={() => {
                                      topScroll();
                                      setShowForm(false);
                                    }}
                                  >
                                    Cancel
                                  </Button>
                                </Grid>
                                <Grid
                                  item
                                  xs={6}
                                  md={6}
                                  style={{ textAlign: "right" }}
                                >
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    startIcon={<SaveIcon />}
                                    type="submit"
                                  >
                                    Submit
                                  </Button>
                                </Grid>
                              </Grid>
                            </Form>
                          </Paper>
                        )}
                      </Formik>
                    </Grid>
                    {/* )} */}
                  </div>
                </Grow>
              </TabPanel>
              <TabPanel value={value} index={1} dir={theme.direction}>
                {/* <Grid container alignItems="flex-end" direction="row-reverse">
                  <Button color="primary" startIcon={<AddIcon />}>
                    Add New Station
                  </Button>
                </Grid> */}
                <FManPayItm data={lineItem} AddStation={AddStation} />
                <Divider variant="middle" className={classes.divider2} />
                <Grow
                  in={showStationForm}
                  style={{ transformOrigin: "0 0 0" }}
                  {...(showForm ? { timeout: 500 } : {})}
                >
                  <div ref={formdivRef}>
                    <Grid className={classes.headcontainer} container>
                      <Typography gutterBottom variant="h5" component="div">
                        Add New Station
                      </Typography>
                      <Formik
                        enableReinitialize
                        initialValues={initialStationValues}
                        validationSchema={Yup.object().shape({
                          stationName: Yup.string().required("Station Name is Required"),
                          // payItemCode: Yup.string().required(),
                          // quantity: Yup.number()
                          //   .required()
                          //   .test(
                          //     "valid quantity",
                          //     "Assigned quantity is higher than available " +
                          //       (availQuant - usedQuant),
                          //     function (value) {
                          //       let usedq = value + usedQuant;
                          //       return usedq <= availQuant && value > 0;
                          //     }
                          //   ),
                        })}
                        innerRef={stationFormRef}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                          postStationInfo(values);
                          setSubmitting(false);
                        }}
                      >
                        {({
                          errors,
                          status,
                          touched,
                          isSubmitting,
                          values,
                          handleBlur,
                          setFieldValue,
                          setTouched,
                        }) => (
                          <Paper className={classes.fullwidthpaper}>
                            <Form className={classes.fullwidthform}>
                              <Grid container spacing={1}>
                                <Grid container spacing={1}>
                                  <Grid item xs={12} sm={6}>
                                    <Field
                                      name="stationName"
                                      options={jobStationList}
                                      component={Autocomplete}
                                      //disableListWrap
                                      freeSolo
                                      getOptionLabel={(option) => option.label}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Job Station"
                                          error={Boolean(touched.stationName && errors.stationName)}
                                          helperText={touched.stationName && errors.stationName}
                                          margin="normal"
                                          className={classes.formControl}
                                          onChange={(event) => {
                                            //console.log(event.target.value);
                                            setFieldValue(
                                              "stationName",
                                              event.target.value
                                            );
                                          }}
                                        />
                                      )}
                                      onChange={(_, value) => {
                                        console.log(value);
                                        if (value) {
                                          setFieldValue(
                                            "stationName",
                                            value.label
                                          );
                                        } else {
                                          setFieldValue("stationName", "");
                                        }
                                      }}
                                    />
                                  </Grid>

                                  <Grid container spacing={1}>
                                    <Grid item xs={12} sm={4}>
                                      <FkInputFld
                                        name="payItemCode"
                                        label="Pay Item Code"
                                        controlClass={classes.formControl}
                                        disabled
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                      <FkInputFld
                                        name="payitemDesc"
                                        label="Pay Item Description"
                                        controlClass={classes.formControl}
                                        disabled
                                      />
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={0}>
                                    <Grid item xs={12} sm={6}>
                                      <FkInputFld
                                        name="quantity"
                                        label="Installed Quantity"
                                        controlClass={classes.formControl}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                      <FkInputFld
                                        name="quantityUnit"
                                        label="Quantity Unit"
                                        controlClass={classes.formControl}
                                        disabled
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                  <Grid item xs={12} sm={6}>
                                    <FkInputFld
                                      name="npi"
                                      label="Non Prod Item"
                                      controlClass={classes.formControl}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <FkInputFld
                                      name="npiQuantity"
                                      label="Non Prod Item Quantity"
                                      controlClass={classes.formControl}
                                    />
                                  </Grid>
                                </Grid>

                                {values.payItmHasSupport && (
                                  <Grid container spacing={1}>
                                    <Grid item xs={12} sm={6}>
                                      <FormLabel
                                        component="legend"
                                        className={classes.rdglvl}
                                        margin={"normal"}
                                      >
                                        Number of Supports
                                      </FormLabel>
                                      <Field
                                        component={RadioGroup}
                                        name="number_of_supports"
                                        row
                                        className={classes.rdgControl}
                                        onChange={(_, value) => {
                                          console.log(value);
                                          setFieldValue(
                                            "number_of_supports",
                                            value
                                          );
                                        }}
                                      >
                                        <FormControlLabel
                                          value="1"
                                          control={
                                            <Radio disabled={isSubmitting} />
                                          }
                                          label="1"
                                          disabled={isSubmitting}
                                        />
                                        <FormControlLabel
                                          value="2"
                                          control={
                                            <Radio disabled={isSubmitting} />
                                          }
                                          label="2"
                                          disabled={isSubmitting}
                                        />
                                      </Field>
                                    </Grid>
                                    <Grid container>
                                      <Grid item xs={12} sm={6}>
                                        {values.number_of_supports && (
                                          <FkInputFld
                                            name="chrgSuppLeft"
                                            label="Charge Support Left"
                                            controlClass={classes.formControl}
                                          />
                                        )}
                                      </Grid>
                                      <Grid item xs={12} sm={6}>
                                        {values.number_of_supports === "2" && (
                                          <FkInputFld
                                            name="chrgSuppRight"
                                            label="Charge Support Right"
                                            controlClass={classes.formControl}
                                          />
                                        )}
                                      </Grid>
                                    </Grid>
                                    <Grid container spacing={0}>
                                      <Grid item xs={12} sm={6}>
                                        {values.number_of_supports && (
                                          <FkInputFld
                                            name="certSuppLeft"
                                            label="Certify Support Left"
                                            controlClass={classes.formControl}
                                          />
                                        )}
                                      </Grid>
                                      <Grid item xs={12} sm={6}>
                                        {values.number_of_supports === "2" && (
                                          <FkInputFld
                                            name="certSuppRight"
                                            label="Certify Support Right"
                                            controlClass={classes.formControl}
                                          />
                                        )}
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                )}
                                {values.payItmHasElevation && (
                                  <Grid container spacing={1}>
                                    <Grid item xs={12} sm={6}>
                                      <FkInputFld
                                        name="fdnElLeft"
                                        label="Foundation Elevation Left"
                                        controlClass={classes.formControl}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                      <FkInputFld
                                        name="fdnElRight"
                                        label="Foundation Elevation Right"
                                        controlClass={classes.formControl}
                                      />
                                    </Grid>
                                  </Grid>
                                )}
                                {values.payItemHasPost && (
                                  <Grid container spacing={1}>
                                    <Grid container spacing={1}>
                                      <Grid item xs={12} sm={6}>
                                        <FormLabel
                                          component="legend"
                                          className={classes.rdglvl}
                                        >
                                          Guardrail Post
                                        </FormLabel>
                                        <Field
                                          component={RadioGroup}
                                          name="grdrlPost"
                                          row
                                          className={classes.rdgControl}
                                        >
                                          <FormControlLabel
                                            value="Steel"
                                            control={<Radio />}
                                            label="Steel"
                                          />
                                          <FormControlLabel
                                            value="Wood"
                                            control={<Radio />}
                                            label="Wood"
                                          />
                                        </Field>
                                      </Grid>

                                      <Grid item xs={12} sm={6}>
                                        <FormLabel
                                          component="legend"
                                          className={classes.rdglvl}
                                        >
                                          Guardrail Post Length (ft)
                                        </FormLabel>

                                        <Field
                                          component={RadioGroup}
                                          name="grdrlPostLen"
                                          row
                                          className={classes.rdgControl}
                                        >
                                          <FormControlLabel
                                            value="6"
                                            control={<Radio />}
                                            label="6"
                                          />
                                          <FormControlLabel
                                            value="7"
                                            control={<Radio />}
                                            label="7"
                                          />
                                          <FormControlLabel
                                            value="8"
                                            control={<Radio />}
                                            label="8"
                                          />
                                          <FormControlLabel
                                            value="9"
                                            control={<Radio />}
                                            label="9"
                                          />
                                        </Field>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                )}
                                {values.payItmHasApproach && (
                                  <Grid container spacing={0}>
                                    <Grid container spacing={0}>
                                      <Grid item xs={12} sm={6}>
                                        <FormLabel
                                          component="legend"
                                          className={classes.rdglvl}
                                        >
                                          Approach Type
                                        </FormLabel>

                                        <Field
                                          component={RadioGroup}
                                          name="approachType"
                                          row
                                          className={classes.rdgControl}
                                        >
                                          <FormControlLabel
                                            value="MSKT"
                                            control={<Radio />}
                                            label="MSKT"
                                          />
                                          <FormControlLabel
                                            value="Soft Stop"
                                            control={<Radio />}
                                            label="Soft Stop"
                                          />
                                        </Field>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                )}

                                <Grid container spacing={1}>
                                  <Grid item xs={12} sm={6}>
                                    <Field
                                      name="instDate"
                                      component={KeyboardDatePicker}
                                      label="Install Date"
                                      format="MM/dd/yyyy"
                                      size="small"
                                      className={classes.formControlLvL}
                                    //lableclass={classes.lvlResize}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <FkInputFld
                                      name="intNotes"
                                      label="External Notes"
                                      controlClass={classes.formControl}
                                    />
                                  </Grid>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        checked={values.isComplete}
                                        onChange={(_, value) => {
                                          console.log(value);
                                          setFieldValue("isComplete", value);
                                        }}
                                        name="isComplete"
                                        color="primary"
                                      />
                                    }
                                    label="Is Station Completed"
                                  />
                                </Grid>
                                <Grid container className={classes.formFooter}>
                                  <Grid item xs={6} md={6}>
                                    <Button
                                      variant="contained"
                                      style={{ background: "red" }}
                                      className={classes.button}
                                      startIcon={<CancelIcon />}
                                      onClick={() => {
                                        topScroll();
                                        setShowStationForm(false);
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={6}
                                    md={6}
                                    style={{ textAlign: "right" }}
                                  >
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      className={classes.button}
                                      startIcon={<SaveIcon />}
                                      type="submit"
                                    >
                                      Add
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Form>
                          </Paper>
                        )}
                      </Formik>
                    </Grid>
                    {/* )} */}
                  </div>
                </Grow>
              </TabPanel>
              <TabPanel value={value} index={2} dir={theme.direction}>
                <FManJobInfo jobDetails={jobDetails} s3files={s3Files} />
              </TabPanel>
            </SwipeableViews>
          </div>
        </Grid>
      </Grid>
      <Backdrop
        className={classes.backdrop}
        open={backDrop}
        onClick={handleBackDropClose}
      >
        <CircularProgress color="primary" thickness={5.6} />
      </Backdrop>
    </MuiPickersUtilsProvider>
  );
}
export default FManJobDetails;
