import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { isAfter, isBefore, parseISO } from "date-fns";
import MaterialTable from "material-table";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { apiService } from "../../services/api.service";
import CustomDatePicker from "../../helper/rangeFilter";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  fab: {
    margin: "0px",
    top: "auto",
    right: "20px",
    bottom: "20px",
    left: "auto",
    position: "fixed",
    color: "#fff",
  },
}));
const JobBoards = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const [data, setData] = useState([]); //rowData
  const { enqueueSnackbar } = useSnackbar();
  const date = new Date();
  const today = date.toLocaleDateString('en-CA');

  useEffect(() => {
    // console.log(props)
    //console.log("Loaded...")
    getJobBoard();
  }, []);

  const getJobBoard = () => {
    apiService.getContent("jb").then((edata) => {
      //jb  -- POST with JSON  without JobBoardID...
      console.log("Ajax DOne");
      setData(edata);
    });
  };
  return (
    <>
      <MaterialTable
        title="Job Boards"
        columns={[{
          title: "Job / Work Order",
          field: "job",
          defaultSort: "asc",
          cellStyle: { color: "blue", fontWeight: 600 },
        },
        { title: "Division", field: "division" },
        { title: "Status", field: "status", hidden: true },
        { title: "jobBoardsId", field: "jobBoardsId", hidden: true },
        { title: "Location", field: "location" },
        {
          title: "Materials",
          field: "materials",
          filtering: false,
          editComponent: (props) => (
            <TextField
              id="standard-multiline-flexible"
              label="Materials"
              multiline
              maxRows={4}
              value={props.value}
              onChange={(e) => props.onChange(e.target.value)}
            />
          ),
        },
        {
          title: "Entered Date",
          field: "enterDate",
          type: "date",
          cellStyle: { color: "#e407a0", fontWeight: 600 },
          editComponent: (props) => (
            <input
              type="date"
              value={props.value}
              defaultValue={today}
              onChange={(e) => props.onChange(e.target.value)}
            />
          ),
          filterComponent: (props) => <CustomDatePicker {...props} />,
          customFilterAndSearch: (term, rowData) => {
            return (
              !isAfter(parseISO(rowData.enterDate), term.endDate) &&
              !isBefore(parseISO(rowData.enterDate), term.startDate)
            );
          },
        },
        {
          title: "Date Needed-Start Date",
          field: "neededStart",
          type: "date",
          cellStyle: { color: "#0c09fe", fontWeight: 600 },
          editComponent: (props) => (
            <input
              type="date"
              value={props.value}
              onChange={(e) => props.onChange(e.target.value)}
            />
          ),
          filterComponent: (props) => <CustomDatePicker {...props} />,
          customFilterAndSearch: (term, rowData) => {
            return (
              !isAfter(parseISO(rowData.neededStart), term.endDate) &&
              !isBefore(parseISO(rowData.neededStart), term.startDate)
            );
          },
        },
        {
          title: "Date Needed-End Date",
          field: "neededEnd",
          type: "date",
          cellStyle: { color: "#e407a0", fontWeight: 600 },
          editComponent: (props) => (
            <input
              type="date"
              value={props.value}
              onChange={(e) => props.onChange(e.target.value)}
            />
          ),
          filterComponent: (props) => <CustomDatePicker {...props} />,
          customFilterAndSearch: (term, rowData) => {
            return (
              !isAfter(parseISO(rowData.neededEnd), term.endDate) &&
              !isBefore(parseISO(rowData.neededEnd), term.startDate)
            );
          },
        },
        {
          title: "Miss Dig-Start Date",
          field: "missdigStart",
          type: "date",
          cellStyle: { color: "#0c09fe", fontWeight: 600 },
          editComponent: (props) => (
            <input
              type="date"
              value={props.value}
              onChange={(e) => props.onChange(e.target.value)}
            />
          ),
          filterComponent: (props) => <CustomDatePicker {...props} />,
          customFilterAndSearch: (term, rowData) => {
            return (
              !isAfter(parseISO(rowData.missdigStart), term.endDate) &&
              !isBefore(parseISO(rowData.missdigStart), term.startDate)
            );
          },
        },
        {
          title: "Miss Dig-End Date",
          field: "missdigEnd",
          type: "date",
          cellStyle: { color: "#e407a0", fontWeight: 600 },
          editComponent: (props) => (
            <input
              type="date"
              value={props.value}
              onChange={(e) => props.onChange(e.target.value)}
            />
          ),
          filterComponent: (props) => <CustomDatePicker {...props} />,
          customFilterAndSearch: (term, rowData) => {
            return (
              !isAfter(parseISO(rowData.missdigEnd), term.endDate) &&
              !isBefore(parseISO(rowData.missdigEnd), term.startDate)
            );
            //return true;
          },
        },
        {
          title: "Utility Needed On Site",
          field: "utilityNeeded",
          filtering: false,
          editComponent: (props) => (
            <TextField
              id="standard-multiline-flexible"
              label="Utility Needed On Site"
              multiline
              maxRows={4}
              style={{ width: 180 }}
              value={props.value}
              onChange={(e) => props.onChange(e.target.value)}
            />
          ),
        }]}
        tableRef={ref}
        data={data}
        actions={[]}
        // components={{
        //   Body: (props) => <MTableBody ref={divRef} {...props} />
        // }}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve, reject) => {
              if (newData.missdigStart) {
                let newDat = new Date(newData.missdigStart);
                newData.missdigStart = new Date(
                  newDat.getTime() + newDat.getTimezoneOffset() * 60000
                );
              }
              if (newData.missdigEnd) {
                let newDat = new Date(newData.missdigEnd);
                newData.missdigEnd = new Date(
                  newDat.getTime() + newDat.getTimezoneOffset() * 60000
                );
              }
              if (newData.neededEnd) {
                let newDat = new Date(newData.neededEnd);
                newData.neededEnd = new Date(
                  newDat.getTime() + newDat.getTimezoneOffset() * 60000
                );
              }
              if (newData.neededStart) {
                let newDat = new Date(newData.neededStart);
                newData.neededStart = new Date(
                  newDat.getTime() + newDat.getTimezoneOffset() * 60000
                );
              }
              if (newData.enterDate) {
                let newDat = new Date(newData.enterDate);
                newData.enterDate = new Date(
                  newDat.getTime() + newDat.getTimezoneOffset() * 60000
                );
              }
              apiService
                .addContent("jb/", newData)
                .then((response) => {
                  //setData([...data, newData]);
                  getJobBoard();
                  enqueueSnackbar("New Record Added Successfully", {
                    variant: "success",
                  });
                  resolve();
                })
                .catch((error) => {
                  enqueueSnackbar(
                    "There is an error in adding new record :" + error,
                    { variant: "error" }
                  );
                  console.log("error: " + error);
                  resolve();
                });
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              //jobBoardsId
              if (newData.missdigStart) {
                let newDat = new Date(newData.missdigStart);
                newData.missdigStart = new Date(
                  newDat.getTime() + newDat.getTimezoneOffset() * 60000
                );
              }
              if (newData.missdigEnd) {
                let newDat = new Date(newData.missdigEnd);
                newData.missdigEnd = new Date(
                  newDat.getTime() + newDat.getTimezoneOffset() * 60000
                );
              }
              if (newData.neededEnd) {
                let newDat = new Date(newData.neededEnd);
                newData.neededEnd = new Date(
                  newDat.getTime() + newDat.getTimezoneOffset() * 60000
                );
              }
              if (newData.neededStart) {
                let newDat = new Date(newData.neededStart);
                newData.neededStart = new Date(
                  newDat.getTime() + newDat.getTimezoneOffset() * 60000
                );
              }
              if (newData.enterDate) {
                let newDat = new Date(newData.enterDate);
                newData.enterDate = new Date(
                  newDat.getTime() + newDat.getTimezoneOffset() * 60000
                );
              }

              apiService
                .putContent("jb/" + oldData.jobBoardsId, newData)
                .then((response) => {
                  //console.log(response.json());
                   getJobBoard();
                  // const dataUpdate = [...data];
                  // const index = oldData.tableData.id;
                  // dataUpdate[index] = newData;
                  // setData([...dataUpdate]);
                  enqueueSnackbar("Record Updated Successfully", {
                    variant: "success",
                  });
                  resolve();
                });
              // getJobBoard();
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              apiService
                .deleteObject("jb/" + oldData.jobBoardsId)
                .then((response) => {
                  //console.log(response);
                  // getJobBoard();
                  const dataDelete = [...data];
                  const index = oldData.tableData.id;
                  dataDelete.splice(index, 1);
                  setData([...dataDelete]);
                  enqueueSnackbar("Record Deleted Successfully", {
                    variant: "success",
                  });
                  resolve();
                });
            }),
        }}
        //components={{ Pagination: (props) => { return <div   />; }, }}
        options={{
          //paging: false,
          search: false,
          pageSizeOptions: [10, 50, 100, 1000],
          pageSize: 1000,
          emptyRowsWhenPaging: false,
          addRowPosition: "first",
          filtering: true,
          columnsButton: true,
          headerStyle: {
            borderBottomColor: "#4cc8ff",
            fontWeight: "600",
            borderBottomWidth: "3px",
            fontFamily: "verdana",
            position: "sticky",
            top: 0,
          }, //background: "#4cc8ff",
          maxBodyHeight: "650px",
        }}
        localization={{
          header: {
            actions: "",
          },
        }}
      />
    </>
  );
});

export default JobBoards;
