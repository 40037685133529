import { Button, FormControl, Grid } from "@material-ui/core";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CancelIcon from "@material-ui/icons/Cancel";
import SaveIcon from "@material-ui/icons/Save";
import { Field, Form, Formik } from "formik";
import { CheckboxWithLabel, TextField } from "formik-material-ui";
import { Autocomplete } from "material-ui-formik-components";
import { Select } from "material-ui-formik-components/Select";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { apiService } from "../../services/api.service";
import FkInputFld from "./formik-custom-fields/FkInputFld";
import FkSelectFld from "./formik-custom-fields/FkSelectFld";
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  headcontainer: {
    margin: "15px 0%",
  },
  button: {
    color: "white",
    fontWeight: 500,
    fontSize: 14,
    letterSpacing: 0.8,
    borderRadius: 20,
  },
  bussinessform: {
    width: "100%",
  },
  formFooter: {
    padding: "15px 25px",
    background: "#efefef",
    "margin-top": "25px",
  },
  checkboxControl: {
    padding: "0px 25px",
    "margin-top": "25px",
    "margin-left": "0px",
    color: "rgb(0,0,0,0.5)",
  },
  formControlLvL: {
    padding: theme.spacing(1),
    minWidth: 200,
    "margin-top": "0px",
    "margin-bottom": "0px",
    width: "102.5%",
    "& label": {
      left: theme.spacing(1),
    },
  },
}));

const NewPayItem = ({ match }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [contr, setContr] = useState([]);
  const [vendor, setVendor] = useState([]);
  const [baseu, setBaseu] = useState([]);
  const [reqm, setReqm] = useState([]);
  const [nonprod, setNonprod] = useState([]);
  const [jobtype, setJobType] = useState([]);
  const [prequal, setPrequal] = useState([]);
  const [payitem, setPayItem] = useState({});
  const [showAdmin, setShowAdmin] = useState(false);
  const { user: currentUser } = useSelector((state) => state.auth);
  const defPayItm = (edata) => {
    if (typeof match !== "undefined" && match.params.id !== "0") {
      setPayItem(edata);
      console.log(edata);
    } else {
      setPayItem({
        payItmId: "",
        payItmCode: "",
        payItmDesc: "",
        contractor: "1",
        prequalCode: null,
        typeId: "",
        requiredMaterial: "",
        nonProdItem: "",
        baseUnit: "",
        status: "1",
        vendor: null,
        range: null
      });
    }
  };
  useEffect(() => {
    console.log(match);
    let reqestp =
      typeof match !== "undefined" && match.params.id !== "0"
        ? match.params.id
        : "";
    apiService.getContent("pi/page?payid=" + reqestp).then((edata) => {
      setContr(edata.contractors);
      setVendor(edata.vendors);
      setBaseu(edata.baseunit);
      setReqm(edata.reqmat);
      setNonprod(edata.nonprod);
      setJobType(edata.piTypes);
      setPrequal(edata.prequals);
      defPayItm(edata.payItem);
    });
    setShowAdmin(currentUser.roles.includes("ROLE_ADMIN"));
  }, []);

  const contractors = contr.map((ptype) => ({
    value: ptype.bpId,
    label: ptype.name,
  }));
  const vendors = vendor.map((ptype) => ({
    value: ptype.bpId,
    label: ptype.name,
  }));
  const baseunits = baseu.map((ptype) => ({
    value: ptype.buId,
    label: ptype.unit + " - " + ptype.desc,
  }));
  const requiredmats = reqm.map((ptype) => ({
    value: ptype.payItmId,
    label: ptype.payItmCode + " - " + ptype.payItmDesc,
  }));
  const nonprods = nonprod.map((ptype) => ({
    value: ptype.payItmId,
    label: ptype.payItmCode + " - " + ptype.payItmDesc,
  }));
  const jobTypes = jobtype.map((ptype) => ({
    value: ptype.payitmTypeId,
    label: ptype.payitmTypeName,
  }));
  const prequals = prequal.map((ptype) => ({
    value: ptype.pqcId,
    label: ptype.section + " - " + ptype.prequal + " - " + ptype.desc,
  }));
  const history = useHistory();

  return (
    <Formik
      enableReinitialize
      initialValues={{
        contractor: payitem.contractor,
        payItmCode: payitem.payItmCode,
        payItmDesc: payitem.payItmDesc,
        requiredMaterial: payitem.requiredMaterial,
        nonProdItem: payitem.nonProdItem,
        prequalCode:
          prequals.find((x) => x.value === payitem.prequalCode) || null, //payitem.prequalCode,
        baseUnit: payitem.baseUnit,
        vendor: vendors.find((x) => x.value === payitem.vendor) || null,  // payitem.vendor,
        typeId: payitem.typeId || "",
        //New Values
        showSign: payitem.typeId
          ? jobTypes.find((x) => x.value == payitem.typeId).label === "Sign"
          : false,
        showGuardrail: payitem.typeId
          ? jobTypes.find((x) => x.value == payitem.typeId).label ===
          "Guardrail"
          : false,
        support: payitem.payItmClass
          ? payitem.payItmClass.some((x) => x.payitmClass === "Support")
          : false,
        elevation: payitem.payItmClass
          ? payitem.payItmClass.some((x) => x.payitmClass === "Elevation")
          : false,
        measurements: payitem.payItmClass
          ? payitem.payItmClass.some((x) => x.payitmClass === "Measurements")
          : false,
        post: payitem.payItmClass
          ? payitem.payItmClass.some((x) => x.payitmClass === "Post")
          : false,
        approach: payitem.payItmClass
          ? payitem.payItmClass.some((x) => x.payitmClass === "Approach")
          : false,
        stRange: payitem.range?.split("|")[0],
        endRange: payitem.range?.split("|")[1]
      }}
      validationSchema={Yup.object().shape({
        payItmCode: Yup.string().required("Code is required").max(12),
        payItmDesc: Yup.string().required("Description is required"),
        stRange: Yup.number().when("support", {
          is: true,
          then: Yup.number().required("Start range is required")
        }),
        endRange: Yup.number().when("support", {
          is: true,
          then: Yup.number().required("End range is required")
        }).when("stRange", (stRange) => {
          if (stRange) {
            return Yup.number().min(stRange, "End Range must be grater than Start")
          }
        })
      })}
      onSubmit={(values, { setSubmitting }) => {
        let itemclass = [];
        //Build Item Only if Pay-Item type have Classification
        //Test Case: What if payitem has value but while edit item type is changed to an non Classification item need to remove db entry
        if (values.showGuardrail || values.showSign) {
          values.support &&
            itemclass.push({
              payitmClass: "Support",
              payitmTypeId: values.typeId,
            });
          values.elevation &&
            itemclass.push({
              payitmClass: "Elevation",
              payitmTypeId: values.typeId,
            });
          values.measurements &&
            itemclass.push({
              payitmClass: "Measurements",
              payitmTypeId: values.typeId,
            });
          values.post &&
            itemclass.push({
              payitmClass: "Post",
              payitmTypeId: values.typeId,
            });
          values.approach &&
            itemclass.push({
              payitmClass: "Approach",
              payitmTypeId: values.typeId,
            });

        }

        var upayitem = {
          ...values,
          payItmId: payitem.payItmId,
          createdOn: payitem.createdOn,
          prequalCode: values.prequalCode ? values.prequalCode.value : "",
          vendor: values.vendor ? values.vendor.value : "",
          payItmClass: itemclass,
          range: values.support ? values.stRange + "|" + values.endRange : null,
          status: 1,
        };
        apiService.postContent("pi/", upayitem).then(
          (saved) => {
            enqueueSnackbar("Payitem Created/Updated successfully!", {
              variant: "success",
            });
          },
          (error) => {
            enqueueSnackbar("Error Creating/Updating Payitem:" + error, {
              variant: "error",
            });
          }
        );
        setSubmitting(false);
        history.goBack();
      }}
    >
      {({ errors, status, touched, setFieldValue, values }) => (
        <Grid className={classes.headcontainer} container spacing={3}>
          <Form className={classes.bussinessform}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <FkSelectFld
                  name="contractor"
                  items={contractors}
                  label="Contractor"
                  controlClass={classes.formControl}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <FkInputFld
                  label="Pay Item Code"
                  name="payItmCode"
                  controlClass={classes.formControl}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <FkInputFld
                  label="Pay Item Description"
                  name="payItmDesc"
                  controlClass={classes.formControl}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <FkSelectFld
                  name="requiredMaterial"
                  items={requiredmats}
                  label="Required Materials"
                  controlClass={classes.formControl}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <FkSelectFld
                  name="nonProdItem"
                  items={nonprods}
                  label="Non Production Item"
                  controlClass={classes.formControl}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Field
                  name="typeId"
                  label="Payitem Type"
                  options={jobTypes}
                  component={Select}
                  className={classes.formControlLvL}
                  //value={values.typeId}
                  onChange={(_, value) => {
                    //console.log(value);
                    setFieldValue("showGuardrail", false);
                    setFieldValue("showSign", false);
                    if (value.props.children === "Sign") {
                      setFieldValue("showSign", true);
                    } else if (value.props.children === "Guardrail") {
                      setFieldValue("showGuardrail", true);
                    }
                    setFieldValue("typeId", value.props.value);
                  }}
                />
              </Grid>
            </Grid>
            {values.showSign && (
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}
                  >
                    <FormLabel
                      component="legend"
                      className={classes.formchkboxlvl}
                    >
                      Pay Item Classification
                    </FormLabel>
                    <FormGroup>
                      <Field
                        type="checkbox"
                        color="primary"
                        component={CheckboxWithLabel}
                        name="support"
                        Label={{ label: "Support", labelPlacement: "end" }}
                      />
                      <Field
                        type="checkbox"
                        color="primary"
                        component={CheckboxWithLabel}
                        name="elevation"
                        Label={{ label: "Elevation", labelPlacement: "end" }}
                      />
                      <Field
                        type="checkbox"
                        color="primary"
                        component={CheckboxWithLabel}
                        name="measurements"
                        Label={{ label: "Measurements", labelPlacement: "end" }}
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>

                {values.support && (
                  <Grid item xs={12} md={6} spacing={3}>
                    {/* <TextField
                      name="stRange"
                      label="Start Range"
                      disabled={false}
                    />
                    <TextField
                      name="endRange"
                      label="End Range"
                      disabled={false}
                    /> */}
                    <FkInputFld
                      label="Start Range"
                      name="stRange"
                      fullWidth={false}
                      type="number"
                    />
                    <div ></div>
                    <FkInputFld
                      label="End Range"
                      name="endRange"
                      fullWidth={false}
                      type="number"
                    />
                  </Grid>
                )}


              </Grid>
            )}

            {values.showGuardrail && (
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}
                  >
                    <FormLabel
                      component="legend"
                      className={classes.formchkboxlvl}
                    >
                      Pay Item Classification
                    </FormLabel>
                    <FormGroup>
                      <Field
                        type="checkbox"
                        color="primary"
                        component={CheckboxWithLabel}
                        name="post"
                        Label={{ label: "Post", labelPlacement: "end" }}
                      />
                      <Field
                        type="checkbox"
                        color="primary"
                        component={CheckboxWithLabel}
                        name="approach"
                        Label={{ label: "Approach", labelPlacement: "end" }}
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>
            )}
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Field
                  name="prequalCode"
                  options={prequals}
                  component={Autocomplete}
                  className={classes.formControl}
                  //  size="small"
                  fullWidth={true}
                  textFieldProps={{
                    label: "Pre-Qual Code",
                    // required: true,
                    // variant: "outlined",
                  }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <FkSelectFld
                  name="baseUnit"
                  items={baseunits}
                  label="Base Unit"
                  controlClass={classes.formControl}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Field
                  name="vendor"
                  options={vendors}
                  component={Autocomplete}
                  className={classes.formControl}
                  //  size="small"
                  fullWidth={true}
                  textFieldProps={{
                    label: "Vendor",
                  }}
                />
                {/* <FkSelectFld
                  name="vendor"
                  items={vendors}
                  label="Vendor"
                  controlClass={classes.formControl}
                /> */}
              </Grid>
            </Grid>
            {showAdmin && (
              <Grid container md={6} className={classes.formFooter}>
                <Grid item xs={6} md={6}>
                  <Button
                    variant="contained"
                    style={{ background: "#ff5722" }}
                    className={classes.button}
                    startIcon={<CancelIcon />}
                    onClick={() => history.goBack()}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={6} md={6} style={{ textAlign: "right" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    startIcon={<SaveIcon />}
                    type="submit"
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            )}
          </Form>
        </Grid>
      )}
    </Formik>
  );
};
export default NewPayItem;
