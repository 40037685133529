import {
  Grid,




  IconButton, List,
  ListItem,

  ListItemSecondaryAction, ListItemText, makeStyles, Typography
} from "@material-ui/core";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import React, { useState } from "react";
import { pdfjs } from "react-pdf";
import { apiService } from "../../services/api.service";
// import aa from "../../sample2.pdf"
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const useStyles = makeStyles((theme) => ({
  fileList: {
    backgroundColor: theme.palette.background.paper,
    //'padding-left': '9px',
  },
  fileNameTitle: {
    fontSize: "18px",
    fontWeight: "500",
    margin: "10px 0px 5px 0px",
    //  paddingLeft : 25,
    color: "#00C853",
  },
}));

const FManJobInfo = ({ jobDetails, s3files }) => {
  const classes = useStyles();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  // const[extFiles,setExtFiles]= useState( []);
  // setExtFiles(jobDetails.s3files);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const downloadS3file = (fileName) => () => {
    console.log(fileName);
    apiService.getFile(fileName);
  };

  function getExtFileNames() {
    console.log(s3files);
    if (s3files.length > 0) {
      let extFiles = s3files;
      const upStyle = {
        "border-bottom": "1px solid #dedede",
        // 'margin-left': '16px',
        "padding-left": "0px",
        "padding-bottom": "10px",
      };
      //console.log(extFiles.length);
      return extFiles.map((node) => (
        <ListItem key={Math.floor(Math.random() * node.length)} style={upStyle}>
          <ListItemText primary={node} />
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              aria-label="download"
              onClick={downloadS3file(node)}
            >
              <CloudDownloadIcon />
            </IconButton>
            {/* <IconButton edge="end" aria-label="delete" onClick={deleteS3file(node)}>
              <DeleteIcon />
            </IconButton> */}
          </ListItemSecondaryAction>
        </ListItem>
      ));
    } else {
      return (
        <ListItem>
          <ListItemText primary={"No plan Doc found!!"} />
        </ListItem>
      );
    }
  }
  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12} md={6} className={classes.formControl}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="overline" display="inline" gutterBottom>
                Job No:{" "}
                <Typography variant="subtitle2" display="inline" gutterBottom>
                  {jobDetails.jobCode}
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="overline" display="inline" gutterBottom>
                Quote:{" "}
                <Typography variant="subtitle2" display="inline" gutterBottom>
                  {jobDetails.quoteNumber}
                </Typography>
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="overline" display="inline" gutterBottom>
                State Job Number:{" "}
                <Typography variant="subtitle2" display="inline" gutterBottom>
                  {jobDetails.stateJobNumber}
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="overline" display="inline" gutterBottom>
                Job Type:{" "}
                <Typography variant="subtitle2" display="inline" gutterBottom>
                  {jobDetails.jobTypeStr?.join()}
                </Typography>
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="overline" display="inline" gutterBottom>
                Job Region:{" "}
                <Typography variant="subtitle2" display="inline" gutterBottom>
                  {jobDetails.regionName}
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="overline" display="inline" gutterBottom>
                Job County:{" "}
                <Typography variant="subtitle2" display="inline" gutterBottom>
                  {jobDetails.counties?.join()}
                </Typography>
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="overline" display="inline" gutterBottom>
                Job Location:{" "}
                <Typography variant="subtitle2" display="inline" gutterBottom>
                  {jobDetails.jobLocation}
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="overline" display="inline" gutterBottom>
                {/* Plan Doc Link:{" "} */}
                Award Date:{" "}
                {/* <a href={jobDetails.planLink}>{jobDetails.planLink}</a> */}
                <Typography variant="subtitle2" display="inline" gutterBottom>
                  {jobDetails.awardDt}
                </Typography>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography className={classes.fileNameTitle}>
            Plan Documents
          </Typography>
          <div className={classes.fileList}>
            <List dense>{getExtFileNames()}</List>
          </div>
        </Grid>
      </Grid>
      {/* <Grid container spacing={0}>
        <Document
          file={jobDetails.planLink}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page pageNumber={pageNumber} />
        </Document>
        <p>
          Page {pageNumber} of {numPages}
        </p>
      </Grid> */}
    </>
  );
};

export default FManJobInfo;
