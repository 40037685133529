import { Checkbox, FormControl, Grid, TextField } from "@material-ui/core";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import { apiService } from "../../services/api.service";
import ReportFooter from "./ReportFooter";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    margin: "0.75rem 0.75rem",
    //'box-shadowhadow': 'none',
  },
  formControl: {
    minWidth: 120,
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
  },
  pos: {
    marginBottom: 12,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
});

export default function QuoteToActualCompByDivision() {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  useEffect(() => {
    apiService.getContent("pi/type").then((edata) => { 
      console.log(edata);
      setData(edata)
    
    });
   
  }, []);

    const runReport = (event, rptType) => {
        if (selectedType) {
            let res = selectedType.map((x) => x.payitmTypeId).toString();
            console.log(res + " ---PrequalIDs");
            console.log(selectedType + " ---Get Selected ID");
            //Submit form rptype...xlsx pdf
            var qrystr = "payitemcompsum&format=" + rptType + "&job_type1=" + res;
            var fileName = "QuotesToActual - Detail_" + res + "." + rptType;
            console.log(qrystr, fileName);
            apiService.getReport(qrystr, fileName);
        } else {
            console.log("Please select a payitem type");
        }
    };
  //const allType = data.map((x) => x);
  return (
    <>
      <CardContent>
        <Typography className={classes.title} gutterBottom>
        Quote to Actual Comparision all Active Jobs by Division
        </Typography>
        <Typography variant="h5" component="h2"></Typography>
        <Typography className={classes.pos} color="textSecondary">
         
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormControl
              className={classes.formControl}
              fullWidth={true}
              margin={"normal"}
              size={"medium"}
            >
              <Autocomplete
                multiple
                id="checkboxes-tags"
                options={data}
                getOptionLabel={(option) => option.payitmTypeName}
                limitTags={3}
                // value={myTempVal}
                // disableCloseOnSelect
                onChange={(event, newValue) => {
                  setSelectedType(newValue);
                }}
                //getOptionLabel={(option) => option}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.payitmTypeName}
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Pay Item Type"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <ReportFooter sumbit={runReport} />
      </CardActions>
      {/* </Card> */}
    </>
  );
}
