import { Button, Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SaveIcon from "@material-ui/icons/Save";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import * as Yup from "yup";
import FkInputFld from "../components/forms/formik-custom-fields/FkInputFld";
import FkSelectFld from "../components/forms/formik-custom-fields/FkSelectFld";
import { DisplayFormikState } from "../helper/DisplayFormikState";
import { apiService } from "../services/api.service";
const useStyles = makeStyles((theme) => ({
  formControl: {
    padding: "0px 25px",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  headcontainer: {
    margin: "15px 0%",
  },
  button: {
    color: "white",
    fontWeight: 500,
    fontSize: 14,
    letterSpacing: 0.8,
    borderRadius: 20,
  },
  bussinessform: {
    width: "100%",
  },
  fullwidthpaper: {
    width: "100%",
  },
  formFooter: {
    padding: "15px 25px",
    background: "#efefef",
    "margin-top": "25px",
  },
  lvlResize: {
    left: "25px",
  },
  projectheading: {
    fontWeight: 500,
    letterSpacing: 0.5,
    fontSize: 17,
  },
}));
const UserDetails = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const { enqueueSnackbar } = useSnackbar();
  const [employee, setEmployee] = useState({});
  const classes = useStyles();
  useEffect(() => {
    apiService
      .getContent("bp/" + currentUser.info.bpId)
      .then((edata) => setEmployee(edata));
  }, []);
  if (!currentUser) {
    return <Redirect to="/login" />;
  }
  const relations = [
    {
      label: "Child",
      value: "Child",
    },
    {
      label: "Family",
      value: "Family",
    },
    {
      label: "Friends",
      value: "Friends",
    },
    {
      label: "Other",
      value: "Other",
    },
    {
      label: "Parent",
      value: "Parent",
    },
    {
      label: "Relative",
      value: "Relative",
    },
    {
      label: "Sibling",
      value: "Sibling",
    },
    {
      label: "Spouse",
      value: "Spouse",
    },
  ];
 
  return (
    <Grid container direction="row">
      <Grid className={classes.headcontainer} container spacing={6}>
        <Grid item xs={6} className={classes.projectheading}>
          <Typography variant="h6" gutterBottom>
            Personal Details -{" "}
            {currentUser.info.firstName + " " + currentUser.info.lastName}
          </Typography>
        </Grid>
        <Formik
          enableReinitialize
          initialValues={{
            // typeId: employee.typeId,
            //companyName: employee.companyName,
            //role: "",
            //status: employee.status,
            //scId: employee.scId,
            bpId: currentUser.info.bpId,
            firstName: employee.firstName,
            lastName: employee.lastName,
            phone: employee.phone,
            email: employee.email,
            contact1Name: employee.contact1Name,
            relation1: employee.relation1,
            cell1Phone: employee.cell1Phone,
            home1Phone: employee.home1Phone,
            contact2Name: employee.contact2Name,
            relation2: employee.relation2,
            cell2Phone: employee.cell2Phone,
            home2Phone: employee.home2Phone,
          }}
          validationSchema={Yup.object().shape({})}
          onSubmit={(values, { setSubmitting }) => {
            //var uemployee = { ...values, bpId: employee.bpId, createdOn: employee.createdOn };
            console.log(values);
            apiService.putContent("bp/empl/" + values.bpId, values).then(
              (saved) => {
                enqueueSnackbar("Business Partner Updated successfully!", {
                  variant: "success",
                });
              },
              (error) => {
                enqueueSnackbar(
                  "Error Creating/Updating Business Partner:" + error,
                  { variant: "error" }
                );
              }
            );
            setSubmitting(false);
            // history.goBack();
          }}
        >
          {({ errors, status, touched, values }) => (
            <Paper className={classes.fullwidthpaper}>
              <Form className={classes.bussinessform}>
                <Grid container spacing={0}>
                  <Grid item xs={12} md={6}>
                    <FkInputFld
                      label="Last Name"
                      name="lastName"
                      disabled={true}
                      controlClass={classes.formControl}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FkInputFld
                      label="First Name"
                      name="firstName"
                      disabled={true}
                      controlClass={classes.formControl}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={0}>
                  <Grid item xs={12} md={6}>
                    <FkInputFld
                      label="Phone"
                      name="phone"
                      controlClass={classes.formControl}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FkInputFld
                      label="Email"
                      name="email"
                      disabled={true}
                      controlClass={classes.formControl}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={0}>
                  <Grid item xs={12} md={6}>
                    <FkInputFld
                      label="Primary Contact Name"
                      name="contact1Name"
                      controlClass={classes.formControl}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FkSelectFld
                      name="relation1"
                      items={relations}
                      label="Relationship"
                      controlClass={classes.formControl}
                      lableClass={classes.lvlResize}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={0}>
                  <Grid item xs={12} md={6}>
                    <FkInputFld
                      label="Cell Phone"
                      name="cell1Phone"
                      controlClass={classes.formControl}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FkInputFld
                      label="Home Phone"
                      name="home1Phone"
                      controlClass={classes.formControl}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={0}>
                  <Grid item xs={12} md={6}>
                    <FkInputFld
                      label="Secondary Contact Name"
                      name="contact2Name"
                      controlClass={classes.formControl}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FkSelectFld
                      name="relation2"
                      items={relations}
                      label="Relationship"
                      controlClass={classes.formControl}
                      lableClass={classes.lvlResize}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={0}>
                  <Grid item xs={12} md={6}>
                    <FkInputFld
                      label="Cell Phone"
                      name="cell2Phone"
                      controlClass={classes.formControl}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FkInputFld
                      label="Home Phone"
                      name="home2Phone"
                      controlClass={classes.formControl}
                    />
                  </Grid>
                </Grid>
                <Grid container className={classes.formFooter}>
                  <Grid item xs={6} md={6}>
                    {/* <Button
                    variant="contained"
                    style={{ background: "#ff5722" }}
                    className={classes.button}
                    startIcon={<CancelIcon />}
                    //onClick={() => history.goBack()}
                  >
                    Cancel
                  </Button> */}
                  </Grid>
                  <Grid item xs={6} md={6} style={{ textAlign: "right" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      startIcon={<SaveIcon />}
                      type="submit"
                    >
                      Update
                    </Button>
                  </Grid>
                  {/* <DisplayFormikState {...values} /> */}
                </Grid>
              </Form>
            </Paper>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default UserDetails;

// <div className="container">
// <header className="jumbotron">
//   <h3>
//     <strong>{currentUser.username}</strong> User Details
//   </h3>
// </header>
// <p>
//   <strong>First Name:</strong> {currentUser.info.firstName}
// </p>
// <p>
//   <strong>Last Name:</strong> {currentUser.info.lastName}
// </p>
// <p>
//   <strong>Phone:</strong> {currentUser.info.phone}
// </p>
// <p>
//   <strong>Email:</strong> {currentUser.email}
// </p>
// <p>
//   <strong>Company Name:</strong> {currentUser.info.companyName}
// </p>
// <p>
//   <strong>Primary Contact Name:</strong> {currentUser.info.contact1Name}
// </p>
// <p>
//   <strong>Primary Contact Relation:</strong> {currentUser.info.relation1}
// </p>
// <p>
//   <strong>Primary Contact Email:</strong> {currentUser.info.email1}
// </p>
// <p>
//   <strong>Primary Contact Phone:</strong> {currentUser.info.cell1Phone}
// </p>
// <p>
//   <strong>Secondary Contact Name:</strong> {currentUser.info.contact2Name}
// </p>
// <p>
//   <strong>Secondary Contact Relation:</strong> {currentUser.info.relation2}
// </p>
// <p>
//   <strong>Secondary Contact Email:</strong> {currentUser.info.email2}
// </p>
// <p>
//   <strong>Secondary Contact Phone:</strong> {currentUser.info.cell2Phone}
// </p>
// </div>
