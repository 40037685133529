import {
  Box,
  Checkbox,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from "@material-ui/core";
import { blue, green } from "@material-ui/core/colors";
import BuildIcon from "@material-ui/icons/Build";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import DoneSharpIcon from "@material-ui/icons/DoneSharp";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MaterialTable, { MTableToolbar } from "material-table";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const useStyles = makeStyles((theme) => ({
  innerTableHeading: {
    "padding-left": "17px",
    "text-transform": "uppercase",
    "font-size": "14px",
    "font-weight": "700",
  },
  formControl: {
    //margin: theme.spacing(1),
    minWidth: 150,
  },
  displayNone: {
    display: "none",
  },
}));
const FMStationItm = ({ data, OnStationSelect, job }) => {
  const classes = useStyles();
  const [selectedpayItms, setSelectedpayItms] = React.useState([]);
  const [selectedNPI, setSelectedNPI] = React.useState([]);

  const [payItems, setPayItems] = React.useState([]);
  const [npiItems, setNpiItems] = React.useState([]);
  const [tableData, setTableData] = React.useState([]);
  const [showAdmin, setShowAdmin] = React.useState(false);
  const { user: currentUser } = useSelector((state) => state.auth);

  const handleChange = (event, value) => {
    setSelectedpayItms(value);
    var filteredAry = data.filter(function (el) {
      return value.find((rm) => rm.value == el.jobPayitm);
    });
    if (selectedNPI?.length) {
      var fAry = data.filter(function (el) {
        return selectedNPI.find((rm) => rm.value == el.jobPayitm);
      });
      var ids = new Set(filteredAry.map((d) => d.stationPi));
      var merged = [
        ...filteredAry,
        ...fAry.filter((d) => !ids.has(d.stationPi)),
      ];
      setTableData(merged);
    } else {
      if (filteredAry?.length) {
        setTableData(filteredAry);
      } else {
        setTableData(data);
      }
    }
    //var selectedPayitems = event.target.value.split(',');
  };
  const handleNPIChange = (event, value) => {
    setSelectedNPI(value);
    var filteredAry = data.filter((el) =>
      value.find((rm) => rm.value == el.jobPayitm)
    ); //e.payItmDesc == event.target.value)
    if (selectedpayItms?.length) {
      var fAry = data.filter(function (el) {
        return selectedpayItms.find((rm) => rm.value == el.jobPayitm);
      });
      var ids = new Set(filteredAry.map((d) => d.stationPi));
      var merged = [
        ...filteredAry,
        ...fAry.filter((d) => !ids.has(d.stationPi)),
      ];
      setTableData(merged);
    } else {
      if (filteredAry?.length) {
        setTableData(filteredAry);
      } else {
        setTableData(data);
      }
    }

    //var selectedPayitems = event.target.value.split(',');
  };

  useEffect(() => {
    //console.log(data);
    const items = data.map((ptype) => ({
      value: ptype.jobPayitm,
      label: ptype.payItmDesc,
      code: ptype.payItmCode,
    }));
    let npiArr = data.map(getNPI);
    function getNPI(itm) {
      if (itm.npiItem != null) {
        return {
          value: itm.jobPayitm,
          label: itm.npiDesc,
          code: itm.npiItem,
        };
      }
    }
    const newArr = npiArr.filter((e) => e);
    var xx = [...new Map(newArr.map((x) => [x["value"], x])).values()];
    xx = xx.sort((a, b) => a.code.localeCompare(b.code));
    setNpiItems(xx);

    var payitmTypes = [...new Map(items.map((x) => [x["value"], x])).values()];
    payitmTypes = payitmTypes.sort((a, b) => a.code.localeCompare(b.code));
    // console.log(payitmTypes);
    setPayItems(payitmTypes);

    var payItmArr = [];
    if (selectedpayItms?.length > 0) {
      payItmArr = data.filter((el) =>
        selectedpayItms.find((rm) => rm.value == el.jobPayitm)
      );
    }
    npiArr = [];
    if (selectedNPI?.length > 0) {
      npiArr = data.filter((el) =>
        selectedNPI.find((rm) => rm.value == el.jobPayitm)
      );
    }
    var ids = new Set(payItmArr.map((d) => d.stationPi));
    var filteredAry = [
      ...payItmArr,
      ...npiArr.filter((d) => !ids.has(d.stationPi)),
    ];
    if (filteredAry?.length) {
      setTableData(filteredAry);
    } else {
      //just backup option to display all value if anything goes wrong..
      setTableData(data);
    }
    setShowAdmin(currentUser.roles.includes("ROLE_ADMIN"));
    //setTableData(data);
  }, [data]);
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
        spacing={5}
      >
        <Grid item xs={12} md={6} px={2}>
          <Autocomplete
            multiple
            id="checkboxes-payItem"
            options={payItems}
            value={selectedpayItms}
            disableCloseOnSelect={true}
            limitTags={3}
            onChange={handleChange}
            // onClose={handleClose}
            size="small"
            getOptionLabel={(option) => option.label.substring(0, 8) + ".."}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.label}
              </React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Select PayItems to filter the list"
                placeholder=""
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} px={2}>
          <Autocomplete
            multiple
            id="checkboxes-npi"
            options={npiItems}
            value={selectedNPI}
            disableCloseOnSelect={true}
            limitTags={3}
            onChange={handleNPIChange}
            // onClose={handleClose}
            size="small"
            getOptionLabel={(option) => option.label.substring(0, 8) + ".."}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.label}
              </React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Select NPI Items to filter the list"
                placeholder=""
              />
            )}
          />
        </Grid>
      </Grid>

      <MaterialTable
        title={job.jobCode + " - " + job.jobLocation}
        columns={[
          { title: "Station", field: "stationName", defaultSort: "asc" },
          {
            title: "",
            field: "imageUrl",
            width: 10,
            render: (rowData) =>
              rowData.complete ? (
                <DoneSharpIcon style={{ color: green[600], fontSize: 20 }} />
              ) : (
                ""
              ),
          },
          { title: "Item Description", field: "payItmDesc", width: 300 },
          { title: "Plan Quantity", field: "quantity", type: "numeric" },
          { title: "Installed", field: "used", type: "numeric" },
          { title: "Code", field: "payItmCode" },
          { title: "NPI Description", field: "npiDesc", width: 250 },
          //{ title: "Type", field: "PayItmType" },
        ]}
        data={tableData}
        actions={[
          (rowData) => ({
            icon: () => (
              <BuildIcon
                style={{ fontSize: 25, color: "#fb8c00" }}
                // className={
                //   rowData.complete && rowData.showAdmin && classes.displayNone
                // }
              />
            ), //,color: "#fb8c00"
            tooltip: "Work on Station",
            hidden: (showAdmin) == true ? false : (rowData.complete==true || !currentUser.roles.includes("ROLE_FOREMAN"))?true:false,
            onClick: (event, rowData) => OnStationSelect(rowData.stationPi),

            //
          }),
        ]}
        detailPanel={[
          (rowData) => ({
            disabled: !rowData.expandable,
            icon: () => (
              <GroupWorkIcon
                style={{ fontSize: 25, color: blue[500] }}
                className={!rowData.expandable && classes.displayNone}
              />
            ),
            render: () => (
              <Box margin={1}>
                <Typography
                  className={classes.innerTableHeading}
                  gutterBottom
                  component="div"
                >
                  More Details
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Inst Date</TableCell>
                      <TableCell align="right">Inst Quantity</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Inst By</TableCell>
                      <TableCell>Notes</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rowData.dlyProdItm.dlyprodActvty.map((history, i) => (
                      <TableRow key={i}>
                        {/* key={historyRow.date} */}
                        <TableCell>{history.instDate}</TableCell>
                        <TableCell align="right">{history.instQty}</TableCell>
                        <TableCell>
                          {history.actvtyStatus == 10
                            ? "Installed"
                            : history.actvtyStatus == 11
                            ? "Approved"
                            : "Rejected"}
                        </TableCell>
                        <TableCell>{history.instBy}</TableCell>
                        <TableCell>{history.notes}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            ),
          }),
        ]}
        options={{
          filtering: false,
          grouping: true,
          // selection:true,
          // showSelectAllCheckbox:false,
          pageSize: 25, //Diaplay all no of reords..
          pageSizeOptions: [25, 50, data.length > 100 ? data.length : 75],
          padding: "dense",
          showTitle: true,
          emptyRowsWhenPaging: false,
          //tableLayout:"fixed"
        }}
        components={{
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} />
              <div style={{ padding: "10px 10px" }}></div>
            </div>
          ),
        }}
        // onSelectionChange={(rows) =>  alert('You selected ' + rows.length + ' rows')}
        localization={{
          header: {
            actions: "",
          },
        }}
      />
    </>
  );
};

export default React.memo(FMStationItm);
