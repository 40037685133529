import { Grid, IconButton, Tooltip, Typography, Divider } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { green } from "@material-ui/core/colors";
import blue from "@material-ui/core/colors/blue";
import deepOrange from "@material-ui/core/colors/deepOrange";
import red from "@material-ui/core/colors/red";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { MonetizationOn } from "@material-ui/icons";
import CategoryIcon from '@material-ui/icons/Category';
import EventBusyIcon from "@material-ui/icons/EventBusy";
import FilterDramaIcon from "@material-ui/icons/FilterDrama";
import ListAltIcon from "@material-ui/icons/ListAlt";
import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import WtherDayDialog from "../forms/WtherDayDialog";
// import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeedSharp';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import FileUploader from "../../helper/FileUploader";


const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    // "&:focus": {
    //   backgroundColor: theme.palette.primary.main,
    //   "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
    //     color: theme.palette.common.white,
    //   },
    // },
    "& .MuiListItemIcon-root": {
      "min-width": "36px",
    },
  },
}))(MenuItem);
const useStyles = makeStyles((theme) => ({
  projectbtn: {
    "& button": {
      float: "right",
    },
  },
  projectheading: {
    fontWeight: 500,
    letterSpacing: 0.5,
    fontSize: 17,
    float: "left",
    paddingTop: 12
  },
  divider: {
    height: 4,
    backgroundImage: 'linear-gradient(90deg, #00b0ff, #00C853)',
    width: '100%',
    marginTop: 15,
  },
}));
export default function CustomizedMenus({ jobId, Wday, headerText, IsAdmin, payitemUpload = false,onFileSet  }) {
  const classes = useStyles();
  const modalRef = useRef(null);

  const AddWeatherModalRef = () => {
    console.log(modalRef.current);
    modalRef.current.handleClickOpen();
  };

  const [open, setOpen] = React.useState(false);
  const [openUpload, setOpenUpload] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [rows, setRows] = React.useState([]);
  const [cols, setCols] = React.useState([]);

  const [files, setFiles] = React.useState([]);
  const history = useHistory();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseModal = () => {
    setOpen(false);
    setOpenUpload(false);
    setFiles([]);
    setAnchorEl(null);
  };
  const handleSubmit=()=>{
    setOpenUpload(false);
    onFileSet(files);
    setFiles([]);
  }
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Grid item xs={12}>
      <div className={classes.projectheading}>
        <Typography variant="h6" gutterBottom>
          {headerText}
        </Typography>
      </div>
      <div className={classes.projectbtn}>
        {Wday && (
          <>
            <Tooltip title="View Weather Days">
              <IconButton
                aria-label="Add Weather Days"
                onClick={() => setOpen(true)}
              >
                <EventBusyIcon style={{ color: red["A200"], fontSize: 30 }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Add Weather Days">
              <IconButton
                aria-label="Add Weather Days"
                onClick={AddWeatherModalRef}
              >
                <FilterDramaIcon style={{ color: "#6699cc", fontSize: 30 }} />
              </IconButton>
            </Tooltip>
          </>
        )}
        {payitemUpload && (
          <>
            <Tooltip title="Upload Pay Items">
              <IconButton
                aria-label="Upload Pay Items"
                onClick={() => setOpenUpload(true)}
              >
                <CloudUploadIcon style={{ color: "#6699cc", fontSize: 30 }} />
              </IconButton>
            </Tooltip>
          </>
        )}
        {(IsAdmin &&
          <Tooltip title="Foreman Station">
            <IconButton
              aria-label="Foreman Station"
              onClick={() => history.push("/FMJobDetails/" + jobId)}
            >
              <DynamicFeedIcon style={{ color: '#f57c00', fontSize: 30 }} />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title="Job Estimates">
          <IconButton
            aria-label="Job Estimates"
            onClick={() => history.push("/estimate/" + jobId)}
          >
            <MonetizationOn style={{ color: green[500], fontSize: 30 }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Job Station Items">
          <IconButton
            aria-label="Job Station Items"
            onClick={() => history.push("/stationlist/" + jobId)}
          >
            <CategoryIcon
              style={{
                color: deepOrange[500],
                fontSize: 30,
              }}
            />
          </IconButton>
        </Tooltip>
        <Tooltip title="Job Pay Items">
          <IconButton
            aria-label="Job Pay Items"
            onClick={() => history.push("/jobdetails/" + jobId)}
          >
            <ListAltIcon style={{ color: blue[500], fontSize: 30 }} />
          </IconButton>
        </Tooltip>

        <WtherDayDialog ref={modalRef} props={jobId}></WtherDayDialog>
        {/* <Button
          aria-controls="customized-menu"
          aria-haspopup="true"
          variant="contained"
          onClick={handleClick}
          color="primary"
        >
          More Option
        </Button> */}
      </div>

      {/*<WtherDayTable ref={modalRef2} ></WtherDayTable> */}
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"All Weather Days"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {Wday && Wday.length > 0 && (
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Reason</TableCell>
                      <TableCell align="right">Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Wday?.map((row) => (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          {row.reason}
                        </TableCell>
                        <TableCell align="right">{row.weatherDay}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            {Wday && !Wday.length && (
              <h4> No Weather days found for this job.</h4>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Divider className={classes.divider} />
      <Dialog
        open={openUpload}
        onClose={handleClose}
        fullWidth
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Upload Payitem Excel"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FileUploader
              acceptedFileTypes="text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
               maxFiles={1}
              fileNames={files}
              onDrop={(acceptedFiles) => {
                setFiles(acceptedFiles.map((file) => file));
                //apiService.postFile(acceptedFiles.map((file) => file), "201600")
              }}
            />

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} color="primary" autoFocus>
            Upload
          </Button>
          <Button onClick={handleCloseModal} color="secondary" autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
