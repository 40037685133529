import { green, red } from "@material-ui/core/colors";
import BorderColorSharpIcon from '@material-ui/icons/BorderColorSharp';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import MaterialTable from "material-table";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from 'react-router-dom';
import { SET_STATION } from "../../actions/type";
import { apiService } from '../../services/api.service';
import DailyProdEditDialog from "../content/DailyProdEditDialog";
const DlyProdList = () => {
  const [data, setData] = useState([])
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const history = useHistory();
  const modalRef = useRef(null);
  const [editObj, setEditObj]= useState({
    Id:0,
    Key:0
  })

  const EditProdModalRef = (dlyprod_itm,dlyprod_key) => {
    console.log(modalRef.current);
    setEditObj({
      ...editObj,
      Id:dlyprod_itm,
      Key:dlyprod_key
    });
    modalRef.current.handleOpen();
  };
  useEffect(() => {
    refreshTable()
  }, [])
  function getSmall(rowData) {
    var submitArr = [];
    rowData.forEach(element => {
      submitArr.push({
        dlyprodItm: element.dlyprod_itm, dlyprodItmKey: element.dlyprod_itm_key,
        logBy: "react"
      })
    });
    return submitArr;
  }
  function refreshTable() {
    apiService.getContent("dp/app")
      .then(edata => {
        setData(edata);
        //console.log(edata)
      })
  }
  function approve(rowData) {
    var smalla = getSmall(rowData);
    putDlyprodApprovals(smalla, "dp/app/accept")
  }
  function reject(rowData) {
    var smalla = getSmall(rowData);
    putDlyprodApprovals(smalla, "dp/app/reject")
  }
  function putDlyprodApprovals(values, poption) {
    console.log(JSON.stringify(values));
    const putMethod = {
      method: 'PUT', // Method itself
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify(values) //JSON format
    }
    apiService.putContent(poption, values)
      .then(response => response.json())
      .then(data => console.log(data))
      .then((response) => {
        refreshTable()
        enqueueSnackbar("Updated Successfully", { variant: "success" });
      })
      .catch((error) => {
        enqueueSnackbar("There is an error in updating approval item:" + error, { variant: 'error', });
        console.log('error: ' + error);
      });
  }
  function localstation(stationid) {
    apiService.getContent("jsi/item/"+stationid)
      .then(edata => {        
        console.log(edata)
        dispatch({
          type: SET_STATION,
          payload: JSON.stringify(edata),
        });
      })    
  }
  
  return (
    <>
    <DailyProdEditDialog ref={modalRef} props={editObj} refreshTable={refreshTable}/>
      <MaterialTable
        title="Daily Approvals"
        columns={[
          { title: "Job", field: "job", defaultGroupOrder: 0 },
          { title: "Location", field: "local" },
          {
            render: (row) => {
              return <Link to={`stationlist/${row.jobId}`} onClick={() => localstation(row.stationid)}>{row.station}</Link>;
            }, title: 'Station',
            customSort: (a,b)=> a.station.localeCompare(b.station),
            defaultSort:"asc"
          },
          { title: "Payitem", field: "payitem" },
          { title: "Payitem Desc", field: "payitemd" },
          { title: "Plan Quantity", field: "planQty", type: "numeric" },
          { title: "Installed", field: "instQty", type: "numeric" },
          {
            render: (row) => {
              return (row.npi == null) ? "" : `${row.npi}-${row.npid}`;
            }, title: 'NPI'
          },
          { title: "NPI Qty", field: "instNpi" },
          { title: "Emp Name", field: "instBy" },
          { title: "Date", field: "instDate" },
          { title: "Note", field: "notes" },
        ]}
        data={data}
        actions={[
          {
            icon: () => (
              <CheckCircleIcon style={{ fontSize: 30, color: green[500] }} />
            ), //,color: "#fb8c00"
            tooltip: "Accept",
            onClick: (event, rowData) => approve(rowData),
            //
          },
          {
            icon: () => (
              <CancelIcon style={{ fontSize: 30, color: red[500] }} />
            ), //,color: "#fb8c00"
            tooltip: "Reject",
            onClick: (event, rowData) => reject(rowData),
            //
          },
          
            {
              icon:() => <BorderColorSharpIcon  style={{ fontSize: 20,color:"#fe4a49" }}/>, //,color: "#fb8c00" 
              tooltip: 'Edit',
              position: 'row',
              onClick: (event, rowData) => EditProdModalRef(rowData.dlyprod_itm,rowData.dlyprod_itm_key)
              
              // 
            }
         
        ]}
        //onSelectionChange={(rows) => console.log('You selected ' + rows.length + ' rows')}
        options={{
          rowStyle: (x) => {
            if (x.instQty != x.planQty || (x.notes && x.notes.length > 1)) {
              return { backgroundColor: "#ffcc80" };
            }
          },
          filtering: false,
          grouping: true,
          // showSelectAllCheckbox:false,
          // pageSize: 10,
          // pageSizeOptions: [10, 25, 50, 100],
          paging: false,
          padding: "dense",
          // showTitle: true,
          emptyRowsWhenPaging: false,
          tableLayout: "fixed",
          selection: true,
        }}
        // onSelectionChange={(rows) =>  alert('You selected ' + rows.length + ' rows')}
        localization={{
          header: {
            actions: "",
          },
        }}
      />
    </>
  );
};

export default DlyProdList;
