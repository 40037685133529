import DateFnsUtils from "@date-io/date-fns";
import { Checkbox, FormControl, Grid, TextField } from "@material-ui/core";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import React, { useEffect, useState } from "react";
import { apiService } from "../../services/api.service";
import ReportFooter from "./ReportFooter";
const useStyles = makeStyles({
  root: {
    minWidth: 275,
    margin: "0.75rem 0.75rem",
    //'box-shadowhadow': 'none',
  },
  formControl: {
    minWidth: 160,
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
  },
  pos: {
    marginBottom: 12,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
});

export default function JobProdByPrequalSummary() {
  const classes = useStyles();
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const [PreQual, setPreQual] = useState([]);
  const [selectedpreQual, setSelectedPreQual] = useState([]);
  useEffect(() => {
    apiService.getContent("pi/pq").then((edata) => {
      //console.log(edata);
      setPreQual(edata);
    });
  }, []);
  const runReport = (event, rptType) => {
    if (selectedpreQual.length > 0) {
      let res = selectedpreQual.map((x) => "'" + x.prequal + "'").toString();
      console.log(res + " ---PrequalIDs");
      var qrystr =
        "jobprodcatsumpqual&format=" +
        rptType +
        "&prequal=" +
        res;
      var fileName =
        "JobProductionPreQuaSummary"+"_"+res+ "." + rptType;
      apiService.getReport(qrystr, fileName);
    } else {
      console.log("Please select a ---PreQualCOde");
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <CardContent>
        <Typography className={classes.title} gutterBottom>
          Job Production by PreQual - Summary
        </Typography>
        <Typography variant="h5" component="h2"></Typography>
        <Typography className={classes.pos} color="textSecondary"></Typography>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormControl
              className={classes.formControl}
              fullWidth={true}
              margin={"normal"}
              size={"medium"}
            >
              <Autocomplete
                multiple
                id="checkboxes-tags"
                options={PreQual}
                getOptionLabel={(option) => option.prequal}
                limitTags={5}
                // value={myTempVal}
                disableCloseOnSelect
                onChange={(event, newValue) => {
                  setSelectedPreQual(newValue);
                }}
                //getOptionLabel={(option) => option}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.prequal} - {option.desc}
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select PreQual"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <ReportFooter sumbit={runReport} />
      </CardActions>
    </MuiPickersUtilsProvider>
  );
}
