import DateFnsUtils from "@date-io/date-fns";
import { FormControl, Grid, TextField } from "@material-ui/core";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import React, { useEffect, useState } from "react";
import { apiService } from "../../services/api.service";
import ReportFooter from "./ReportFooter";
import dateFormat from "dateformat";
const useStyles = makeStyles({
  root: {
    minWidth: 275,
    margin: "0.75rem 0.75rem",
    //'box-shadowhadow': 'none',
  },
  formControl: {
    minWidth: 160,
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
  },
  pos: {
    marginBottom: 12,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
});

export default function QuantitiesByStationRpt() {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedjob, setSelectedJob] = useState(null);

  useEffect(() => {
    apiService.getContent("job/joblist").then((edata) => setData(edata));
  }, []);
  // const handleDateChange = (date) => {
  //   setSelectedDate(date);
  // };

  const runReport = (event, rptType) => {
    if (selectedjob) {
      //var formatd = dateFormat(selectedDate, "yyyy-mm-dd")
      console.log(selectedjob);
      //Submit form rptype...xlsx pdf
      //var qrystr = "qntbysite&format=" + rptType + "&jobcode=" + selectedjob + "&installdate_date=" + formatd;
      let rpttmpt = (rptType === 'xlsx')? 'qntbysiteexcel':'qntbysite'
      var qrystr = rpttmpt + "&format=" + rptType + "&jobcode=" + selectedjob;
      var fileName = selectedjob + "_site." + rptType;
      apiService.getReport(qrystr, fileName);
    } else {
      console.log("Please select a ---Job");
    }
  };
  const alljobs = data.map((x) => x);
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <CardContent>
        <Typography className={classes.title} gutterBottom>
          Quantities by Station
        </Typography>
        <Typography variant="h5" component="h2"></Typography>
        <Typography className={classes.pos} color="textSecondary">
          All Approved Quantities by Station, Payitem and Installed Date
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormControl
              className={classes.formControl}
              fullWidth={true}
              margin={"normal"}
              size={"medium"}
            >
              <Autocomplete
                id="job"
                // blurOnSelect
                options={alljobs}
                onChange={(_, value) => {
                  setSelectedJob(value);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Select a Job" />
                )}
              />
            </FormControl>
          </Grid>
          {/* <Grid item xs={12}>
            <KeyboardDatePicker
              margin={"normal"}
              id="date-picker-dialog"
              label="Date"
              format="MM/dd/yyyy"
              value={selectedDate}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </Grid> */}
        </Grid>
      </CardContent>
      <CardActions>
        <ReportFooter sumbit={runReport} />
      </CardActions>
    </MuiPickersUtilsProvider>
    //</Card>
  );
}
