import { ApiProps } from '../props/ApiProps'

export function login(username, password) {
    return fetch(ApiProps.baseUrl + 'auth/signin', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    }).then((response) => {
        if (!response.ok) {
            if (response.status === 401) {
                throw new Error("Not Authorized")
            } else {
                throw new Error("Error In Authentication:" + response.status)
            }
        } else {
            return response;
        }
    })
        .then(response => response.json())
        .then(edata => {
            //console.log(edata)
            if (edata.accessToken) {
                localStorage.setItem("user", JSON.stringify(edata));
            }
            return edata.data;
        })
}

export function logout() {
    localStorage.removeItem("user");
}

export function register(username, email, password) {
    var jsonStr = JSON.stringify({ userName: username, pwd: password, email })
    return fetch(ApiProps.baseUrl + "auth/signup", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: jsonStr,
    }).then((response) => {

        if (response.status === 200) return response;
        else {
            let jsonr = response.json();
            jsonr.then(function (result) {
                console.log(result) // "Some action"                
            })
            throw new Error("Unable to register user");
        }
    })
}

