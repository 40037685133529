import React, { ReactNode } from "react";
import { Field, ErrorMessage, FieldInputProps, useField } from "formik";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const MaterialUISelectField = ({
  label,
  children,
  value,
  name,
  onChange,
  onBlur,
  required,
  controlClass,
  lableClass,
}) => {
  const [field, meta] = useField({ name });
  const errorText = meta.error && meta.touched ? meta.error : "";
  return (
    <FormControl
      className={controlClass}
      fullWidth={true}
      margin={"normal"}
      //size={"medium"}
      error={!!errorText}
    >
      <InputLabel className={lableClass} required={required}>
        {label}
      </InputLabel>
      <Select name={name} onChange={onChange} onBlur={onBlur} value={field.value || ""}>
        {children}
      </Select>
      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
};

//FkrmkCheckBox
//FkRadioFld

const FkSelectFld = ({ name, items, label, required = false, controlClass = "", lableClass = "" }) => {
  return (
    <Field
      name={name}
      as={MaterialUISelectField}
      label={label}
      required={required}
      controlClass={controlClass}
      lableClass={lableClass}
    >
      <MenuItem value="">Choose your option</MenuItem>
      {items.map(item => (
        // console.log(typeof(item.value) + " Lable="+ item.label),
        <MenuItem key={item.value} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </Field>

  );
}

export default FkSelectFld;