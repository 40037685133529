import { Divider, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import OpReport from "../reports/OperationalReports";
const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  projectheading: {
    fontWeight: 500,
    letterSpacing: 0.5,
    fontSize: 17,
  },
  divider: {
    height: 4,
    backgroundImage: "linear-gradient(90deg, #00b0ff, #00C853)",
    width: "100%",
    marginTop: 15,
  },
  headcontainer: {
    margin: "15px 0%",
  },
  projectbtn: {
    "& button": {
      float: "right",
      textTransform: "inherit",
      fontWeight: 500,
      fontSize: 12,
      letterSpacing: 0.5,
      borderRadius: 20,
      color: "white",
    },
  },
}));
function OpReportComp({ match }) {
  const classes = useStyles();
  return (
    <Grid container direction="row">
      <Grid className={classes.headcontainer} container item xs={12} md={12}>
        <Grid item xs={6} className={classes.projectheading}>
          <Typography variant="h6" gutterBottom>
            Operational Reports
          </Typography>
        </Grid>
        <Divider className={classes.divider} />
      </Grid>
      <Grid container direction="row">
        <OpReport />
      </Grid>
    </Grid>
  );
}
export default OpReportComp;
