import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { apiService } from "../../services/api.service";
import ReportFooter from "./ReportFooter";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    margin: "0.75rem 0.75rem",
    //'box-shadowhadow': 'none',
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function EstNoTransDtReport() {
  const classes = useStyles();
  const runReport = (event, rptType) => {
    //Submit form rptype...xlsx/pdf
    var qrystr = "estnotransferdate&format=" + rptType;
    var fileName = "EstimatesNoTransDate." + rptType;
    apiService.getReport(qrystr, fileName);
  };
  return (
    // <Card className={classes.root} variant="outlined">
    <>
      <CardContent>
        <Typography className={classes.title} gutterBottom>
          Estimates with No Transfer Date
        </Typography>
        <Typography variant="h5" component="h2"></Typography>
        <Typography className={classes.pos} color="textSecondary">
          Shows all Estimates with no transfer date.
        </Typography>
      </CardContent>
      <CardActions>
        <ReportFooter sumbit={runReport} />
      </CardActions>
    </>
    // </Card>
  );
}
