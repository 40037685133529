import React from "react"
import AddPayItem from "../forms/PayItemForm"
import { Divider, Typography, Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  projectheading: {
    fontWeight: 500,
    letterSpacing: 0.5,
    fontSize: 17,
  },
  divider: {
    height: 4,
    backgroundImage: 'linear-gradient(90deg, #00b0ff, #00C853)',
    width: '100%',
    marginTop: 15,
  },
  headcontainer: {
    margin: '15px 0%',
  },
}));
function PayItemComp({ match }) {
  const classes = useStyles();
  return (
    <Grid container direction="row">
      <Grid className={classes.headcontainer} container item xs={12} md={12}>
        <Grid item xs={12} className={classes.projectheading}>
          <Typography variant="h6" gutterBottom>
            New Pay Item
            </Typography>
        </Grid>
        <Divider className={classes.divider} />
      </Grid>
      <Grid container direction="row">
        <AddPayItem match={match} />
      </Grid>
    </Grid>
  )
}
export default PayItemComp