import DateFnsUtils from "@date-io/date-fns";
import { Grid } from "@material-ui/core";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import dateFormat from "dateformat";
import React, { useState } from "react";
import ReportFooter from "./ReportFooter";
import { apiService } from "../../services/api.service";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    margin: "0.75rem 0.75rem",
  },

  title: {
    fontSize: 14,
    fontWeight: 600,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function PostCertsComp() {
  const classes = useStyles();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const runReport = (event, rptType) => {
    if (startDate && endDate) {
      var formatds = dateFormat(startDate, "yyyy-mm-dd");
      var formatde = dateFormat(endDate, "yyyy-mm-dd");
      console.log(formatds + " ---range--" + formatde);
      var qrystr = "postcerts&format=" + rptType + "&fromdate_date=" + formatds + "&todate_date=" + formatde;
      var fileName = formatds + "_" + formatde + "_postcerts." + rptType;
      apiService.getReport(qrystr, fileName);
    } else {
      console.log("Please select date range");
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      {/* <Card className={classes.root} variant="outlined"> */}
      <CardContent>
        <Typography className={classes.title} gutterBottom>
          3# Post Cert Quantities
        </Typography>
        <Typography variant="h5" component="h2"></Typography>
        <Typography className={classes.pos} color="textSecondary">
          Install Date Range
        </Typography>

        <Grid container spacing={1}>
          <Grid item xs={12}>
            <KeyboardDatePicker
              fullWidth
              margin={"normal"}
              id="date-picker-dialog"
              label="From"
              format="MM/dd/yyyy"
              value={startDate}
              onChange={handleStartDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <KeyboardDatePicker
              fullWidth
              margin={"normal"}
              id="date-picker-dialog"
              label="To"
              format="MM/dd/yyyy"
              value={endDate}
              onChange={handleEndDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <ReportFooter sumbit={runReport} />
      </CardActions>
      {/* </Card> */}
    </MuiPickersUtilsProvider>
  );
}
