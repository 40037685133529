import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { isAfter, isBefore, parseISO } from "date-fns";
import MaterialTable from "material-table";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { apiService } from "../../services/api.service";
import CustomDatePicker from "../../helper/rangeFilter";
import { useSelector } from "react-redux";


const HotelList = React.forwardRef((props, ref) => {
    const [data, setData] = useState([]); //rowData
    const { enqueueSnackbar } = useSnackbar();
    const [showAdmin, setShowAdmin] = useState(false);
    const { user: currentUser } = useSelector((state) => state.auth);

    useEffect(() => {
        // console.log(props)
        getHotels();
        setShowAdmin(["ROLE_ADMIN", "ROLE_CORPORATE"].some(x => currentUser.roles.includes(x)));
    }, []);

    const getHotels = () => {
        apiService.getContent("hotels").then((edata) => {
            setData(edata);
        });
    };
    const [columns, setColumns] = useState([
    { title: "Name", field: "hotelName", defaultSort: "asc" },
    { title: "Location", field: "location" },
    { title: "Phone", field: "phone" },
    { title: "Contact", field: "contact" },
    { title: "HotelId", field: "hotelsId", hidden: true },
    { title: "Rate Plus", field: "rateTax", },
    {
        title: "Good Through",
        field: "goodDate",
        type: "date",
        cellStyle: { color: "#e407a0", fontWeight: 600 },
        editComponent: (props) => (
            <input
                type="date"
                value={props.value}
                //defaultValue={today}
                onChange={(e) => props.onChange(e.target.value)}
            />
        ),
        filterComponent: (props) => <CustomDatePicker {...props} />,
        customFilterAndSearch: (term, rowData) => {
            return (
                !isAfter(parseISO(rowData.enterDate), term.endDate) &&
                !isBefore(parseISO(rowData.enterDate), term.startDate)
            );
        },
    }])

    return (
        <>
            <MaterialTable
                title="Hotels – Direct Bill List"
                columns={columns}
                //tableRef={ref}
                data={data}
                actions={[]}
                // components={{
                //   Body: (props) => <MTableBody ref={divRef} {...props} />
                // }}
                editable={showAdmin && {
                    // isDeleteHidden: (rowData) =>  !showAdmin,
                    // isEditHidden:(rowData) =>  !showAdmin,
                    onRowAdd: (newData) =>
                        new Promise((resolve, reject) => {

                            if (newData.goodDate) {
                                let newDat = new Date(newData.goodDate);
                                newData.goodDate = new Date(
                                    newDat.getTime() + newDat.getTimezoneOffset() * 60000
                                );
                            }
                            apiService
                                .addContent("hotels/", newData)
                                .then((response) => {
                                    enqueueSnackbar("New Record Added Successfully", {
                                        variant: "success",
                                    });
                                    getHotels();
                                    resolve();
                                })
                                .catch((error) => {
                                    enqueueSnackbar(
                                        "There is an error in adding new record :" + error,
                                        { variant: "error" }
                                    );
                                    console.log("error: " + error);
                                    resolve();
                                });
                        }),
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                            if (newData.goodDate) {
                                let newDat = new Date(newData.goodDate);
                                newData.goodDate = new Date(
                                    newDat.getTime() + newDat.getTimezoneOffset() * 60000
                                );
                            }


                            apiService
                                .putContent("hotels/" + oldData.hotelsId, newData)
                                .then((response) => {
                                    getHotels();
                                    enqueueSnackbar("Record Updated Successfully", {
                                        variant: "success",
                                    });
                                    resolve();
                                });
                        }),
                    onRowDelete: (oldData) =>
                        new Promise((resolve, reject) => {
                            apiService
                                .deleteObject("hotels/" + oldData.hotelsId)
                                .then((response) => {
                                    getHotels();
                                    enqueueSnackbar("Record Deleted Successfully", {
                                        variant: "success",
                                    });
                                    resolve();
                                });
                        }),
                }}
                //components={{ Pagination: (props) => { return <div   />; }, }}
                options={{
                    //paging: false,
                    //searchText:"arn",
                    //search: false,
                    pageSizeOptions: [10, 50, 100, 1000],
                    pageSize: 1000,
                    emptyRowsWhenPaging: false,
                    addRowPosition: "first",
                    filtering: true,
                    columnsButton: true,
                    headerStyle: {
                        borderBottomColor: "#4cc8ff",
                        fontWeight: "600",
                        borderBottomWidth: "3px",
                        fontFamily: "verdana",
                        position: "sticky",
                        top: 0,
                    }, //background: "#4cc8ff",
                    maxBodyHeight: "650px",
                }}
                localization={{
                    header: {
                        actions: "",
                    },
                }}
            />
        </>
    );
});

export default HotelList;
