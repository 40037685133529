import Button from "@material-ui/core/Button";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { useHistory } from "react-router-dom";
import ReportFooter from "./ReportFooter";
import { apiService } from "../../services/api.service";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    // margin: "0.75rem 0.75rem",
    // 'box-shadowhadow': 'none',
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
  },
  pos: {
    marginBottom: 12,
  },
  btn: {
    background: green[500],
    color: "white",
    "&:hover": {
      backgroundColor: green[700],
    },
  },
});

export default function DlyProdExceedContract() {
  const classes = useStyles();
  const history = useHistory();
  const runReport = (event, rptType) => {
    //Submit form rptype...xlsx/pdf
    var qrystr = "prodexceedcontract&format=" + rptType;
    var fileName = "ProdExceedContract." + rptType;
    apiService.getReport(qrystr, fileName);

  };
  return (
    // <Card className={classes.root} >
    <>
      <CardContent>
        <Typography className={classes.title} gutterBottom>
          Production Exceeds Contract Quantity
        </Typography>
        <Typography variant="h5" component="h2"></Typography>
        <Typography className={classes.pos} color="textSecondary">
          Shows all active jobs, where sum of installed quantity is more than
          contract quantity.
        </Typography>
      </CardContent>
      <CardActions style={{ width: "100%", justifyContent: "flex-end" }}>
        <ReportFooter sumbit={runReport} />
      </CardActions>
    </>
    // </Card>
  );
}
