import { Checkbox, FormControl, Grid, TextField } from "@material-ui/core";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import { apiService } from "../../services/api.service";
import ReportFooter from "./ReportFooter";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import dateFormat from "dateformat";
const useStyles = makeStyles({
    root: {
        minWidth: 275,
        margin: "0.75rem 0.75rem",
        //'box-shadowhadow': 'none',
    },
    formControl: {
        minWidth: 120,
    },
    title: {
        fontSize: 14,
        fontWeight: 600,
    },
    pos: {
        marginBottom: 12,
    },
    chips: {
        display: "flex",
        flexWrap: "wrap",
    },
    chip: {
        margin: 2,
    },
});

export default function JobProdByCategoryInstDate() {
    //start date -1 month
    var d = new Date();
    d.setMonth(d.getMonth() - 1);

    const [startDate, setStartDate] = useState(d);
    const [endDate, setEndDate] = useState(new Date());
    const handleStartDateChange = (date) => {
        setStartDate(date);
    };
    const handleEndDateChange = (date) => {
        setEndDate(date);
    };
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [selectedType, setSelectedType] = useState(null);
    useEffect(() => {
        apiService.getContent("pi/type").then((edata) => {
            //console.log(edata);
            setData(edata)
        });

    }, []);

    const runReport = (event, rptType) => {
        if (startDate && endDate && selectedType) {
            var stDt = dateFormat(startDate, "yyyy-mm-dd");
            var endDt = dateFormat(endDate, "yyyy-mm-dd");
            console.log(stDt, endDt);

            console.log(selectedType.payitmTypeName + " ---Get Selected ID");
            //Submit form rptype...xlsx pdf
            var qrystr = "jobprodcatsumdate&format=" + rptType + "&pitem=" + selectedType.payitmTypeName + "&fromdate_date=" + stDt + "&todate_date=" + endDt;
            var fileName = "JobprodcatsummaryBy_" + selectedType.payitmTypeName + "." + rptType;
            apiService.getReport(qrystr, fileName);
        } else {
            console.log("Please select a payitem type");
        }
    };
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <CardContent>
                <Typography className={classes.title} gutterBottom>
                    Job Production by Category & Install Date - Summary
                </Typography>
                <Typography variant="h5" component="h2"></Typography>
                <Typography className={classes.pos} color="textSecondary">
                </Typography>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <FormControl
                            className={classes.formControl}
                            fullWidth={true}
                            margin={"normal"}
                            size={"medium"}
                        >
                            <Autocomplete
                                //multiple
                                id="checkboxes-tags"
                                options={data}
                                getOptionLabel={(option) => option.payitmTypeName}
                                limitTags={3}
                                // value={myTempVal}
                                // disableCloseOnSelect
                                onChange={(event, newValue) => {
                                    setSelectedType(newValue);
                                }}
                                //getOptionLabel={(option) => option}
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.payitmTypeName}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select Pay Item Type"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "new-password",
                                        }}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <KeyboardDatePicker
                            margin={"normal"}
                            fullWidth
                            id="date-picker-dialog"
                            label="Install Date From"
                            format="MM/dd/yyyy"
                            value={startDate}
                            onChange={handleStartDateChange}
                            KeyboardButtonProps={{
                                "aria-label": "change date",
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <KeyboardDatePicker
                            margin={"normal"}
                            fullWidth
                            id="date-picker-dialog"
                            label="Install Date To"
                            format="MM/dd/yyyy"
                            value={endDate}
                            onChange={handleEndDateChange}
                            KeyboardButtonProps={{
                                "aria-label": "change date",
                            }}
                        />
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions>
                <ReportFooter sumbit={runReport} />
            </CardActions>
            {/* </Card> */}
        </MuiPickersUtilsProvider>
    );
}
