import DateFnsUtils from "@date-io/date-fns";
import { FormControl, Grid, TextField } from "@material-ui/core";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import React, { useEffect, useState } from "react";
import { apiService } from "../../services/api.service";
import ReportFooter from "./ReportFooter";
const useStyles = makeStyles({
  root: {
    minWidth: 275,
    margin: "0.75rem 0.75rem",
    //'box-shadowhadow': 'none',
  },
  formControl: {
    minWidth: 160,
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
  },
  pos: {
    marginBottom: 12,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
});

export default function ProdCounMaintenance() {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [selectedjob, setSelectedJob] = useState(null);
  const [selectedStation, setSelectedStation] = useState(null);
  const [stations, setStations] = useState([]);

  useEffect(() => {
    if (selectedjob) {
      apiService
        .getContent("jsi/stations?jobid=" + selectedjob)
        .then((edata) => {
          console.log(edata);
          setStations(edata);
        });
    }

    if (data.length == 0) {
      apiService.getContent("job/joblist").then((edata) => {
        console.log(edata);
        setData(edata);
      });
    }
  }, [selectedjob]);

  const runReport = (event, rptType) => {
    if (selectedjob && selectedStation) {
      console.log(selectedjob);
      console.log(selectedStation);
      //Submit form rptype...xlsx pdf
      var qrystr = "dailyprodctymain&format=" + rptType + "&job=" + selectedjob + "&station=" + selectedStation;
      var fileName = selectedjob + "_" + selectedStation + "_prod." + rptType;
      apiService.getReport(qrystr, fileName);
    } else {
      console.log("Please select a ---Job and station");
    }
  };
  const alljobs = data.map((x) => x);
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <CardContent>
        <Typography className={classes.title} gutterBottom>
          County Daily Production
        </Typography>
        <Typography variant="h5" component="h2"></Typography>
        <Typography className={classes.pos} color="textSecondary">
          Production Detail by Job and Station
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormControl
              className={classes.formControl}
              fullWidth={true}
              margin={"normal"}
              size={"medium"}
            >
              <Autocomplete
                id="job"
                // blurOnSelect
                options={alljobs}
                onChange={(_, value) => {
                  setSelectedJob(value);
                  setSelectedStation(null);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Select a Job" />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              className={classes.formControl}
              fullWidth={true}
              margin={"normal"}
              size={"medium"}
            >
              <Autocomplete
                id="station"
                // blurOnSelect
                options={stations}
                value={selectedStation}
                onChange={(_, value) => {
                  setSelectedStation(value);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Select a Station" />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <ReportFooter sumbit={runReport} />
      </CardActions>
    </MuiPickersUtilsProvider>
    //</Card>
  );
}
