import React from "react";
import { ErrorMessage, Field, useField } from "formik";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";


// const MyTextField = ({
//     label,
//     ...props
//   }) => {
//     const [field, meta] = useField(props);
//     const errorText = meta.error && meta.touched ? meta.error : "";
//     return (
//       <TextField
//         label={label}
//         {...field}
//         helperText={errorText}
//         error={!!errorText}
//       />
//     );
//   };

const FkInputFld = ({ name, label, type = "text", required = false,disabled=false,  placeholder = "", controlClass = "",fullWidth=true, InputProps = {} }) => {
  const [field, meta] = useField({ name });
  const errorText = meta.error && meta.touched ? meta.error : "";
  return (
    <FormControl
      className={controlClass}
      fullWidth={fullWidth}
      margin={"normal"}
      size={"medium"}
    >
      <TextField
        label={label}
        {...field}
        required={required}
        autoComplete="off"
        name={name}
        type={type}
        value={field.value || ""}
        helperText={errorText}
        error={!!errorText}
        fullWidth
        placeholder={placeholder}
        InputProps={InputProps}
        disabled ={disabled}
      />
    </FormControl>
  );
}

export default FkInputFld;