import { Fab, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import StopIcon from "@material-ui/icons/HighlightOff";
import SlowMotionVideoIcon from "@material-ui/icons/SlowMotionVideo";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import JobBoardForm from "./jobBoardForm";
import JobCards from "./jobCards";


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  fab: {
    margin: "0px",
    top: "auto",
    right: "20px",
    bottom: "20px",
    left: "auto",
    position: "fixed",
    color: "#fff",
  },
}));
function JobBoardMainView() {
  const classes = useStyles();
  const [data, setData] = useState(Date.now()); //rowData
  const [originalData, setOriginalData] = useState([]); //rowData
  //const [muiTableKey, setMuiTableKey] = React.useState(0);
  const [filter, setFilter] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const tableRef = useRef(null);
  const [currentPosition, setCurrentPosition] = useState(0);
  const [autoShow, setAutoShow] = useState(false);
  const [intervalId, setIntervalId] = useState(0);
  const handleFabClick = () => {
    console.log(autoShow);
    if (!autoShow) {
      if(tableRef.current){
        //console.log(tableRef.current.state.data);
        setOriginalData(tableRef.current.state.data);
      }
    } else {
      //setData(originalData);
    }
    setAutoShow(currentShow => !currentShow);
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(0);
      return;
    }

    const newIntervalId = setInterval(() => {
      console.log("Refresh Called!!");
      setAutoShow(currentShow => !currentShow);
      setTimeout(() => setOriginalData(tableRef.current.state.data), 2000);
      setTimeout(() => setAutoShow(currentShow => !currentShow), 5000);
      //setOriginalData(tableRef.current.state.data);
     // setAutoShow(currentShow => !currentShow);
    }, 900000);
    setIntervalId(newIntervalId);
  };
  useEffect(() => {
    //getJobBoard();
  }, []);

  return (
    <>
    {autoShow ? (<JobCards items={originalData}/>):(
        <JobBoardForm ref={tableRef} data={data}/>  
      )}
      {/* <div>{autoShow ? <JobCards />:null}</div> */}
      <Tooltip title={(autoShow && "Stop") || "Start"} aria-label="Start/Stop">
        <Fab
          color="primary"
          className={classes.fab}
          aria-label="add"
          onClick={handleFabClick}
        >
          {(autoShow && <StopIcon fontSize="large" />) || (
            <SlowMotionVideoIcon fontSize="large" />
          )}
        </Fab>
      </Tooltip>
      
      {/* <div ref={divRef} /> */}
    </>
  );
}

export default JobBoardMainView;
