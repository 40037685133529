import React, { useState } from 'react';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, IconButton, Grid } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { ExcelRenderer } from 'react-excel-renderer';
import { apiService } from '../../services/api.service';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from "react-router-dom";

function ExcelUpload({ file, uploadCancelEvent, jobId }) {
    const { enqueueSnackbar,closeSnackbar  } = useSnackbar();
    const history = useHistory();
    const [rows, setRows] = useState([]);
    const [cols, setCols] = useState([]);
    const [errorRows, setErrorRows] = useState([]);  // To store the rows with error
    const handleSnackbarClose = (key) => () => {
        closeSnackbar(key);
    };
    useEffect(() => {
        if (file) {
            console.log(file, jobId);
            apiService.postExcel(file, jobId).then(
                (res) => {
                    const errorRowsFromApi = res.filter(item => item.error !== null).map(item => item.row);
                    setErrorRows(errorRowsFromApi);  // Set the error rows
                    if (errorRowsFromApi.length > 0) {
                        enqueueSnackbar(`Oops! We've detected ${errorRowsFromApi.length} invalid row(s) in your upload. Please rectify the errors and try uploading again.`, {
                            variant: "warning",
                            action: (key) => (
                                <IconButton onClick={handleSnackbarClose(key)}>
                                    <CloseIcon />
                                </IconButton>
                            ),
                        });
                    }
                    console.log(res);
                    ExcelRenderer(file[0], (err, resp) => {
                        if (err) {
                            console.log(err);
                            enqueueSnackbar("Something went wrong please try again", {
                                variant: "error",
                            });
                        } else {
                            const headers = resp.rows[0].map((header, index) => ({ name: header, key: index })); // setting headers from first row
                            const dataRows = resp.rows.slice(1); // removing first row from data
                            setCols(headers);
                            setRows(dataRows);
                        }
                    });
                },
                (error) => {
                    console.log(error)
                    enqueueSnackbar("Something went wrong please try again", {
                        variant: "error",
                    });
                }
            );


        }
    }, [file]);

    // const handleRemoveRow = (index) => {
    //     const newRows = [...rows];
    //     newRows.splice(index, 1);
    //     setRows(newRows);
    // };

    // const handleCellChange = (rowIndex, cellIndex, event) => {
    //     const newRows = [...rows];
    //     newRows[rowIndex][cellIndex] = event.target.value;
    //     setRows(newRows);
    // };
    const cancelHandler = () => {
        uploadCancelEvent();
    }
    const submitHandler = () => {
        if (file) {
            apiService.postExcel(file, jobId,true).then(
              (saved) => {
                console.log(saved);
                enqueueSnackbar("File(s) Loaded successfully!", {
                  variant: "success",
                });
              },
              (error) => {
                console.log(error);
                enqueueSnackbar(
                  "Error Creating/Updating Job:" + error,
                  { variant: "error" }
                );
              }
            );
            cancelHandler();
          }

    }

    return (
        <Grid item xs={12} sm={12}>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {cols.map((col, index) => (
                                <TableCell key={index}>{col.name}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, rowIndex) => (
                            <TableRow
                                key={rowIndex}
                                style={errorRows.includes(rowIndex + 1) ? { backgroundColor: "#e16338" } : {}}  // If errorRows include the rowIndex+1, change the color
                            >
                                {cols.map((col, colIndex) => (
                                    <TableCell align="center" key={colIndex}>
                                        {row[colIndex] !== undefined ? row[colIndex] : ""}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Grid item md={12} style={{ textAlign: "right", marginTop: "8px" }}>
                <Button
                    variant="contained"
                    color="primary"
                    style={{ marginRight: "10px" }}
                    onClick={submitHandler}
                    disabled={errorRows.length > 0}  // Disable the button if there are errors
                >
                    Create PayItems
                </Button>
                <Button variant="contained" color="secondary" onClick={cancelHandler}>
                    Cancel
                </Button>
            </Grid>

        </Grid>
    );
}


export default ExcelUpload;
