import { ApiProps } from '../props/ApiProps';
import authHeader from './auth.header';
import { logout } from './auth.service';
import { saveAs } from "file-saver";

function getContent(append) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(ApiProps.baseUrl + append, requestOptions).then(handleResponse);
}
function getContentNoLoad(append) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(ApiProps.baseUrl + append, requestOptions).then(handleResponseNoLoad).catch(err=>{console.log(err)});
}
function postContent(append, contnt) {
    let pheader = {
        ...authHeader(),
        'Content-Type': 'application/json'
    }
    const requestOptions = {
        method: 'POST',
        headers: pheader,
        body: JSON.stringify(contnt)
    };
    return fetch(ApiProps.baseUrl + append, requestOptions).then(handleResponse);
}

function addContent(append, contnt) {
    let pheader = {
        ...authHeader(),
        'Content-Type': 'application/json'
    }
    const requestOptions = {
        method: 'POST',
        headers: pheader,
        body: JSON.stringify(contnt)
    };
    return fetch(ApiProps.baseUrl + append, requestOptions).then(handleAdd);
}

function putContent(append, contnt) {
    let pheader = {
        ...authHeader(),
        'Content-Type': 'application/json'
    }
    const requestOptions = {
        method: 'PUT',
        headers: pheader,
        body: JSON.stringify(contnt)
    };
    return fetch(ApiProps.baseUrl + append, requestOptions).then(handleOkay);
}

function deleteObject(append) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(ApiProps.baseUrl + append, requestOptions).then(handleOkay);
}
function postFile(files, jobId) {
    const data = new FormData();
     for (const file of files) {
        data.append('file', file, file.name);
     }
    let header = {
        ...authHeader(),
        // 'Content-Type': 'multipart/form-data'
    }
    const requestOptions = {
        method: 'POST',
        headers: header,
        body: data,
    };
    return fetch(ApiProps.baseUrl + "atms3/upload/" + jobId, requestOptions).then(handleOkay);
}
function postExcel(files, jobId,finalUpload=false) {
    const data = new FormData();
     for (const file of files) {
        data.append('file', file, file.name);
     }
    let header = {
        ...authHeader(),
        // 'Content-Type': 'multipart/form-data'
    }
    const requestOptions = {
        method: 'POST',
        headers: header,
        body: data,
    };
    if(finalUpload)
    {
        return fetch(ApiProps.baseUrl + "jpi/upload/" + jobId, requestOptions).then(handleResponse);
    }
    return fetch(ApiProps.baseUrl + "jpi/validate/" + jobId, requestOptions).then(handleResponse);
}

function getFile(fileName) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    fetch(ApiProps.baseUrl + "atms3/download?fileName=" + fileName, requestOptions)
        .then(function (response) {
            return response.blob();
        })
        .then(function (blob) {
            saveAs(blob, fileName);
        })
        .catch(error => {
            throw error;
        })
}

function getReport(reportParms, fileName) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    fetch(ApiProps.baseUrl + "reports/emp?name=" + reportParms, requestOptions)
        .then(function (response) {
            return response.blob();
        })
        .then(function (blob) {
            saveAs(blob, fileName);
        })
        .catch(error => {
            throw error;
        })
}

function deleteFile(fileName) {
    return deleteObject("atms3/delete?fileName=" + fileName)
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                window.location.href = '/login';               
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

function handleResponseNoLoad(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

function handleAdd(response) {
    if (response.status === 201) return response;
    else throw new Error(response.status);
}

function handleOkay(response) {
    if (response.status === 200) return response;
    else throw new Error(response.status);
}

export const apiService = {
    getContent,
    getContentNoLoad,
    postContent,
    deleteObject,
    addContent,
    putContent,
    postFile,
    getFile,
    getReport,
    deleteFile,
    postExcel
};