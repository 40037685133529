import { Divider, Typography } from "@material-ui/core";
import Box from '@material-ui/core/Box';
import Button from "@material-ui/core/Button";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import PropTypes from 'prop-types';
import React, { Fragment } from "react";
import MapComp from "./GoogleMapComp";

const useStyles = makeStyles((theme) =>  ({

  media: {
    height: 250,
  },
  root: {
    flexGrow: 1,
    [theme.breakpoints.up('sm')]: {
       
      },
   
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  projectheading: {
    fontWeight: 500,
    letterSpacing: 0.5,
    fontSize: 17,
  },
  projectbtn: {
    "& a": {
      float: "right",
      textTransform: "inherit",
      fontWeight: 500,
      fontSize: 14,
      letterSpacing: 0.8,
      borderRadius: 20,
	  color : 'white',
    },
  },
  divider:{
    height: 4,
    backgroundImage: 'linear-gradient(90deg, #00b0ff, #00C853)',
    width: '100%',
    marginTop: 15,
  },
  headcontainer: {
    margin:'5px 0%',
  },
  spatab: {
    margin:'0.75rem 0.75rem',
    'box-shadowhadow': 'none',
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box css={{p: 0 }} p={3}>
          {children}
        </Box>        
      )}
    </div>
  );
}


TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function MainComp() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Fragment>
      {/* <Grid container direction="row">
        <Grid className={classes.headcontainer} container item xs={12} md={12}>
          <Grid item xs={6} className={classes.projectheading}>
            <Typography variant="h6" gutterBottom>
              Projects Map
            </Typography>
          </Grid>         
          <Divider className={classes.divider} />
        </Grid>
      </Grid> */}
      <Grid container direction="row">
        <Grid className={classes.headcontainer} container item xs={12} md={12}>
        <MapComp />          
        </Grid>       
      </Grid>
    </Fragment>
  );
}